/******************************************************************************************************************************
Global Style
-------------------------------------------------------------------------------------------------------------------------------
Use this file to modify core HTML properties or very global objects.

The rules applied here are applied literally without conversion, thus avoid using names that can clash with components.

For Ant specifics, see ant.css
******************************************************************************************************************************/



.noWrap{
    white-space: nowrap;
}
  
  .table-container {
    border: 1px solid var(--border);
    overflow-x: scroll;
    width: 100%;
    max-width: 960px;
    position: relative;
  }
  
  table {
    /* box-shadow and borders will not work with positon: sticky otherwise */
    border-collapse: separate !important;
    border-spacing: 0;
  }

 
  
  th {
    border-bottom: 1px solid var(--border);
    font-weight: 500;
    height: 30px;
    padding: 2px 4px;
    position: relative;
    text-align: center;
  }
  thead {
    background-color: var(--neutral-2);

    position: sticky;
    top: 0;
    z-index: 1;
    border-radius: 4px;
  }
  table th:first-child{
    border-radius:4px 0 0 0px;
  }
  
  table th:last-child{
    border-radius:0 4px 0px 0;
  }
  
  td {
    border-bottom: 1px solid var(--border);

    padding: 2px 2px;
  }

  table tbody tr:hover {
    transition: 0.3s all;
    background-color: var(--neutral-2);
  }
  
  .resizer {
    background: rgb(0, 0, 0);
    cursor: col-resize;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    touch-action: none;
    user-select: none;
    width: 5px;
  }
  
  .resizer.isResizing {
    background: blue;
    opacity: 1;
  }

  
/******************************************************************************************************************************
Browser Specifics
*******************************************************************************************************************************/

input:-webkit-autofill::first-line {
    font-size: 16px !important;
}

/******************************************************************************************************************************
Draft JS
*******************************************************************************************************************************/

.ProseMirror {
    height: 100px;
    background-color: var(--background);
    border-radius: 5px;
    padding: 5px;
}

.mention {
    color: var(--ant-primary-color);
    border-radius: 0.3rem;
    padding: 0.1rem 0.3rem;
}

#mention {
    color: var(--ant-primary-color);
}

/* #link {
    color: var(--ant-primary-color);
} */

.userMenuContent {
    left: -19px !important;
}

.userMenuContent .ant-menu-item {
    display: flex !important;
    align-items: center !important;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
    padding-top: 8px !important;
}
#userMenu.ant-popover-open {
    color: var(--ant-primary-color);
    border-bottom: 2.5px solid var(--ant-primary-color);
}
#userMenu.ant-popover-open:after {
    content: '';
    display: block;
    background: var(--ant-primary-color);

    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.46477 12.1506C8.07424 12.5411 8.07424 13.1743 8.46477 13.5648C8.85529 13.9554 9.48846 13.9554 9.87898 13.5648L12.0003 11.4435L14.1216 13.5649C14.5121 13.9554 15.1453 13.9554 15.5358 13.5649C15.9263 13.1743 15.9263 12.5412 15.5358 12.1506L12.7136 9.3284C12.7115 9.32632 12.7095 9.32425 12.7074 9.32219C12.3657 8.98048 11.8382 8.93777 11.4501 9.19405C11.3946 9.23066 11.342 9.27337 11.2932 9.32219C11.2932 9.3222 11.2932 9.32221 11.2932 9.32222C11.293 9.32241 11.2928 9.32261 11.2926 9.3228L8.46477 12.1506Z' fill='%23FF3862'/%3E%3C/svg%3E%0A");
    width: 20px;
    mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.46477 12.1506C8.07424 12.5411 8.07424 13.1743 8.46477 13.5648C8.85529 13.9554 9.48846 13.9554 9.87898 13.5648L12.0003 11.4435L14.1216 13.5649C14.5121 13.9554 15.1453 13.9554 15.5358 13.5649C15.9263 13.1743 15.9263 12.5412 15.5358 12.1506L12.7136 9.3284C12.7115 9.32632 12.7095 9.32425 12.7074 9.32219C12.3657 8.98048 11.8382 8.93777 11.4501 9.19405C11.3946 9.23066 11.342 9.27337 11.2932 9.32219C11.2932 9.3222 11.2932 9.32221 11.2932 9.32222C11.293 9.32241 11.2928 9.32261 11.2926 9.3228L8.46477 12.1506Z' fill='%23FF3862'/%3E%3C/svg%3E%0A");
    width: 20px;

    float: right;
    width: 24px;
    height: 24px;
}

::-moz-selection {
    /* Code for Firefox */
    color: unset !important;
    background: #accef7 !important;
}

::selection {
    color: unset !important;
    background: #accef7 !important;
}

.emoji {
    zoom: 0.25;
}

.light .hoverable {
    display: flex;
    color: var(--neutral-13) !important;
}

.dark .hoverable {
    display: flex;
    color: var(--neutral-13) !important;
}

:root .light {
    --daybreak-blue-1: #e6f7ff;
    --daybreak-blue-2: #bae7ff;
    --daybreak-blue-3: #91d5ff;
    --daybreak-blue-4: #69c0ff;
    --daybreak-blue-5: #40a9ff;
    --daybreak-blue-6: #1890ff;
    --daybreak-blue-7: #096dd9;
    --daybreak-blue-8: #0050b3;
    --daybreak-blue-9: #003a8c;
    --daybreak-blue-10: #002766;

    --turingo-1: #fff0f2;
    --turingo-2: #ffc7ce;
    --turingo-3: #ff9ead;
    --turingo-4: #ff758e;
    --turingo-5: #fc506f;
    --turingo-6: #e61e49;
    --turingo-7: #cf133c;
    --turingo-8: #a8072a;
    --turingo-9: #82001c;
    --turingo-10: #5c0014;

    --dust-red-1: #fff1f0;
    --dust-red-2: #ffccc7;
    --dust-red-3: #ffa39e;
    --dust-red-4: #ff7875;
    --dust-red-5: #ff4d4f;
    --dust-red-6: #EA0B16;
    --dust-red-7: #cf1322;
    --dust-red-8: #a8071a;
    --dust-red-9: #820014;
    --dust-red-10: #5c0011;

    --volcano-1: #fff2e8;
    --volcano-2: #ffd8bf;
    --volcano-3: #ffbb96;
    --volcano-4: #ff9c6e;
    --volcano-5: #ff7a45;
    --volcano-6: #fa541c;
    --volcano-7: #CE370D;
    --volcano-8: #ad2102;
    --volcano-9: #871400;
    --volcano-10: #610b00;

    --sunset-orange-1: #fff7e6;
    --sunset-orange-2: #ffe7ba;
    --sunset-orange-3: #ffd591;
    --sunset-orange-4: #ffc069;
    --sunset-orange-5: #ffa940;
    --sunset-orange-6: #fa8c16;
    --sunset-orange-7: #B45F04;
    --sunset-orange-8: #ad4e00;
    --sunset-orange-9: #873800;
    --sunset-orange-10: #612500;

    --polar-green-1: #f6ffed;
    --polar-green-2: #d9f7be;
    --polar-green-3: #b7eb8f;
    --polar-green-4: #95de64;
    --polar-green-5: #73d13d;
    --polar-green-6: #52c41a;
    --polar-green-7: #389e0d;
    --polar-green-8: #237804;
    --polar-green-9: #135200;
    --polar-green-10: #092b00;

    --cyan-1: #e6fffb;
    --cyan-2: #b5f5ec;
    --cyan-3: #87e8de;
    --cyan-4: #5cdbd3;
    --cyan-5: #36cfc9;
    --cyan-6: #13c2c2;
    --cyan-7: #08979c;
    --cyan-8: #006d75;
    --cyan-9: #00474f;
    --cyan-10: #002329;

    --magenta-1: #fff0f6;
    --magenta-2: #ffd6e7;
    --magenta-3: #ffadd2;
    --magenta-4: #ff85c0;
    --magenta-5: #f759ab;
    --magenta-6: #D1157C;
    --magenta-7: #c41d7f;
    --magenta-8: #9e1068;
    --magenta-9: #780650;
    --magenta-10: #520339;

    --purple-1: #f9f0ff;
    --purple-2: #efdbff;
    --purple-3: #d3adf7;
    --purple-4: #b37feb;
    --purple-5: #9254de;
    --purple-6: #722ed1;
    --purple-7: #531dab;
    --purple-8: #391085;
    --purple-9: #22075e;
    --purple-10: #120338;

    --modal-icon-success: var(--polar-green-5);
    --modal-icon-warning: var(--sunset-orange-5);
    --modal-icon-error: var(--dust-red-5);

    --notification-dot: var(--dust-red-6);

    --post-type-image-background: var(--cyan-2);
    --post-type-image-icon: var(--cyan-8);

    --post-type-image-background-hover: var(--cyan-1);
    --post-type-image-icon-hover: var(--cyan-7);

    --post-type-image-background-active: var(--cyan-3);
    --post-type-image-icon-active: var(--cyan-9);


    --post-type-video-background: var(--purple-2);
    --post-type-video-icon: var(--purple-8);


    --post-type-video-background-hover: var(--purple-1);
    --post-type-video-icon-hover: var(--purple-7);

    --post-type-video-background-active: var(--purple-3);
    --post-type-video-icon-active: var(--purple-9);

    --post-type-attachment-background: var(--daybreak-blue-2);
    --post-type-attachment-icon: var(--daybreak-blue-8);


    --post-type-attachment-background-hover: var(--daybreak-blue-1);
    --post-type-attachment-icon-hover: var(--daybreak-blue-7);

    --post-type-attachment-background-active: var(--daybreak-blue-3);
    --post-type-attachment-icon-active: var(--daybreak-blue-9);


    --post-type-poll-background: var(--volcano-2);
    --post-type-poll-icon: var(--volcano-8);


    --post-type-poll-background-hover: var(--volcano-1);
    --post-type-poll-icon-hover: var(--volcano-7);

    --post-type-poll-background-active: var(--volcano-3);
    --post-type-poll-icon-active: var(--volcano-9);


    --disabled-button-background: var(--neutral-3);

    --tag-border: var(--neutral-5);

    --tag-active-background: var(--ant-primary-1);
    --tag-active-border: var(--ant-primary-color);

    --post-action-color: var(--ant-primary-color);
    --post-action-background: var(--ant-primary-1);
}

.formFlex .ant-form-item-control-input-content {
    display: flex !important;
}

:root .dark {
    --daybreak-blue-1: #111d2c;
    --daybreak-blue-2: #112a45;
    --daybreak-blue-3: #15395b;
    --daybreak-blue-4: #164c7e;
    --daybreak-blue-5: #1765ad;
    --daybreak-blue-6: #177ddc;
    --daybreak-blue-7: #3c9ae8;
    --daybreak-blue-8: #65b7f3;
    --daybreak-blue-9: #8dcff8;
    --daybreak-blue-10: #b7e3fa;

    --turingo-1: #2a1213;
    --turingo-2: #43141f;
    --turingo-3: #581827;
    --turingo-4: #791a2f;
    --turingo-5: #a61d3b;
    --turingo-6: #e61e49;
    --turingo-7: #ed5a7a;
    --turingo-8: #f3708c;
    --turingo-9: #f89aae;
    --turingo-10: #fac3cf;

    --dust-red-1: #2a1215;
    --dust-red-2: #431418;
    --dust-red-3: #58181c;
    --dust-red-4: #791a1f;
    --dust-red-5: #a61d24;
    --dust-red-6: #d32029;
    --dust-red-7: #e84749;
    --dust-red-8: #f37370;
    --dust-red-9: #f89f9a;
    --dust-red-10: #fac8c3;

    --volcano-1: #2b1611;
    --volcano-2: #441d12;
    --volcano-3: #592716;
    --volcano-4: #7c3118;
    --volcano-5: #aa3e19;
    --volcano-6: #d84a1b;
    --volcano-7: #e87040;
    --volcano-8: #f3956a;
    --volcano-9: #f8b692;
    --volcano-10: #fad4bc;

    --sunset-orange-1: #2b1d11;
    --sunset-orange-2: #442a11;
    --sunset-orange-3: #593815;
    --sunset-orange-4: #7c4a15;
    --sunset-orange-5: #aa6215;
    --sunset-orange-6: #d87a16;
    --sunset-orange-7: #e89a3c;
    --sunset-orange-8: #f3b765;
    --sunset-orange-9: #f8cf8d;
    --sunset-orange-10: #fae3b7;

    --polar-green-1: #162312;
    --polar-green-2: #1d3712;
    --polar-green-3: #274916;
    --polar-green-4: #306317;
    --polar-green-5: #3c8618;
    --polar-green-6: #49aa19;
    --polar-green-7: #6abe39;
    --polar-green-8: #8fd460;
    --polar-green-9: #b2e58b;
    --polar-green-10: #d5f2bb;

    --cyan-1: #112123;
    --cyan-2: #113536;
    --cyan-3: #144848;
    --cyan-4: #146262;
    --cyan-5: #138585;
    --cyan-6: #13a8a8;
    --cyan-7: #33bcb7;
    --cyan-8: #58d1c9;
    --cyan-9: #84e2d8;
    --cyan-10: #b2f1e8;

    --magenta-1: #291321;
    --magenta-2: #40162f;
    --magenta-3: #551c3b;
    --magenta-4: #75204f;
    --magenta-5: #a02669;
    --magenta-6: #cb2b83;
    --magenta-7: #e35fa3;
    --magenta-8: #f37fb7;
    --magenta-9: #f8a8cc;
    --magenta-10: #fad2e3;

    --purple-1: #1a1325;
    --purple-2: #24163a;
    --purple-3: #301c4d;
    --purple-4: #3e2069;
    --purple-5: #51258f;
    --purple-6: #642ab5;
    --purple-7: #854eca;
    --purple-8: #ab7ae0;
    --purple-9: #cda8f0;
    --purple-10: #ebd7fa;

    --modal-icon-success: var(--polar-green-7);
    --modal-icon-warning: var(--sunset-orange-7);
    --modal-icon-error: var(--dust-red-7);

    --notification-dot: var(--dust-red-6);

    --post-type-image-background: var(--cyan-4);
    --post-type-image-icon: var(--cyan-8);
    
    --post-type-image-background-hover: var(--cyan-3);
    --post-type-image-icon-hover: var(--cyan-7);

    --post-type-image-background-active: var(--cyan-5);
    --post-type-image-icon-active: var(--cyan-9);



    --post-type-video-background: var(--purple-4);
    --post-type-video-icon: var(--purple-8);

    --post-type-video-background-hover: var(--purple-3);
    --post-type-video-icon-hover: var(--purple-7);

    --post-type-video-background-active: var(--purple-5);
    --post-type-video-icon-active: var(--purple-9);


    --post-type-attachment-background: var(--daybreak-blue-4);
    --post-type-attachment-icon: var(--daybreak-blue-8);

    --post-type-attachment-background-hover: var(--daybreak-blue-3);
    --post-type-attachment-icon-hover: var(--daybreak-blue-7);

    --post-type-attachment-background-active: var(--daybreak-blue-5);
    --post-type-attachment-icon-active: var(--daybreak-blue-9);


    --post-type-poll-background: var(--volcano-4);
    --post-type-poll-icon: var(--volcano-8);

    --post-type-poll-background-hover: var(--volcano-3);
    --post-type-poll-icon-hover: var(--volcano-7);

    --post-type-poll-background-active: var(--volcano-5);
    --post-type-poll-icon-active: var(--volcano-9);

    --disabled-button-background: var(--neutral-2);

    --tag-border: var(--neutral-5);

    --tag-active-background: var(--ant-primary-1);
    --tag-active-border: var(--ant-primary-color);

    --post-action-color: var(--ant-primary-color);
    --post-action-background: var(--ant-primary-1);
}

:root .turingo_color {
    --ant-primary-1: var(--turingo-1);
    --ant-primary-2: var(--turingo-2);
    --ant-primary-3: var(--turingo-3);
    --ant-primary-4: var(--turingo-4);
    --ant-primary-5: var(--turingo-5);
    --ant-primary-6: var(--turingo-6);
    --ant-primary-7: var(--turingo-7);
    --ant-primary-8: var(--turingo-8);
    --ant-primary-9: var(--turingo-9);
    --ant-primary-10: var(--turingo-10);
}



:root .red {
    --ant-primary-1: var(--dust-red-1);
    --ant-primary-2: var(--dust-red-2);
    --ant-primary-3: var(--dust-red-3);
    --ant-primary-4: var(--dust-red-4);
    --ant-primary-5: var(--dust-red-5);
    --ant-primary-6: var(--dust-red-6);
    --ant-primary-7: var(--dust-red-7);
    --ant-primary-8: var(--dust-red-8);
    --ant-primary-9: var(--dust-red-9);
    --ant-primary-10: var(--dust-red-10);
}

:root .light.red {
    --ant-primary-color-hover: var(--ant-primary-6);
    --ant-primary-color-active: var(--ant-primary-8);
    --ant-primary-color: var(--ant-primary-7);
    --ant-primary-color-outline: var(--ant-primary-7);
}

:root .dark.red {
    --ant-primary-color-hover: var(--ant-primary-7);
    --ant-primary-color-active: var(--ant-primary-9);
    --ant-primary-color: var(--ant-primary-8);
    --ant-primary-color-outline: var(--ant-primary-8);
}

:root .volcano {
    --ant-primary-1: var(--volcano-1);
    --ant-primary-2: var(--volcano-2);
    --ant-primary-3: var(--volcano-3);
    --ant-primary-4: var(--volcano-4);
    --ant-primary-5: var(--volcano-5);
    --ant-primary-6: var(--volcano-6);
    --ant-primary-7: var(--volcano-7);
    --ant-primary-8: var(--volcano-8);
    --ant-primary-9: var(--volcano-9);
    --ant-primary-10: var(--volcano-10);
}

:root .light.volcano {
    --ant-primary-color-hover: var(--ant-primary-6);
    --ant-primary-color-active: var(--ant-primary-8);
    --ant-primary-color: var(--ant-primary-7);
    --ant-primary-color-outline: var(--ant-primary-7);
}

:root .dark.volcano {
    --ant-primary-color-hover: var(--ant-primary-6);
    --ant-primary-color-active: var(--ant-primary-8);
    --ant-primary-color: var(--ant-primary-7);
    --ant-primary-color-outline: var(--ant-primary-7);
}

:root .orange {
    --ant-primary-1: var(--sunset-orange-1);
    --ant-primary-2: var(--sunset-orange-2);
    --ant-primary-3: var(--sunset-orange-3);
    --ant-primary-4: var(--sunset-orange-4);
    --ant-primary-5: var(--sunset-orange-5);
    --ant-primary-6: var(--sunset-orange-6);
    --ant-primary-7: var(--sunset-orange-7);
    --ant-primary-8: var(--sunset-orange-8);
    --ant-primary-9: var(--sunset-orange-9);
    --ant-primary-10: var(--sunset-orange-10);
}
:root .light.orange {
    --ant-primary-color-hover: var(--ant-primary-6);
    --ant-primary-color-active: var(--ant-primary-8);
    --ant-primary-color: var(--ant-primary-7);
    --ant-primary-color-outline: var(--ant-primary-7);
}
:root .dark.orange {
    --ant-primary-color-hover: var(--ant-primary-5);
    --ant-primary-color-active: var(--ant-primary-7);
    --ant-primary-color: var(--ant-primary-6);
    --ant-primary-color-outline: var(--ant-primary-6);
}

:root .blue {
    --ant-primary-1: var(--daybreak-blue-1);
    --ant-primary-2: var(--daybreak-blue-2);
    --ant-primary-3: var(--daybreak-blue-3);
    --ant-primary-4: var(--daybreak-blue-4);
    --ant-primary-5: var(--daybreak-blue-5);
    --ant-primary-6: var(--daybreak-blue-6);
    --ant-primary-7: var(--daybreak-blue-7);
    --ant-primary-8: var(--daybreak-blue-8);
    --ant-primary-9: var(--daybreak-blue-9);
    --ant-primary-10: var(--daybreak-blue-10);
}
:root .light.blue {
    --ant-primary-color-hover: var(--ant-primary-6);
    --ant-primary-color-active: var(--ant-primary-8);
    --ant-primary-color: var(--ant-primary-7);
    --ant-primary-color-disabled: var(--ant-primary-2);
    --ant-primary-color-outline: var(--ant-primary-7);
}

:root .dark.blue {
    --ant-primary-color-hover: var(--ant-primary-6);
    --ant-primary-color-active: var(--ant-primary-8);
    --ant-primary-color: var(--ant-primary-7);
    --ant-primary-color-outline: var(--ant-primary-7);
}

:root .green {
    --ant-primary-1: var(--polar-green-1);
    --ant-primary-2: var(--polar-green-2);
    --ant-primary-3: var(--polar-green-3);
    --ant-primary-4: var(--polar-green-4);
    --ant-primary-5: var(--polar-green-5);
    --ant-primary-6: var(--polar-green-6);
    --ant-primary-7: var(--polar-green-7);
    --ant-primary-8: var(--polar-green-8);
    --ant-primary-9: var(--polar-green-9);
    --ant-primary-10: var(--polar-green-10);
}

:root .light.green {
    --ant-primary-color-hover: var(--ant-primary-7);
    --ant-primary-color-active: var(--ant-primary-9);
    --ant-primary-color: var(--ant-primary-8);
    --ant-primary-color-outline:  var(--ant-primary-8);
}
:root .dark.green {
    --ant-primary-color-hover: var(--ant-primary-6);
    --ant-primary-color-active: var(--ant-primary-8);
    --ant-primary-color: var(--ant-primary-7);
    --ant-primary-color-outline: var(--ant-primary-7);
}

:root .cyan {
    --ant-primary-1: var(--cyan-1);
    --ant-primary-2: var(--cyan-2);
    --ant-primary-3: var(--cyan-3);
    --ant-primary-4: var(--cyan-4);
    --ant-primary-5: var(--cyan-5);
    --ant-primary-6: var(--cyan-6);
    --ant-primary-7: var(--cyan-7);
    --ant-primary-8: var(--cyan-8);
    --ant-primary-9: var(--cyan-9);
    --ant-primary-10: var(--cyan-10);
}

:root .light.cyan {
    --ant-primary-color-hover: var(--ant-primary-7);
    --ant-primary-color-active: var(--ant-primary-9);
    --ant-primary-color: var(--ant-primary-8);
    --ant-primary-color-outline: var(--ant-primary-8);
}
:root .dark.cyan {
    --ant-primary-10: #b2f1e8;
    --ant-primary-color-hover: var(--ant-primary-6);
    --ant-primary-color-active: var(--ant-primary-8);
    --ant-primary-color: var(--ant-primary-7);
    --ant-primary-color-outline: var(--ant-primary-7);
}

:root .magenta {
    --ant-primary-1: var(--magenta-1);
    --ant-primary-2: var(--magenta-2);
    --ant-primary-3: var(--magenta-3);
    --ant-primary-4: var(--magenta-4);
    --ant-primary-5: var(--magenta-5);
    --ant-primary-6: var(--magenta-6);
    --ant-primary-7: var(--magenta-7);
    --ant-primary-8: var(--magenta-8);
    --ant-primary-9: var(--magenta-9);
    --ant-primary-10: var(--magenta-10);
}

:root .light.magenta {
    --ant-primary-color-hover: var(--ant-primary-5);
    --ant-primary-color-active: var(--ant-primary-7);
    --ant-primary-color: var(--ant-primary-6);
    --ant-primary-color-disabled: var(--ant-primary-2);
    --ant-primary-color-outline: var(--ant-primary-6);
}
:root .dark.magenta {
    --ant-primary-color-hover: var(--ant-primary-6);
    --ant-primary-color-active: var(--ant-primary-8);
    --ant-primary-color: var(--ant-primary-7);
    --ant-primary-color-disabled: var(--ant-primary-2);
    --ant-primary-color-outline: var(--ant-primary-7);
}

:root .light {
    --neutral-1: #ffffff;
    --neutral-2: #fafafa;
    --neutral-3: #f5f5f5;
    --neutral-4: #f0f0f0;
    --neutral-5: #d9d9d9;
    --neutral-6: #bfbfbf;
    --neutral-7: #757575;
    --neutral-8: #595959;
    --neutral-9: #434343;
    --neutral-10: #262626;
    --neutral-11: #1f1f1f;
    --neutral-12: #141414;
    --neutral-13: #000000;
    --input-error: #ff4d6e;
    --text: var(--neutral-10);
    --border-button: var(--neutral-5);
    --background: var(--neutral-1);
    --empty-text: #bfbfbf;
    --hover: var(--neutral-3);
    --yellow: #fffbe6;
    --red: #fff1f0;
    --green: #f6ffed;
    --placeholder: var(--neutral-6);
    --secondary-text: var(--neutral-7);
    --image-empty-background: var(--neutral-3);
    --orange-background: #fff7e6;
    --orange-border: #ffd591;
    --list-background: var(--ant-primary-1);
    --list-item: var(--ant-primary-color);
    --border: var(--neutral-4);
    -webkit-focus-ring-color: var(--ant-primary-color);
    --sidebar: var(--neutral-5);
    --table-header: var(--neutral-2);
    --cyan: #13c2c2;
    --response-background: var(--neutral-4);
    --message-background: var(--neutral-9);
    --picture-border: var(--neutral-4);
    --warning: var(var(--sunset-orange-5));
    --share-link: #9254de;
    --share-email: #8c8c8c;
    --share-facebook: #1890ff;
    --share-X: #262626;
    --share-linkedin: #0050b3;
    --share-telegram: #69c0ff;
    --share-whatsapp: #52c41a;
    --image-background: var(--neutral-13);
    --profile-border: var(--neutral-3);
    --tag-filter-disable-background: var(--neutral-4);
    --tag-filter-background: var(--neutral-4);
    --tag-filter-active-background: var(--ant-primary-1);
    --antd-arrow-background-color: var(--text);
    --poll_option_background: var(--neutral-1);

    --alert-success-icon: var(--polar-green-5);
    --alert-success-outline: var(--polar-green-3);
    --alert-success-background: var(--polar-green-1);
    --alert-success-text: var(--polar-green-7);


    --alert-error-icon: var(--dust-red-5);
    --alert-error-outline: var(--dust-red-3);
    --alert-error-background: var(--dust-red-1);
    --alert-error-text: var(--dust-red-7);

    --alert-warning-icon: var(--sunset-orange-5);
    --alert-warning-outline: var(--sunset-orange-3);
    --alert-warning-background: var(--sunset-orange-1);
    --alert-warning-text: var(--sunset-orange-7);

    --alert-neutral-icon: var(--neutral-12);
    --alert-neutral-outline: var(--neutral-4);
    --alert-neutral-background: var(--neutral-4);
    --alert-neutral-text: var(--neutral-12);

    --card-secondary-background: var(--neutral-4);


}

:root .dark {
    --neutral-1: #000000;
    --neutral-2: #141414;
    --neutral-3: #1f1f1f;
    --neutral-4: #262626;
    --neutral-5: #434343;
    --neutral-6: #595959;
    --neutral-7: #8c8c8c;
    --neutral-8: #bfbfbf;
    --neutral-9: #f0f0f0;
    --neutral-10: #f5f5f5;
    --neutral-11: #fafafa;
    --neutral-12: #ffffff;
    --neutral-13: #ffffff;
    --input-error: #f55875;
    --text: var(--neutral-9);
    --border-button: var(--neutral-7);
    --background: var(--neutral-4);
    --border: var(--neutral-5);
    
    --table-header: var(--neutral-3);
    --empty-text: #f0f0f0;
    --hover: var(--neutral-5);
    --sidebar: var(--neutral-3);
    --message-background: var(--neutral-9);
    --response-background: var(--neutral-2);
    --picture-border: var(--neutral-5);

    --poll_option_background: var(--neutral-5);

    --profile-border: var(--neutral-5);
    --card-secondary-background: var(--neutral-3);

    --image-background: var(--neutral-2);
    --cyan: #13a8a8;
    --yellow: #2b2111;
    --red: #2a1215;
    --green: #162312;
    --orange-background: #2b1d11;
    --orange-border: #aa6215;
    --warning: var(--sunset-orange-7);
    --list-background: var(--ant-primary-2);
    --placeholder: var(--neutral-6);
    --image-empty-background: var(--neutral-2);
    --list-item: var(--ant-primary-color);
    --secondary-text: var(--neutral-7);
    -webkit-focus-ring-color: var(--ant-primary-color);
    --share-link: #9254de;
    --share-email: #8c8c8c;
    --share-facebook: #177ddc;
    --share-X: #f0f0f0;
    --share-linkedin: #65b7f3;
    --share-telegram: #164c7e;
    --share-whatsapp: #49aa19;
    --tag-filter-disable-background: var(--neutral-4);
    --tag-filter-background: var(--neutral-5);
    --tag-filter-active-background: var(--ant-primary-1);
    --antd-arrow-background-color: var(--text);

    --alert-success-icon: var(--polar-green-5);
    --alert-success-outline: var(--polar-green-3);
    --alert-success-background: var(--polar-green-1);
    --alert-success-text: var(--polar-green-7);


    --alert-error-icon: var(--dust-red-5);
    --alert-error-outline: var(--dust-red-3);
    --alert-error-background: var(--dust-red-1);
    --alert-error-text: var(--dust-red-7);

    --alert-warning-icon: var(--sunset-orange-5);
    --alert-warning-outline: var(--sunset-orange-3);
    --alert-warning-background: var(--sunset-orange-1);
    --alert-warning-text: var(--sunset-orange-7);

    --alert-neutral-icon: var(--neutral-12);
    --alert-neutral-outline: var(--neutral-4);
    --alert-neutral-background: var(--neutral-2);
    --alert-neutral-text: var(--neutral-12);



}


:root .light.turingo_color {
    --ant-primary-color-hover: var(--ant-primary-5);
    --ant-primary-color-active: var(--ant-primary-7);
    --ant-primary-color: var(--ant-primary-6);
    --ant-primary-color-outline: var(--ant-primary-6);
}

:root .dark.turingo_color {
    --ant-primary-color-hover: var(--ant-primary-6);
    --ant-primary-color-active: var(--ant-primary-8);
    --ant-primary-color: var(--ant-primary-7);
    --ant-primary-color-outline: var(--ant-primary-7);
}

.light .turingo.ant-divider-horizontal.ant-divider-with-text::before,
.light .turingo.ant-divider-horizontal.ant-divider-with-text::after {
    position: unset !important;
    border-top-color: var(--neutral-5) !important;
}

.dark .turingo.ant-divider-horizontal.ant-divider-with-text::before,
.dark .turingo.ant-divider-horizontal.ant-divider-with-text::after {
    position: unset !important;
    border-top-color: var(--neutral-5) !important;
}

.turingo .ant-divider-inner-text{
    color: var(--neutral-7) !important;
    padding: 0 16px !important;
    font-size: 14px;
}



@media (max-width: 991px) {
    .turingo .ant-divider-inner-text{
        font-size: 16px;
    }
}

.ant-btn-primary[disabled] path {
    fill: var(--neutral-6) !important;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
    background-color: var(--neutral-2) !important;
    border-color: var(--neutral-5) !important;
}

.bold {
    font-weight: 500;
}

.turingo-message:first-child {
    margin-top: 16px;
}

.grid-container div:first-of-type:nth-last-of-type(7) {
    grid-column: 3 / span 4;
}

.grid-container div:first-of-type:nth-last-of-type(7) ~ div {
    grid-column: span 4;
}

@media (min-width: 992px) {
    .turingo-title {
        font-size: 16px !important;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 0px;
    }

    .turingo-sidebar-text {
        font-size: 16px !important;
        line-height: 24px;
        margin-bottom: 0px;
    }

    .turingo-text {
        font-size: 14px !important;
        line-height: 22px !important;
        margin-bottom: 0px;
    }

    .turingo-subtext {
        font-size: 12px !important;
        line-height: 20px;
        margin-bottom: 0px;
    }
    .turingo-secondary {
        font-size: 12px !important;
        line-height: 20px;
        color: var(--secondary-text) !important;
        margin-bottom: 0px;
    }
}

@media (max-width: 991px) {
    .turingo-title {
        font-size: 18px !important;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 0px;
    }
    .turingo-sidebar-text {
        font-size: 18px !important;
        line-height: 24px;
        margin-bottom: 0px;
    }

    .turingo-text {
        font-size: 16px !important;
        line-height: 24px !important;
        margin-bottom: 0px;
    }
    .turingo-subtext {
        font-size: 14px !important;
        line-height: 22px;
        margin-bottom: 0px;
    }
    .turingo-secondary {
        font-size: 14px !important;
        line-height: 22px;
        color: var(--secondary-text) !important;
        margin-bottom: 0px;
    }
}

.ant-menu-light .ant-menu-item:active,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:active {
    color: var(--ant-primary-color) !important;
}

.btnHoverPrimary {
    cursor: pointer;
    transition: 0.3s all;
}
.btnHoverPrimary:hover {
    background-color: var(--post-action-background) !important;
    color: var(--post-action-color) !important;
}

.btnHoverPrimary path {
    transition: 0.3s all;
}
.btnHoverPrimary:hover path {
    fill: var(--ant-primary-color) !important;
}
html body {
    padding-bottom: env(safe-area-inset-bottom);
    padding: 0;
    margin: 0;
    height: 100vh;
    font-family: 'Roboto', 'Helvetica Neue', Arial, -apple-system, BlinkMacSystemFont, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji' !important;
    font-display: swap;
}

body {
    color: var(--text) !important;
    width: 100% !important;
    overflow: initial !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--neutral-13);
    -webkit-box-shadow: 0 0 0px 1000px var(--neutral-4) inset;
    transition: background-color 5000s ease-in-out 0s;
}

.scrollHorizontal::-webkit-scrollbar {
    height: 12px;
    background-color: transparent;
}

.light .scrollHorizontal::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    border-top: 4px #b80d0d00 solid;
    background-clip: padding-box;
}

.dark .scrollHorizontal::-webkit-scrollbar-track {
}

.light .scrollHorizontal::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.dark .scrollHorizontal::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;

    border-top: 4px #b80d0d00 solid;
    background-clip: padding-box;
}
.scrollStyle {
    padding-left: 2px;
    padding-right: 2px;
}

.scrollStyle::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

.light .scrollStyle::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    border-left: 4px #b80d0d00 solid;
    background-clip: padding-box;
}

.dark .scrollStyle::-webkit-scrollbar-track {
}

.light .scrollStyle::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.dark .scrollStyle::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;

    border-left: 4px #b80d0d00 solid;
    background-clip: padding-box;
}

.light::-webkit-scrollbar-track {
    background: #e6e6e6;
    border-left: 1px solid #dadada;
    transition: all 0.3s;
}

.light::-webkit-scrollbar-thumb {
    background: #b0b0b0;
    border: solid 3px #e6e6e6;
    border-radius: 7px;
    transition: all 0.3s;
}

.light::-webkit-scrollbar-thumb:hover {
    background: black;
}

.dark::-webkit-scrollbar {
    width: 15px;
    transition: all 0.3s;
}

.dark::-webkit-scrollbar-track {
    background: #202020;
    border-left: 1px solid #2c2c2c;
    transition: all 0.3s;
}

.dark::-webkit-scrollbar-thumb {
    background: #3e3e3e;
    border: solid 3px #202020;
    border-radius: 7px;
    transition: all 0.3s;
}

.dark::-webkit-scrollbar-thumb:hover {
    background: white;
}

html {
    overflow-y: scroll !important;
}

.ant-menu-vertical {
    border-right: 0 !important;
}

.ant-list-item-meta-title {
    margin-bottom: 0 !important;
}

/*         button:active {
    outline: none !important;
    border: 0 !important;
} */

/*     button:focus {
    outline: none !important;
    outline-offset: none !important;
} */

.hashtag {
    color: blue;
}

.ant-list-vertical .ant-list-item-meta {
    margin-bottom: 0px !important;
}

.light .ant-layout {
    background-color: #f5f5f5 !important;
}

.ant-image-img {
    object-fit: cover;
}

.ant-menu-horizontal {
    border-bottom: none !important;
}

.trigger {
    font-size: 18px;
    line-height: 36px;
    padding: 0 24px;
    cursor: pointer;
    transition: fill 0.3s;
}



.tgo-hover-button svg {
    transition: color 0.3s cubic-bezier(0.03, 0.42, 0.17, 1.03);
}

.tgo-hover-button:hover svg {
    color: var(--ant-primary-color-hover);
}
.tgo-hover-button:active svg {
    color: var(--ant-primary-color-active);
}

.hover-svg svg {
    transition: fill 0.3s;
}

.ant-menu-submenu-title:hover svg{
    fill: var(--ant-primary-color);

}

/* .hover-svg div {
    transition: color 0.3s;
} */
.hover-svg:hover svg {
    fill: var(--ant-primary-color);
}
.hover-svg:hover div {
    color: var(--ant-primary-color);
}

.disabled-svg svg {
    fill: black;
}

.border {
    border: 0 !important;
}

.height-one {
    padding-top: 70% !important;
}

.height-two {
    padding-top: 30% !important;
}

label[for='normal_login_password'] {
    width: 100%;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin-top: -2px !important;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 0px solid #f0f0f0 !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
    font-size: 24px !important;
}

.light .ant-layout-sider:not(.community) {
    background: #fff;
    box-shadow: inset -1px 0px 0px #f0f0f0;
}

.ant-layout-header {
    height: 56px !important;
    line-height: 56px !important;
}
.light .ant-layout-header {
    background: #fff;
    box-shadow: 0px 2px 8px #f0f1f2;
}

.ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dark .react-tel-input .flag-dropdown {
    background: transparent;
    border: none;
    border-right: 1px solid #434343;
}

.light .react-tel-input .flag-dropdown {
    background: transparent;
    border: none;
    border-right: 1px solid #d9d9d9;
}

.dark .react-tel-input .country-list .country:hover {
    background: #434343;
}

.dark .react-tel-input .country-list .country.highlight {
    background-color: #434343;
}

.dark .react-tel-input .flag-dropdown.open {
    background: transparent;
}

.dark .react-tel-input .form-control {
    border: 1px solid #434343;
}

.form-control {
    transition: all 0.3s;
}

.flag-dropdown {
    transition: all 0.3s;
}

.flag-dropdown:hover {
    background-color: var(--neutral-5) !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background-color: unset !important;
}

.react-tel-input .flag-dropdown.open {
    z-index: 2;
    border-radius: 2px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background: unset !important;
    border-radius: 2px 0px 0px 2px !important;
}

.form-control:hover {
    border: 1px solid #8c8c8c !important;
}

.form-control:focus {
    border: 1px solid #8c8c8c !important;
    box-shadow: 0px 0px 0px 2px rgba(140, 140, 140, 0.2);
}

.hasError.react-tel-input .form-control:hover {
    border: 1px solid #ff4d4f !important;
}

.hasError.react-tel-input .form-control:focus {
    border: 1px solid #ff4d4f !important;
    box-shadow: 0px 0px 0px 2px rgb(255 56 98 / 20%) !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-top: 7px !important;
}

.ant-input:hover:not(.ant-input-borderless, .ant-input-status-error) {
    border: 1px solid #8c8c8c !important;
}

.ant-input:focus:not(.ant-input-borderless) {
    border: 1px solid #8c8c8c !important;
    box-shadow: 0px 0px 0px 2px rgba(140, 140, 140, 0.2) !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    border-color: #8c8c8c !important;
    border-right-width: 1px !important;
    box-shadow: 0px 0px 0px 2px rgba(140, 140, 140, 0.2) !important;
}

:not(.ant-form-item-has-error) .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled, .ant-input-affix-wrapper-status-error):hover {
    border-color: #8c8c8c !important;
    border-right-width: 1px !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #8c8c8c !important;
    box-shadow: 0px 0px 0px 2px rgba(140, 140, 140, 0.2) !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: 1px solid #8c8c8c !important;
}

.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
    border: 1px solid #ff4d4f !important;
    box-shadow: 0px 0px 0px 2px rgb(255 56 98 / 20%);
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
    border: 1px solid #ff4d4f !important;
    box-shadow: 0px 0px 0px 2px rgb(255 56 98 / 20%);
}

.ant-input-password .ant-input:hover {
    border: 0px !important;
    box-shadow: none !important;
}

.ant-input-password .ant-input:focus {
    border: 0px !important;
    box-shadow: none !important;
}

.light .react-tel-input .form-control {
    border: 1px solid #d9d9d9;
}

.react-tel-input .form-control {
    background: transparent;
    width: 100%;
    border: none;
    border-radius: 2px;
}

.dark .react-tel-input .country-list {
    background: #262626;
    border: 1px solid #434343;
}

.dark .react-tel-input .flag-dropdown.open .selected-flag {
    background: transparent;
}

.ant-form-item-control-input {
    min-height: 0px !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 16px;
}

.ant-form-item-explain.ant-form-item-explain-success {
    margin-bottom: 16px;
}

.ant-form-item-explain.ant-form-item-explain-validating {
    margin-bottom: 16px;
}

.reactEasyCrop_CropArea {
    border: 4px solid var(--ant-primary-color);
}

.ant-modal-content {
    border-radius: 4px;
}

.ant-modal-footer {
    border-top: 0px;
    padding: 24px;
}

.ant-modal-header {
    border-bottom: 0px;
    border-radius: 4px 4px 0px 0px;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
    color: rgba(255, 255, 255, 0.85);
    background: var(--ant-primary-color);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(255, 255, 255, 0.85);
    background-color: var(--ant-primary-color);
}

.ant-slider-track {
    background-color: var(--ant-primary-color) !important;
    border-radius: 2px;
}
.ant-slider-rail {
    background-color: var(--border) !important;
}

/* .dark .ant-slider-step {
    background-color: rgba(255, 255, 255, 0.65);
}

.light .ant-slider-step {
    background-color: rgba(0, 0, 0, 0.85);
} */

.ant-slider-handle {
    background-color: var(--ant-primary-color) !important;
    border: solid 2px var(--ant-primary-color) !important;
    border-radius: 50%;
    box-shadow: 0;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: 0px solid #595959 !important;
    width: 99.4% !important;
}

.ant-btn.underline > span {
    transition: all 0.3s;
    text-decoration: underline !important;
}

.noscroll {
    overflow: hidden !important;
}

.dark .ant-alert.ant-alert-info.blue {
    background-color: #111d2c;
    border: 1px solid #15395b;
}

.light .ant-alert.ant-alert-info.blue {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
}

.dark .ant-alert.ant-alert-info.dashed {
    background-color: transparent;
    border: 1px solid #177ddc;
}

.light .ant-alert.ant-alert-info.dashed {
    background-color: transparent;
    border: 1px solid #91d5ff;
}

.dark .blue .ant-alert-icon {
    color: #177ddc !important;
}

.light .blue .ant-alert-icon {
    color: #1890ff !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 500;
}

.dark .ant-menu-submenu {
    border-top: 1px solid #262626;
    margin-top: -1px !important;
}

.light .ant-menu-submenu {
    border-top: 0px !important;
    box-shadow: none;
    margin-top: 0px !important;
}

.dark .ant-menu-submenu-popup {
    border: 1px solid #595959 !important;
}

.light .ant-menu-submenu-popup {
    border: 1px solid rgba(0, 0, 0, 0.06) !important;
}

h1.ant-typography,
.ant-typography h1 {
    font-weight: 500 !important;
}

h2.ant-typography,
.ant-typography h2 {
    font-weight: 500 !important;
}

h3.ant-typography,
.ant-typography h3 {
    font-weight: 500 !important;
}

h4.ant-typography,
.ant-typography h4 {
    font-weight: 500 !important;
}

h5.ant-typography,
.ant-typography h5 {
    font-weight: 500 !important;
}

.ant-typography strong {
    font-weight: 500 !important;
}

.ant-menu-inline-collapsed .ant-menu-item.ant-menu-item-selected {
    border-right: 3px solid var(--ant-primary-color);
    transition: all 0.3;
}

.ant-menu-light .ant-menu-item:active,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:active {
    color: white;
}


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:active {
    background-color: var(--ant-primary-1) !important;
    color: var(--ant-primary-color);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:active {
    background-color: var(--ant-primary-1) !important;
    color: var(--ant-primary-color) !important;
}

.ant-menu-item-selected:active a:hover {
    color: var(--ant-primary-color) !important;
}

.ant-menu-item:active a:hover {
    color: var(--ant-primary-color) !important;
}

.ant-dropdown-menu {
    border: 1px solid var(--neutral-5) !important;
}

.ant-list-item-meta-description {
    font-size: 12px;
}

.ant-rate-disabled > .ant-rate-star > div:hover,
.ant-rate-star > div:focus-visible {
    transform: scale(1) !important;
}

/* .ant-avatar > img {
    height: unset !important;
} */

.carousel__dot--selected {
    opacity: 1 !important;
}

.turingoCarousel .slick-slide {
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-bottom: 12px !important;
}

/* .turingoCarousel .slick-slide:first-child {
    padding-right: 12px !important;
    padding-bottom: 12px !important;
}
.turingoCarousel .slick-slide:last-child {
    padding-left: 12px !important;
    padding-bottom: 12px !important;
}
 */

/*         .slick-slider {
    width: 96.5vw;
    max-width: 1260px;
} */

.avatar-group > div {
    margin-left: -8px;
}

.avatar-group {
    display: flex;
    margin-left: 8px;
}

@media (min-width: 1351px) {
    .turingoCarousel .slick-slider {
        width: 96.5vw;
        max-width: 1260px;
    }
}

@media (min-width: 993px) and (max-width: 1350px) {
    .turingoCarousel .slick-slider {
        width: 88.5vw;
        max-width: 1260px;
    }
}

@media (max-width: 992px) {
    .turingoCarousel .slick-slider {
        width: 93.5vw;
        max-width: 1260px;
    }
}

.turingoCarousel .slick-disabled {
    visibility: hidden;
}

.turingoCarousel .slick-list {
    padding: 0 20% 0 0 !important;
}

.dark .turingoCarousel .slick-prev:before,
.dark .turingoCarousel .slick-next:before {
    color: white !important;
}

.light .turingoCarousel .slick-prev:before,
.light .turingoCarousel .slick-next:before {
    color: black !important;
}

.turingoCarousel .slick-dots li {
    width: 24px;
    height: unset !important;
    border-radius: 100px;
}

.light .turingoCarousel .slick-dots .slick-active > div {
    background-color: rgba(0, 0, 0, 0.85);
    opacity: 1 !important;
}

.light .turingoCarousel .slick-dots > ul > li > div {
    background-color: rgba(0, 0, 0, 0.85);
    opacity: 0.3 !important;
}

.dark .turingoCarousel .slick-dots .slick-active > div {
    background-color: #f0f0f0;
    opacity: 1 !important;
}

.dark .turingoCarousel .slick-dots > ul > li > div {
    background-color: #f0f0f0;
    opacity: 0.3 !important;
}

.turingoCarousel .slick-dots {
    bottom: -28px;
}

.highlightCarousel .slick-slide {
    padding-left: 24px !important;
    padding-right: 24px !important;
}
@media (min-width: 1351px) {
    .highlightCarousel .slick-slider {
        width: 328px;
        max-width: 328px;
    }
}

@media (min-width: 993px) and (max-width: 1350px) {
    .highlightCarousel .slick-slider {
        width: 328px;
        max-width: 328px;
    }
}

@media (max-width: 992px) {
    .highlightCarousel .slick-slider {
        width: 328px;
        max-width: 328px;
    }
    .turingoCarousel .slick-dots {
        bottom: -60px;
    }
}

.infinite-scroll-component video {
    height: unset !important;
}
.highlightCarousel .slick-disabled {
    visibility: hidden;
}

.highlightCarousel .slick-list {
    padding: 0 20% 0 0 !important;
}

.highlightCarousel .slick-track {
    height: 450px !important;
}

.dark .highlightCarousel .slick-prev:before,
.dark .highlightCarousel .slick-next:before {
    color: white !important;
}

.light .highlightCarousel .slick-prev:before,
.light .highlightCarousel .slick-next:before {
    color: black !important;
}

.highlightCarousel .slick-dots li {
    width: 28px;
    height: unset !important;
    border-radius: 100px;
}

.light .highlightCarousel .slick-dots .slick-active > div {
    background-color: rgba(0, 0, 0, 0.85);
    opacity: 1 !important;
}

.light .highlightCarousel .slick-dots > ul > li > div {
    background-color: rgba(0, 0, 0, 0.85);
    opacity: 0.3 !important;
}

.dark .highlightCarousel .slick-dots .slick-active > div {
    background-color: #f0f0f0;
    opacity: 1 !important;
}

.dark .highlightCarousel .slick-dots > ul > li > div {
    background-color: #f0f0f0;
    opacity: 0.3 !important;
}

.highlightCarousel .slick-dots {
    bottom: -30px;
}
.mobile .ant-switch {
    min-width: 51px !important;
    height: 31px !important;
}

.mobile .ant-switch-handle::before {
    border-radius: 34px !important;
}

.mobile .ant-switch-handle {
    width: 27px !important;
    height: 27px !important;
}

.mobile .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 27px - 2px) !important;
}

.mobile .ant-switch-checked .ant-switch-inner {
    margin: 0 32px 0 7px !important;
}

.mobile .ant-switch-inner {
    margin: 0 7px 0 32px !important;
}

.mobile .ant-switch-loading-icon {
    top: 6px !important;
}

.light .ant-list .ant-card {
    background: white !important;
}

.dark .ant-list .ant-card {
    background: #262626 !important;
}

.ant-steps-item-process .ant-steps-item-icon {
    background: var(--cyan-6) !important;
    border-color: var(--cyan-6) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
    color: var(--cyan-6) !important;
    border-color: var(--cyan-6) !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: var(--cyan-6) !important;
}

.ant-steps-item-finish .ant-steps-icon {
    color: var(--cyan-6) !important;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: var(--cyan-6) !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: var(--cyan-6) !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: var(--cyan-6) !important;
}

._hj-s3UIi__styles__globalStyles ._hj-SU8LU__styles__primaryButton {
    color: white !important;
}

.lateral-padding {
    padding: 0 24px 0px 24px;
}
@media (max-width: 680px) {
    .lateral-padding {
        padding: 0 16px 0px 16px;
    }
}

.ant-radio-button-wrapper {
    font-weight: 400 !important;
    font-size: 14px;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.large .ant-radio-button-wrapper {
    font-weight: 400 !important;
    height: 38px !important;
    font-size: 14px;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.ant-btn-circle {
    min-width: 38px !important;
    height: 38px !important;
}

.expandable{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

}
@media (max-width: 991px) {

    .expandable{
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .expandable :not(:last-child){
        margin-bottom: 16px;
    }
    

    .react-tel-input .form-control {
        font-size: 16px !important;
    }

    .mentions--multiLine {
        flex-grow: 1;
    }

    .ant-form-item-label > label {
        font-size: 16px !important;
    }

    .ant-input {
        font-size: 16px !important;
    }

    .ant-form-item {
        font-size: 16px !important;
    }


    button.ant-btn:not(.ant-btn-circle, .ant-input-search-button, .ant-btn-lg, .sideBarBtn, .custom) {
        height: 44px !important;
        font-size: 16px;
    }

    .ant-btn-circle {
        min-width: 44px !important;
        height: 44px !important;
    }

    .ant-btn-icon-only {
        height: 44px !important;
        width: 44px !important;
    }

    .ant-menu-vertical > .ant-menu-item,
    .ant-menu-vertical-left > .ant-menu-item,
    .ant-menu-vertical-right > .ant-menu-item,
    .ant-menu-inline > .ant-menu-item,
    .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
        height: 44px;
        line-height: 40px;
    }

    .ant-typography {
        font-size: 16px;
    }

    span:not(.anticon, .i-icon, .ant-badge, .ant-scroll-number-only-unit, .ant-scroll-number-only, .nostyle, .ant-select-selection-item-content) {
        font-size: 16px;
    }

    a {
        font-size: 16px;
    }

    p {
        font-size: 16px;
    }

    .ant-radio-button-wrapper {
        font-weight: 400 !important;
        font-size: 16px;
        height: 44px !important;
        display: inline-flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .ant-alert-message {
        font-size: 16px;
    }

    .ant-steps-horizontal .ant-steps-item-title {
        font-size: 14px;
    }
}

.ant-popover-inner-content {
    padding: 0px !important;
}

.ant-list-item-meta-description {
    color: var(--neutral-13) !important;
}

.ant-notification-notice-message {
    margin-bottom: 0px !important;
}

.ant-notification-notice-closable .ant-notification-notice-message {
    padding-right: 0px !important;
}

.ant-notification-notice {
    padding: 0px !important;
    margin-bottom: 0px !important;
}

.ant-list-vertical .ant-list-item-action > li {
    padding: 0px;
}

.ant-list-vertical .ant-list-item-action {
    margin-top: 0px;
    margin-left: 16px;
}

p {
    color: var(--text);
}

input[type='text'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='email'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='password'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='search'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='tel'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='text'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='url'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.light .turingo-ant-carousel {
    color: rgba(0, 0, 0, 0.85) !important;
}

.light .turingo-ant-carousel .slick-slider {
    -webkit-tap-highlight-color: transparent !important;
}

.light .turingo-ant-carousel .slick-prev,
.light .turingo-ant-carousel .slick-next {
    color: transparent !important;
    background: transparent !important;
    border: 0 !important;
}

.light .turingo-ant-carousel .slick-prev:hover,
.light .turingo-ant-carousel .slick-next:hover,
.light .turingo-ant-carousel .slick-prev:focus,
.light .turingo-ant-carousel .slick-next:focus {
    color: transparent !important;
    background: transparent !important;
}

.light .turingo-ant-carousel .slick-dots li button {
    color: transparent !important;
    background: #fff !important;
    border: 0 !important;
    border-radius: 1px !important;
}

.dark .turingo-ant-carousel {
    color: rgba(255, 255, 255, 0.85) !important;
}

.dark .turingo-ant-carousel .slick-slider {
    -webkit-tap-highlight-color: transparent !important;
}

.dark .turingo-ant-carousel .slick-prev,
.dark .turingo-ant-carousel .slick-next {
    color: transparent !important;
    background: transparent !important;
    border: 0 !important;
}

.dark .turingo-ant-carousel .slick-prev:hover,
.dark .turingo-ant-carousel .slick-next:hover,
.dark .turingo-ant-carousel .slick-prev:focus,
.dark .turingo-ant-carousel .slick-next:focus {
    color: transparent !important;
    background: transparent !important;
}

.dark .turingo-ant-carousel .slick-dots li button {
    color: transparent !important;
    background: #262626 !important;
    border: 0 !important;
    border-radius: 1px !important;
}

.turingo-ant-carousel .slick-dots li {
    position: relative !important;
    display: inline-block !important;
    flex: 0 1 auto !important;
    box-sizing: content-box !important;
    width: 16px !important;
    height: 3px !important;
    margin: 0 3px !important;
    padding: 0 !important;
    text-align: center !important;
    text-indent: -999px !important;
    vertical-align: top !important;
    transition: all 0.5s !important;
}

.turingo-ant-carousel .slick-dots {
    position: absolute !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    z-index: 15 !important;
    display: flex !important;
    justify-content: center !important;
    margin-right: 15% !important;
    margin-left: 15% !important;
    padding-left: 0 !important;
    list-style: none !important;
}

.dark .turingo-ant-carousel .slick-dots li.slick-active button {
    background: #262626 !important;
}

.turingo-ant-carousel .slick-dots-bottom {
    bottom: 12px !important;
}

.turingo-ant-carousel .slick-dots li.slick-active {
    width: 24px !important;
}

[contenteditable='true']:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.mobile .lastStep .ant-steps-item-title {
    margin-left: -7px !important;
}

td {
    transition: all 0.3s;
}
.infinite-scroll-component tr {
    border-bottom: 1px solid var(--border);
}

.headerColumn::after {
    width: 10px;
    content: ' ';
    box-shadow: inset -10px 0 8px -8px #00000026;
}

.headerColumn:after {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    width: 30px;
    transform: translate(-100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
}

.fixedColumn::after {
    height: 72.5px;
    width: 10px;
    content: ' ';
    box-shadow: inset -10px 0 8px -8px #00000026;
}

.fixedColumn:after {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    width: 30px;
    transform: translate(-100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
}

.fixedColumnSmall::after {
    height: 60px;
    width: 10px;
    content: ' ';
    box-shadow: inset -10px 0 8px -8px #00000026;
}

.fixedColumnSmall:after {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    width: 30px;
    transform: translate(-100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
}
.goBack:hover {
    color: var(--ant-primary-color);
    cursor: pointer;
}

.goBack:before {
    content: '';
    display: block;
    background: var(--neutral-13);
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.70709 11.0355C8.09762 11.426 8.73078 11.426 9.1213 11.0355C9.51183 10.645 9.51183 10.0118 9.1213 9.62131L7.00001 7.50002L9.12133 5.3787C9.51186 4.98817 9.51186 4.35501 9.12133 3.96448C8.73081 3.57396 8.09764 3.57396 7.70712 3.96448L4.88484 6.78676C4.88278 6.78879 4.88072 6.79083 4.87866 6.79288C4.48814 7.18341 4.48814 7.81657 4.87866 8.2071L4.87869 8.20712L7.70709 11.0355Z' fill='%23F0F0F0'/%3E%3C/svg%3E%0A");
    mask-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.70709 11.0355C8.09762 11.426 8.73078 11.426 9.1213 11.0355C9.51183 10.645 9.51183 10.0118 9.1213 9.62131L7.00001 7.50002L9.12133 5.3787C9.51186 4.98817 9.51186 4.35501 9.12133 3.96448C8.73081 3.57396 8.09764 3.57396 7.70712 3.96448L4.88484 6.78676C4.88278 6.78879 4.88072 6.79083 4.87866 6.79288C4.48814 7.18341 4.48814 7.81657 4.87866 8.2071L4.87869 8.20712L7.70709 11.0355Z' fill='%23F0F0F0'/%3E%3C/svg%3E%0A");
    width: 14px;
    height: 14px;
    margin-right: 8px;
    float: left;
}

.goBack:hover:before {
    content: '';
    background: var(--ant-primary-color);
    display: block;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.70709 11.0355C8.09762 11.426 8.73078 11.426 9.1213 11.0355C9.51183 10.645 9.51183 10.0118 9.1213 9.62131L7.00001 7.50002L9.12133 5.3787C9.51186 4.98817 9.51186 4.35501 9.12133 3.96448C8.73081 3.57396 8.09764 3.57396 7.70712 3.96448L4.88484 6.78676C4.88278 6.78879 4.88072 6.79083 4.87866 6.79288C4.48814 7.18341 4.48814 7.81657 4.87866 8.2071L4.87869 8.20712L7.70709 11.0355Z' fill='%23F0F0F0'/%3E%3C/svg%3E%0A");
    mask-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.70709 11.0355C8.09762 11.426 8.73078 11.426 9.1213 11.0355C9.51183 10.645 9.51183 10.0118 9.1213 9.62131L7.00001 7.50002L9.12133 5.3787C9.51186 4.98817 9.51186 4.35501 9.12133 3.96448C8.73081 3.57396 8.09764 3.57396 7.70712 3.96448L4.88484 6.78676C4.88278 6.78879 4.88072 6.79083 4.87866 6.79288C4.48814 7.18341 4.48814 7.81657 4.87866 8.2071L4.87869 8.20712L7.70709 11.0355Z' fill='%23F0F0F0'/%3E%3C/svg%3E%0A");
    width: 14px;
    height: 14px;
    float: left;
    margin-right: 8px;
}

/*  .turingo-list-picture-card:not(.turingo-list-picture-card-no-items){
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 8px;
    row-gap: 8px;
} */

.cover {
    background-size: cover !important;
    background-position: center !important;
}

.form_list .ant-form-item-control-input-content {
    display: flex;
}

.light .turingo-list-item:hover {
    cursor: pointer;
    background: var(--ant-primary-1);
    transition: all 0.3s;
}

.light .turingo-list-item:hover .turingo-list-item-text {
    color: var(--ant-primary-color);
    transition: all 0.3s;
}

.dark .turingo-list-item:hover {
    cursor: pointer;
    background: var(--ant-primary-1);
    transition: all 0.3s;
}

.rotate {
    -webkit-transform: rotate(180deg); /* Safari and Chrome */
    -moz-transform: rotate(180deg); /* Firefox */
    -ms-transform: rotate(180deg); /* IE 9 */
    -o-transform: rotate(180deg); /* Opera */
    transform: rotate(180deg);
    transform-origin: center center;
}

.dark .turingo-list-item:hover .turingo-list-item-text {
    color: var(--ant-primary-color);
    transition: all 0.3s;
}

.turingo-radio-list .ant-space {
    width: 100%;
}

.turingo-radio-list {
    width: 100%;
}

.turingo-radio-list .ant-radio-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100% !important;
}

.turingo-radio-list .ant-radio-wrapper:after {
    display: none;
}

.turingo-radio-card .ant-card-bordered:has(label.ant-radio-wrapper-checked) {
    border-color: var(--ant-primary-color);
}

@media (max-width: 991px) {
    .turingo-radio-card .ant-radio-wrapper {
        display: flex;
        flex-direction: column;
    }
    .turingo-radio-card span.ant-radio {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .turingo-radio-card .ant-radio-wrapper:after {
        display: none;
    }
}

.turingo-radio-card .ant-card-body:has(label.ant-radio-wrapper-checked) {
    position: relative;
}

.turingo-radio-card .ant-card-body:has(label.ant-radio-wrapper-checked)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: var(--ant-primary-color);
    z-index: 0;
}

.turingo-radio-card span.ant-radio + * {
    padding-right: 0;
    padding-left: 0;
}

.turingo-radio-card span.ant-radio {
    margin-right: 8px;
    margin-left: 8px;
}

.turingo-radio-card .ant-space {
    width: 100%;
}

.turingo-radio-card .ant-radio-wrapper > span:not(.ant-radio) {
    width: 100%;
}

.turingo-radio-card {
    width: 100%;
}

.turingo-radio-card .ant-radio-wrapper {
    width: 100% !important;
}

.turingo-card-tabs .ant-tabs-nav {
    margin: 0px 24px 0px 24px !important;
}

.ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 16px !important;
}

.turingo-card-body-padding {
    padding: 16px 24px !important;
}

.turingo-card-header-padding {
    padding: 16px 24px 0px 24px !important;
}
@media (max-width: 991px) {
    .turingo-card-tabs .ant-tabs-nav {
        margin: 0px 16px 0px 16px !important;
    }
    .turingo-card-body-padding {
        padding: 16px !important;
    }
    .turingo-card-header-padding {
        padding: 16px 16px 0px 16px !important;
    }
}

.select_dropdown {
    display: none !important;
}

.outline-hover {
    transition: 0.3s color, 0.3s border-color;
}
.outline-hover:hover {
    color: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
}
.outline-hover:active {
    color: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
}


.outline-hover .ant-typography {
    transition: 0.3s color, 0.3s border-color;
}

.outline-hover:hover .ant-typography {
    color: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
}
.outline-hover:active .ant-typography {
    color: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
}


.outline-parent-hover {
    transition: 0.3s color, 0.3s border-color;
}

.outline-parent-hover:has(.outline-title-hover:nth-of-type(1):hover) {
    /*you may replace this with an explicit className, e.g. :has(.child1)*/
    color: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
}

.outline-parent-hover:has(.outline-title-hover:nth-of-type(1):active) {
    /*you may replace this with an explicit className, e.g. :has(.child1)*/
    color: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
}


.outline-title-hover .ant-typography {
    transition: 0.3s color, 0.3s border-color;
}

.outline-parent-hover:has(.outline-title-hover:nth-of-type(1):hover) .outline-title-hover .ant-typography {
    color: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
}
.outline-parent-hover:has(.outline-title-hover:nth-of-type(1):active) .outline-title-hover .ant-typography {
    color: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
}


/* .outline-title-hover {
    transition: 0.3s color, 0.3s border-color;
}
.outline-title-hover:hover {
    color: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
}
.outline-title-hover:active {
    color: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
} */

