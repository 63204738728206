.editorWrapperBordered{
    border: 1px solid var(--neutral-5) !important;
    border-radius: 4px;
    transition: border 0.3s;
    background-color: var(--background);
    padding: 8px 12px;
}

.editorWrapper{
    border: none !important;
    border-radius: none;
    background-color: var(--background);
    flex-grow: 1;
}
.editor{
    padding: 0px;
    min-height: 22px;
    line-height: 22px;
    word-break: break-all;
    margin-bottom: 0;
}

.editor[placeholder]:empty::before {
    content: attr(placeholder);
    color: var(--placeholder) 
}

.editor[placeholder]:empty:focus::before {
    content: "";
}

.editorWrapperBordered:focus{
    border: 1px solid #8c8c8c !important;
}

.editorWrapperBordered:hover{
    border: 1px solid #8c8c8c !important;
}
.editor:focus-visible {
    outline: none !important;
}
.emoji{
    cursor: pointer;
    :hover{
        color: var(--ant-primary-color);
    }
}