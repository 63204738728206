.previewCard {
    cursor: pointer;
    background-color: #f0f2f5;
    display: flex;
    border: 1px #f0f2f5 solid;
    margin-bottom: 16px;
}

.previewCard:hover {
    background-color: #e6e6e6;
}

.player-wrapper {
    max-height: 680px;
    background-color: black;
}

.react-player {
    background-color: black;
}

.image {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32vw;
    height: 18vw;
    border-radius: 4px;
    color: #fff;
    min-height: 153px;
    min-width: 153px;
    max-width: 200px;
    max-height: 200px;
}

.previewCard:hover .title {
    color: #1890ff;
}
.previewCard:hover .description {
    color: #1890ff;
}
.title {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.description {
    color: rgba(0, 0, 0, 0.45);
}
