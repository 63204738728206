.button {
    display: flex;
    align-items: center;
    transition: 0.3s all;
    justify-content: center;
    text-wrap: nowrap;

}
.active:hover {
    cursor: pointer;
}

.disabled:hover {
    cursor: not-allowed;
}

.standard,
.square,
.social_media,
.square,
.vertical {
    border-radius: 4px;
}
.circle {
    border-radius: 100px;
}

.primary {
    background-color: var(--ant-primary-color);
    border: 1px solid var(--ant-primary-color);
    box-shadow: 0px 2px 0px 0px #0000000b;

    color: white;
}
.primary:hover {
    background-color: var(--ant-primary-color-hover);
    border: 1px solid var(--ant-primary-color-hover);
}
.primary:active {
    background-color: var(--ant-primary-color-active);
    border: 1px solid var(--ant-primary-color-active);
}

.default {
    background-color: var(--background);
    border: 1px solid var(--neutral-5);
    color: var(--text);
    box-shadow: 0px 2px 0px 0px #0000000b;

}
.default:hover {
    border: 1px solid var(--ant-primary-color-hover);
    color: var(--ant-primary-color-hover);
}
.default:active {
    border: 1px solid var(--ant-primary-color-active);
    color: var(--ant-primary-color-active);
}

.dashed {
    border: 1px dashed var(--border-button);
    color: var(--text);
    box-shadow: 0px 2px 0px 0px #0000000b;

}
.dashed:hover {
    border: 1px dashed var(--ant-primary-color-hover);
    color: var(--ant-primary-color-hover);
}
.dashed:active {
    border: 1px dashed var(--ant-primary-color-active);
    color: var(--ant-primary-color-active);
}

.link {
    border: 1px solid transparent;
    color: var(--ant-primary-color);

}
.link:hover {
    color: var(--ant-primary-color-hover);
}
.link:active {
    color: var(--ant-primary-color-active);
}

.reaction {
    border: 1px solid transparent;
    color: var(--text);

}
.reaction:hover {
    background-color: var(--ant-primary-1);
    color: var(--ant-primary-color-hover);
}
.reaction:active {
    color: var(--ant-primary-color-active);
}

.reaction {
    border: 1px solid transparent;
    color: var(--text);

}

.text {
    border: 1px solid transparent;
    color: var(--text);

}
.text:hover {
    background-color: var(--neutral-2);
}
.text:active {
    background-color: var(--neutral-3);
}

.standard.medium {
    padding: 7px 15px;
    max-height: 38px;
    @media (max-width: 991px) {
        padding: 9px 15px;
        max-height: 44px;
    }
}

.standard.small {
    padding: 3px 7px;
    max-height: 30px;
    @media (max-width: 991px) {
        max-height: 34px;
        padding: 4px 7px;
    }
}

.circle.medium {
    padding: 11px;
    max-width: 38px;
    max-height: 38px;
    @media (max-width: 991px) {
        max-width: 44px;
        max-height: 44px;
        padding: 13px;
    }
}

.circle.small {
    padding: 7px;
    max-width: 30px;
    max-height: 30px;
    @media (max-width: 991px) {
        max-width: 34px;
        max-height: 34px;
        padding: 8px;
    }
}

.square.medium {
    padding: 11px;
    max-width: 38px;
    max-height: 38px;
    @media (max-width: 991px) {
        max-width: 44px;
        max-height: 44px;
        padding: 13px;
    }
}

.square.small {
    max-width: 30px;
    max-height: 30px;
    padding: 7px;
    @media (max-width: 991px) {
        max-width: 34px;
        max-height: 34px;
        padding: 8px;
    }
}

.social_media {
    padding: 7px;
    @media (max-width: 991px) {
        padding: 9px;
    }
}

.vertical {
    padding: 7px;
    @media (max-width: 991px) {
        padding: 7px;
    }
}

.primary.disabled {
    background-color: var(--neutral-2) !important;
    color: var(--neutral-6) !important;
    border: 1px solid var(--neutral-5) !important;
    box-shadow: none !important;

}

.default.disabled {
    color: var(--neutral-6) !important;
    border: 1px solid var(--neutral-5) !important;
    box-shadow: none !important;

}

.dashed.disabled {
    color: var(--neutral-6) !important;
    border: 1px dashed var(--neutral-5) !important;
    box-shadow: none !important;
}


.link.disabled, .reaction.disabled {
    background-color: transparent !important;
    color: var(--neutral-6) !important;
    box-shadow: none !important;
}

