.item:hover {
    background-color: var(--ant-primary-1);
    color: var(--ant-primary-6);
    cursor: pointer;
}

.item:active {
    background-color: var(--background);
    color: var(--ant-primary-6);
    cursor: pointer;
}
