.itemCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    padding: 24px;
    width: 100%;
}

.cyan{
    background: linear-gradient(86.35deg, #008BF2 -3.22%, #00CCA5 106.07%);
}

.purple{
    background: linear-gradient(86.09deg, #3131AA -0.24%, #EF009F 124.12%);
}

.image{
    background: linear-gradient(86.09deg, #3131AA -0.24%, #EF009F 124.12%);
}

.turingoLight{
    background-color: #F0F0F0;
}

.turingoDark{
    background-color: #262626;
}

.iconLeft {
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 150px;
  }


  .iconRight {
    position: absolute;
    right: 0;
    top: 0px;
    width: 150px;
  }

.content {
    // position: absolute;
    // left: 0; top: 0; right: 0; bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
}


.courseTitle {
    font-size: 24px;
    text-align: center;
    max-width: 1167px;
    font-weight: 500;
    margin-bottom: 21px;

}

.callToAction {
    border: 0px;
    background-color: transparent;
    color: white;
    max-height: 48px;
    transition: all 0.3s;
    cursor: pointer;
    padding: 10px 35px 10px 35px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border: 1px solid #fff;

}

.callToAction:hover {

    background-color: rgba(white, 0.4);
    
}
