

.autocomplete-panel {
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
border-radius: 4px;
    padding-top: 8px;
    position: absolute;
  z-index: 1002;
  background-color: var(--background);
}

.autocomplete-items::-webkit-scrollbar {
    width: 8px;
}

.autocomplete-items::-webkit-scrollbar-track {
    border-radius: 10px;
    width: 12px;

}

.autocomplete-items::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background:  rgba(0, 0, 0, 0.3);
}

.autocomplete-loading {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: rgba(14, 165, 233, 1);
}

.autocomplete-loading-icon {
  display: block;
  height: 2rem;
  width: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.autocomplete-source {
  margin-top: 0.5rem;
  width: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.autocomplete-source-stalled {
  filter: grayscale(1);
  opacity: 0.8;
}

.autocomplete-items {
  height: 100%;
  max-height: 298px;
  overflow-y: scroll;
  list-style: none;
  margin: 0;
  padding: 0;
}

.autocomplete-item {
    border: none;
  display: flex;
  cursor: pointer;
  color: var(--neutral-13);
  flex-direction: column;
  background: transparent;
  width: 100%;
}

.autocomplete-item:focus {
  background: var(--list-background);
  color: var(--ant-primary-color);
}
.autocomplete-item:hover {
    background: var(--list-background);
    color: var(--ant-primary-color);
  }

.autocomplete-item > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(0.25rem * calc(1 - 0));
  margin-bottom: calc(0.25rem * 0);
}

.autocomplete-item {
    border-radius: 4px;
  padding: 9px 8px;
}

.autocomplete-skeleton {
    border-radius: 4px;
  padding: 9px 8px;
}


