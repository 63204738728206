.dark html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.dark body {
    color: var(--neutral-9);
    background-color: #000;
}
.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6 {
    color: var(--neutral-9);
}
.dark abbr[title],
.dark abbr[data-original-title] {
    border-bottom: 0;
}
.dark a {
    color: var(--ant-primary-color);
    background-color: transparent;
}
.dark a:hover {
    color: var(--ant-primary-color-hover);
}
.dark a:active {
    color: var(--ant-primary-color-active);
}
.dark a[disabled] {
    color: rgba(255, 255, 255, 0.3);
}
.dark img {
    border-style: none;
}
.dark table {
    border-collapse: collapse;
}
.dark caption {
    color: var(--neutral-6);
}
.dark input,
.dark button,
.dark select,
.dark optgroup,
.dark textarea {
    color: inherit;
}
.dark button::-moz-focus-inner,
.dark [type='button']::-moz-focus-inner,
.dark [type='reset']::-moz-focus-inner,
.dark [type='submit']::-moz-focus-inner {
    border-style: none;
}
.dark fieldset {
    border: 0;
}
.dark legend {
    color: inherit;
}
.dark mark {
    background-color: #feffe6;
}
.dark ::selection {
    color: #fff !important;
    background: rgba(255, 255, 255, 0.2) !important;
}
.dark .dark .anticon {
    color: inherit;
}
.dark html {
    --antd-wave-shadow-color: var(--ant-primary-color);
}
.dark [ant-click-animating-without-extra-node='true']::after,
.dark .ant-click-animating-node {
    border-radius: inherit;
    box-shadow: 0 0 0 0 var(--ant-primary-color);
    box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
    animation-fill-mode: forwards;
}

.dark .ant-alert {
    color: var(--neutral-9);
    border-radius: 2px;
}
.dark .ant-alert-success {
    background-color: #263221;
    border: 1px solid #335522;
}
.dark .ant-alert-success .ant-alert-icon {
    color: #52c41a;
}
.dark .ant-alert-info {
    background-color: #131b25;
    border: 1px solid #1890ff;
}
.dark .ant-alert-info .ant-alert-icon {
    color: #1890ff;
}
.dark .ant-alert-warning {
    background-color: #3a3020;
    border: 1px solid #664f21;
}
.dark .ant-alert-warning .ant-alert-icon {
    color: #faad14;
}
.dark .ant-alert-error {
    background-color: #2a1213;
    border: 1px solid #581827;
}

.dark .ant-alert-close-icon {
    background-color: transparent;
    border: none;
}
.dark .ant-alert-close-icon .anticon-close {
    color: var(--neutral-6);
}
.dark .ant-alert-close-icon .anticon-close:hover {
    color: rgba(255, 255, 255, 0.75);
}
.dark .ant-alert-close-text {
    color: var(--neutral-6);
}
.dark .ant-alert-close-text:hover {
    color: rgba(255, 255, 255, 0.75);
}
.dark .ant-alert-with-description .ant-alert-message {
    color: var(--neutral-9);
}
.dark .ant-alert-message {
    color: var(--neutral-9);
}
.dark .ant-alert-banner {
    border: 0;
    border-radius: 0;
}
.dark .ant-anchor {
    color: var(--neutral-9);
}
.dark .ant-anchor-wrapper {
    background-color: transparent;
}
.dark .ant-anchor-ink::before {
    background-color: #303030;
}
.dark .ant-anchor-ink-ball {
    background-color: #262626;
    border: 2px solid var(--ant-primary-color);
    border-radius: 8px;
}
.dark .ant-anchor-link-title {
    color: var(--neutral-9);
}
.dark .ant-anchor-link-active > .ant-anchor-link-title {
    color: var(--ant-primary-color);
}
.dark .ant-select-auto-complete {
    color: var(--neutral-9);
}
.dark .ant-avatar {
    color: var(--neutral-9);
    color: #fff;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
}
.dark .ant-avatar-image {
    background: transparent;
}
.dark .ant-avatar-lg {
    border-radius: 50%;
}
.dark .ant-avatar-sm {
    border-radius: 50%;
}
.dark .ant-avatar-square {
    border-radius: 2px;
}
.dark .ant-avatar-group .ant-avatar {
    border: 1px solid #fff;
}
.dark .ant-back-top {
    color: var(--neutral-9);
}
.dark .ant-back-top-content {
    color: #fff;
    background-color: var(--neutral-6);
    border-radius: 20px;
}
.dark .ant-back-top-content:hover {
    background-color: var(--neutral-9);
}
.dark .ant-badge {
    color: var(--neutral-9);
}
.dark .ant-badge-count {
    color: #fff;
    background: var(--notification-dot);
    border-radius: 10px;
    box-shadow: 0 0 0 1px #262626;
}
.dark .ant-badge-count a,
.dark .ant-badge-count a:hover {
    color: #fff;
}
.dark .ant-badge-count-sm {
    border-radius: 7px;
}
.dark .ant-badge-dot {
    background: var(--notification-dot);
    border-radius: 100%;
    box-shadow: 0 0 0 1px #262626;
}
.dark .ant-badge-status-dot {
    border-radius: 50%;
}
.dark .ant-badge-status-success {
    background-color: #52c41a;
}
.dark .ant-badge-status-processing {
    background-color: #1890ff;
}
.dark .ant-badge-status-processing::after {
    border: 1px solid #1890ff;
    border-radius: 50%;
}
.dark .ant-badge-status-default {
    background-color: var(--neutral-5);
}
.dark .ant-badge-status-error {
    background-color: var(--ant-primary-color-hover);
}
.dark .ant-badge-status-warning {
    background-color: #faad14;
}
.dark .ant-badge-status-pink {
    background: #eb2f96;
}
.dark .ant-badge-status-magenta {
    background: #eb2f96;
}
.dark .ant-badge-status-red {
    background: var(--ant-primary-color);
}
.dark .ant-badge-status-volcano {
    background: #fa541c;
}
.dark .ant-badge-status-orange {
    background: #fa8c16;
}
.dark .ant-badge-status-yellow {
    background: #fadb14;
}
.dark .ant-badge-status-gold {
    background: #faad14;
}
.dark .ant-badge-status-cyan {
    background: #13c2c2;
}
.dark .ant-badge-status-lime {
    background: #a0d911;
}
.dark .ant-badge-status-green {
    background: #52c41a;
}
.dark .ant-badge-status-blue {
    background: #1890ff;
}
.dark .ant-badge-status-geekblue {
    background: #2f54eb;
}
.dark .ant-badge-status-purple {
    background: #722ed1;
}
.dark .ant-badge-status-text {
    color: var(--neutral-9);
}
.dark .ant-badge-zoom-appear,
.dark .ant-badge-zoom-enter {
    animation-fill-mode: both;
}
.dark .ant-badge-zoom-leave {
    animation-fill-mode: both;
}
.dark .ant-ribbon {
    color: var(--neutral-9);
    color: #fff;
    background-color: var(--ant-primary-color);
    border-radius: 2px;
}
.dark .ant-ribbon-text {
    color: #fff;
}
.dark .ant-ribbon-corner {
    color: currentcolor;
    border: 4px solid;
}
.dark .ant-ribbon-corner::after {
    color: rgba(0, 0, 0, 0.25);
    border: inherit;
}
.dark .ant-ribbon-color-pink {
    color: #eb2f96;
    background: #eb2f96;
}
.dark .ant-ribbon-color-magenta {
    color: #eb2f96;
    background: #eb2f96;
}
.dark .ant-ribbon-color-red {
    color: var(--ant-primary-color);
    background: var(--ant-primary-color);
}
.dark .ant-ribbon-color-volcano {
    color: #fa541c;
    background: #fa541c;
}
.dark .ant-ribbon-color-orange {
    color: #fa8c16;
    background: #fa8c16;
}
.dark .ant-ribbon-color-yellow {
    color: #fadb14;
    background: #fadb14;
}
.dark .ant-ribbon-color-gold {
    color: #faad14;
    background: #faad14;
}
.dark .ant-ribbon-color-cyan {
    color: #13c2c2;
    background: #13c2c2;
}
.dark .ant-ribbon-color-lime {
    color: #a0d911;
    background: #a0d911;
}
.dark .ant-ribbon-color-green {
    color: #52c41a;
    background: #52c41a;
}
.dark .ant-ribbon-color-blue {
    color: #1890ff;
    background: #1890ff;
}
.dark .ant-ribbon-color-geekblue {
    color: #2f54eb;
    background: #2f54eb;
}
.dark .ant-ribbon-color-purple {
    color: #722ed1;
    background: #722ed1;
}
.dark .ant-ribbon.ant-ribbon-placement-end {
    border-bottom-right-radius: 0;
}
.dark .ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
    border-color: currentcolor transparent transparent currentcolor;
}
.dark .ant-ribbon.ant-ribbon-placement-start {
    border-bottom-left-radius: 0;
}
.dark .ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
    border-color: currentcolor currentcolor transparent transparent;
}
.dark .ant-ribbon-rtl.ant-ribbon-placement-end {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
}
.dark .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
    border-color: currentcolor currentcolor transparent transparent;
}
.dark .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
    border-color: currentcolor currentcolor transparent transparent;
}
.dark .ant-ribbon-rtl.ant-ribbon-placement-start {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
}
.dark .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
    border-color: currentcolor transparent transparent currentcolor;
}
.dark .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
    border-color: currentcolor transparent transparent currentcolor;
}
.dark .ant-breadcrumb {
    color: var(--neutral-9);
    color: var(--neutral-6);
}
.dark .ant-breadcrumb a {
    color: var(--neutral-7);
}
.dark .ant-breadcrumb a:hover {
    color: var(--neutral-9);
}
.dark .ant-breadcrumb li:last-child {
    color: var(--neutral-9);
}
.dark .ant-breadcrumb li:last-child a {
    color: var(--neutral-9);
}
.dark .ant-breadcrumb-separator {
    color: var(--neutral-6);
}
.dark .ant-btn {
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    border-radius: 2px;
    color: var(--neutral-9);
    border-color: #434343;
    background: var(--neutral-4);
}
.dark .ant-btn:not([disabled]):active {
    box-shadow: none;
}
.dark .ant-btn-lg {
    border-radius: 2px;
}
.dark .ant-btn-sm {
    border-radius: 2px;
}
.dark .ant-btn > a:only-child {
    color: currentcolor;
}
.dark .ant-btn > a:only-child::after {
    background: transparent;
}

.dark .ant-btn:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn[disabled],
.dark .ant-btn[disabled]:hover,
.dark .ant-btn[disabled]:focus,
.dark .ant-btn[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn[disabled] > a:only-child,
.dark .ant-btn[disabled]:hover > a:only-child,
.dark .ant-btn[disabled]:focus > a:only-child,
.dark .ant-btn[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn[disabled] > a:only-child::after,
.dark .ant-btn[disabled]:hover > a:only-child::after,
.dark .ant-btn[disabled]:focus > a:only-child::after,
.dark .ant-btn[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn:hover,
.dark .ant-btn:focus,
.dark .ant-btn:active {
    background: transparent;
}
.dark .ant-btn-primary {
    color: #fff;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.dark .ant-btn:not(.ant-btn-primary):hover,
.dark .ant-btn:not(.ant-btn-primary):focus {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
    background: var(--neutral-4);
}
.dark .ant-btn:hover > a:only-child,
.dark .ant-btn:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn:hover > a:only-child::after,
.dark .ant-btn:focus > a:only-child::after {
    background: var(--neutral-4);
}
.dark .ant-btn:not(.ant-btn-primary):active {
    color: var(--ant-primary-color-active);
    border-color: var(--ant-primary-color-active);
    background: var(--neutral-4);
}

.dark.magenta .ant-btn-primary:hover {
    
    border-color: var(--ant-primary-5);
    background: var(--ant-primary-5);
}

.dark.magenta .ant-btn-primary {
    border-color: var(--ant-primary-6);
    background: var(--ant-primary-6);
}

.dark.magenta .ant-btn-primary:active {
    border-color: var(--ant-primary-7);
    background: var(--ant-primary-7);
}


.dark.red .ant-btn-primary:hover {
    border-color: var(--ant-primary-5);
    background: var(--ant-primary-5);
}

.dark.red .ant-btn-primary {
    border-color: var(--ant-primary-6);
    background: var(--ant-primary-6);
}

.dark.red .ant-btn-primary:active {
    border-color: var(--ant-primary-7);
    background: var(--ant-primary-7);
}

.dark.volcano .ant-btn-primary:hover {
    border-color: var(--ant-primary-5);
    background: var(--ant-primary-5);
}

.dark.volcano .ant-btn-primary {
    border-color: var(--ant-primary-6);
    background: var(--ant-primary-6);
}

.dark.volcano .ant-btn-primary:active {
    border-color: var(--ant-primary-7);
    background: var(--ant-primary-7);
}


.dark.turingo_color .ant-btn-primary:hover {
    border-color: var(--ant-primary-5);
    background: var(--ant-primary-5);
}

.dark.turingo_color .ant-btn-primary {
    border-color: var(--ant-primary-6);
    background: var(--ant-primary-6);
}

.dark.turingo_color .ant-btn-primary:active {
    border-color: var(--ant-primary-7);
    background: var(--ant-primary-7);
}

.dark.blue .ant-btn-primary:hover {
    border-color: var(--ant-primary-4);
    background: var(--ant-primary-4);
}

.dark.blue .ant-btn-primary {
    border-color: var(--ant-primary-5);
    background: var(--ant-primary-5);
}

.dark.blue .ant-btn-primary:active {
    border-color: var(--ant-primary-6);
    background: var(--ant-primary-6);
}

.dark.cyan .ant-btn-primary:hover {
    border-color: var(--ant-primary-4);
    background: var(--ant-primary-4);
}

.dark.cyan .ant-btn-primary {
    border-color: #23807D;
    background: #23807D;
}

.dark.cyan .ant-btn-primary:active {
    border-color: var(--ant-primary-6);
    background: var(--ant-primary-6);
}


.dark.green .ant-btn-primary:hover {
    border-color: var(--ant-primary-4);
    background: var(--ant-primary-4);
}

.dark.green .ant-btn-primary {
    border-color: var(--ant-primary-5);
    background: var(--ant-primary-5);
}

.dark.green .ant-btn-primary:active {
    border-color: var(--ant-primary-6);
    background: var(--ant-primary-6);
}

.dark.orange .ant-btn-primary:hover {
    border-color: var(--ant-primary-4);
    background: var(--ant-primary-4);
}

.dark.orange .ant-btn-primary {
    border-color: var(--ant-primary-5);
    background: var(--ant-primary-5);
}

.dark.orange .ant-btn-primary:active {
    border-color: var(--ant-primary-6);
    background: var(--ant-primary-6);
}


.dark.orange .ant-btn-primary:hover {
    color: #FFF !important;
}


.dark.orange .ant-btn-primary:active {
   color: #FFF !important;
}

.dark .ant-btn-primary > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-primary > a:only-child::after {
    background: transparent;
}

.dark .ant-btn-primary:hover > a:only-child,
.dark .ant-btn-primary:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-primary:hover > a:only-child::after,
.dark .ant-btn-primary:focus > a:only-child::after {
    background: transparent;
}

.dark .ant-btn-primary:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-primary:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-primary[disabled],
.dark .ant-btn-primary[disabled]:hover,
.dark .ant-btn-primary[disabled]:focus,
.dark .ant-btn-primary[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-primary[disabled] > a:only-child,
.dark .ant-btn-primary[disabled]:hover > a:only-child,
.dark .ant-btn-primary[disabled]:focus > a:only-child,
.dark .ant-btn-primary[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-primary[disabled] > a:only-child::after,
.dark .ant-btn-primary[disabled]:hover > a:only-child::after,
.dark .ant-btn-primary[disabled]:focus > a:only-child::after,
.dark .ant-btn-primary[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: var(--ant-primary-color-hover);
    border-left-color: var(--ant-primary-color-hover);
}
.dark .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #434343;
}
.dark .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: var(--ant-primary-color-hover);
}
.dark .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
    border-right-color: #434343;
}
.dark .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.dark .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: var(--ant-primary-color-hover);
}
.dark .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.dark .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-left-color: #434343;
}
.dark .ant-btn-ghost {
    color: var(--neutral-9);
    border-color: #434343;
    background: transparent;
}
.dark .ant-btn-ghost > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-ghost > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-ghost:hover,
.dark .ant-btn-ghost:focus {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
    background: transparent;
}
.dark .ant-btn-ghost:hover > a:only-child,
.dark .ant-btn-ghost:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-ghost:hover > a:only-child::after,
.dark .ant-btn-ghost:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-ghost:active {
    color: var(--ant-primary-color-active);
    border-color: var(--ant-primary-color-active);
    background: transparent;
}
.dark .ant-btn-ghost:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-ghost:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-ghost[disabled],
.dark .ant-btn-ghost[disabled]:hover,
.dark .ant-btn-ghost[disabled]:focus,
.dark .ant-btn-ghost[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-ghost[disabled] > a:only-child,
.dark .ant-btn-ghost[disabled]:hover > a:only-child,
.dark .ant-btn-ghost[disabled]:focus > a:only-child,
.dark .ant-btn-ghost[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-ghost[disabled] > a:only-child::after,
.dark .ant-btn-ghost[disabled]:hover > a:only-child::after,
.dark .ant-btn-ghost[disabled]:focus > a:only-child::after,
.dark .ant-btn-ghost[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dashed {
    color: var(--neutral-9);
    border-color: #434343;
    background: transparent;
    border-style: dashed;
}
.dark .ant-btn-dashed > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dashed > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dashed:hover,
.dark .ant-btn-dashed:focus {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
    background: transparent;
}
.dark .ant-btn-dashed:hover > a:only-child,
.dark .ant-btn-dashed:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dashed:hover > a:only-child::after,
.dark .ant-btn-dashed:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dashed:active {
    color: var(--ant-primary-color-active);
    border-color: var(--ant-primary-color-active);
    background: transparent;
}
.dark .ant-btn-dashed:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dashed:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dashed[disabled],
.dark .ant-btn-dashed[disabled]:hover,
.dark .ant-btn-dashed[disabled]:focus,
.dark .ant-btn-dashed[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-dashed[disabled] > a:only-child,
.dark .ant-btn-dashed[disabled]:hover > a:only-child,
.dark .ant-btn-dashed[disabled]:focus > a:only-child,
.dark .ant-btn-dashed[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dashed[disabled] > a:only-child::after,
.dark .ant-btn-dashed[disabled]:hover > a:only-child::after,
.dark .ant-btn-dashed[disabled]:focus > a:only-child::after,
.dark .ant-btn-dashed[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-danger {
    color: #fff;
    border-color: var(--ant-primary-color-hover);
    background: var(--ant-primary-color-hover);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.dark .ant-btn-danger > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-danger > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-danger:hover,
.dark .ant-btn-danger:focus {
    color: #fff;
    border-color: #ff8597;
    background: #ff8597;
}
.dark .ant-btn-danger:hover > a:only-child,
.dark .ant-btn-danger:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-danger:hover > a:only-child::after,
.dark .ant-btn-danger:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-danger:active {
    color: #fff;
    border-color: #cf405f;
    background: #cf405f;
}
.dark .ant-btn-danger:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-danger:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-danger[disabled],
.dark .ant-btn-danger[disabled]:hover,
.dark .ant-btn-danger[disabled]:focus,
.dark .ant-btn-danger[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-danger[disabled] > a:only-child,
.dark .ant-btn-danger[disabled]:hover > a:only-child,
.dark .ant-btn-danger[disabled]:focus > a:only-child,
.dark .ant-btn-danger[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-danger[disabled] > a:only-child::after,
.dark .ant-btn-danger[disabled]:hover > a:only-child::after,
.dark .ant-btn-danger[disabled]:focus > a:only-child::after,
.dark .ant-btn-danger[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-link {
    color: var(--ant-primary-color);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}
.dark .ant-btn-link > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-link > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-link:hover,
.dark .ant-btn-link:focus {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
    background: transparent;
}
.dark .ant-btn-link:hover > a:only-child,
.dark .ant-btn-link:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-link:hover > a:only-child::after,
.dark .ant-btn-link:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-link:active {
    color: var(--ant-primary-color-active);
    border-color: var(--ant-primary-color-active);
    background: transparent;
}
.dark .ant-btn-link:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-link:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-link[disabled],
.dark .ant-btn-link[disabled]:hover,
.dark .ant-btn-link[disabled]:focus,
.dark .ant-btn-link[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-link[disabled] > a:only-child,
.dark .ant-btn-link[disabled]:hover > a:only-child,
.dark .ant-btn-link[disabled]:focus > a:only-child,
.dark .ant-btn-link[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-link[disabled] > a:only-child::after,
.dark .ant-btn-link[disabled]:hover > a:only-child::after,
.dark .ant-btn-link[disabled]:focus > a:only-child::after,
.dark .ant-btn-link[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-link:hover {
    background: transparent;
}
.dark .ant-btn-link:hover,
.dark .ant-btn-link:focus,
.dark .ant-btn-link:active {
    border-color: transparent;
}
.dark .ant-btn-link[disabled],
.dark .ant-btn-link[disabled]:hover,
.dark .ant-btn-link[disabled]:focus,
.dark .ant-btn-link[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}
.dark .ant-btn-link[disabled] > a:only-child,
.dark .ant-btn-link[disabled]:hover > a:only-child,
.dark .ant-btn-link[disabled]:focus > a:only-child,
.dark .ant-btn-link[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-link[disabled] > a:only-child::after,
.dark .ant-btn-link[disabled]:hover > a:only-child::after,
.dark .ant-btn-link[disabled]:focus > a:only-child::after,
.dark .ant-btn-link[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-text {
    color: var(--neutral-9);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}
.dark .ant-btn-text > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-text > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-text:hover,
.dark .ant-btn-text:focus {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
    background: transparent;
}
.dark .ant-btn-text:hover > a:only-child,
.dark .ant-btn-text:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-text:hover > a:only-child::after,
.dark .ant-btn-text:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-text:active {
    color: var(--ant-primary-color-active);
    border-color: var(--ant-primary-color-active);
    background: transparent;
}
.dark .ant-btn-text:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-text:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-text[disabled],
.dark .ant-btn-text[disabled]:hover,
.dark .ant-btn-text[disabled]:focus,
.dark .ant-btn-text[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-text[disabled] > a:only-child,
.dark .ant-btn-text[disabled]:hover > a:only-child,
.dark .ant-btn-text[disabled]:focus > a:only-child,
.dark .ant-btn-text[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-text[disabled] > a:only-child::after,
.dark .ant-btn-text[disabled]:hover > a:only-child::after,
.dark .ant-btn-text[disabled]:focus > a:only-child::after,
.dark .ant-btn-text[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-text:hover,
.dark .ant-btn-text:focus {
    color: var(--neutral-9);
    background: var(--neutral-2);
    border-color: transparent;
}
.dark .ant-btn-text:active {
    color: var(--neutral-9);
    background: rgba(255, 255, 255, 0.04);
    border-color: transparent;
}
.dark .ant-btn-text[disabled],
.dark .ant-btn-text[disabled]:hover,
.dark .ant-btn-text[disabled]:focus,
.dark .ant-btn-text[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}
.dark .ant-btn-text[disabled] > a:only-child,
.dark .ant-btn-text[disabled]:hover > a:only-child,
.dark .ant-btn-text[disabled]:focus > a:only-child,
.dark .ant-btn-text[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-text[disabled] > a:only-child::after,
.dark .ant-btn-text[disabled]:hover > a:only-child::after,
.dark .ant-btn-text[disabled]:focus > a:only-child::after,
.dark .ant-btn-text[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
    background: transparent;
}
.dark .ant-btn-dangerous > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous:hover,
.dark .ant-btn-dangerous:focus {
    color: #ff8597;
    border-color: #ff8597;
    background: transparent;
}
.dark .ant-btn-dangerous:hover > a:only-child,
.dark .ant-btn-dangerous:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous:hover > a:only-child::after,
.dark .ant-btn-dangerous:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous:active {
    color: #cf405f;
    border-color: #cf405f;
    background: transparent;
}
.dark .ant-btn-dangerous:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous[disabled],
.dark .ant-btn-dangerous[disabled]:hover,
.dark .ant-btn-dangerous[disabled]:focus,
.dark .ant-btn-dangerous[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-dangerous[disabled] > a:only-child,
.dark .ant-btn-dangerous[disabled]:hover > a:only-child,
.dark .ant-btn-dangerous[disabled]:focus > a:only-child,
.dark .ant-btn-dangerous[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous[disabled] > a:only-child::after,
.dark .ant-btn-dangerous[disabled]:hover > a:only-child::after,
.dark .ant-btn-dangerous[disabled]:focus > a:only-child::after,
.dark .ant-btn-dangerous[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-primary {
    color: #fff;
    border-color: var(--ant-primary-color-hover);
    background: var(--ant-primary-color-hover);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.dark .ant-btn-dangerous.ant-btn-primary > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-primary > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-primary:hover,
.dark .ant-btn-dangerous.ant-btn-primary:focus {
    color: #fff;
    border-color: #ff8597;
    background: #ff8597;
}
.dark .ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.dark .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-primary:active {
    color: #fff;
    border-color: #cf405f;
    background: #cf405f;
}
.dark .ant-btn-dangerous.ant-btn-primary:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-primary[disabled],
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-link {
    color: var(--ant-primary-color-hover);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}
.dark .ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-link > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-link:hover,
.dark .ant-btn-dangerous.ant-btn-link:focus {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.dark .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-link:active {
    color: var(--ant-primary-color-active);
    border-color: var(--ant-primary-color-active);
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-link[disabled],
.dark .ant-btn-dangerous.ant-btn-link[disabled]:hover,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:focus,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-link:hover,
.dark .ant-btn-dangerous.ant-btn-link:focus {
    color: #ff8597;
    border-color: transparent;
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.dark .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-link:active {
    color: #cf405f;
    border-color: transparent;
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-link[disabled],
.dark .ant-btn-dangerous.ant-btn-link[disabled]:hover,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:focus,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}
.dark .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-text {
    color: var(--ant-primary-color-hover);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}
.dark .ant-btn-dangerous.ant-btn-text > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-text > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-text:hover,
.dark .ant-btn-dangerous.ant-btn-text:focus {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.dark .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-text:active {
    color: var(--ant-primary-color-active);
    border-color: var(--ant-primary-color-active);
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-text:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-text[disabled],
.dark .ant-btn-dangerous.ant-btn-text[disabled]:hover,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:focus,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-text:hover,
.dark .ant-btn-dangerous.ant-btn-text:focus {
    color: #ff8597;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
}
.dark .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.dark .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-text:active {
    color: #cf405f;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.04);
}
.dark .ant-btn-dangerous.ant-btn-text:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-dangerous.ant-btn-text[disabled],
.dark .ant-btn-dangerous.ant-btn-text[disabled]:hover,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:focus,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}
.dark .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-icon-only {
    border-radius: 2px;
}
.dark .ant-btn-icon-only.ant-btn-lg {
    border-radius: 2px;
}
.dark .ant-btn-icon-only.ant-btn-sm {
    border-radius: 2px;
}
.dark .ant-btn-round {
    border-radius: 32px;
}
.dark .ant-btn-round.ant-btn-lg {
    border-radius: 40px;
}
.dark .ant-btn-round.ant-btn-sm {
    border-radius: 24px;
}
.dark .ant-btn-circle {
    border-radius: 50%;
}

.dark .ant-btn-icon-only.ant-btn-circle:not(.ant-btn-primary),
.dark .ant-btn-icon-only.ant-btn-circle:not(.ant-btn-primary) {
    border-color: var(--neutral-5);
}
.dark .ant-btn-icon-only.ant-btn-circle:hover:not(.ant-btn-primary),
.dark .ant-btn-icon-only.ant-btn-circle:focus:not(.ant-btn-primary) {
    color: var(--ant-primary-color-hover) !important;
    border-color: var(--ant-primary-color-hover) !important;
}

.dark .ant-btn-circle.ant-btn-lg {
    border-radius: 50%;
}
.dark .ant-btn-circle.ant-btn-sm {
    border-radius: 50%;
}
.dark .ant-btn::before {
    background: #262626;
    border-radius: inherit;
}
.dark .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
    border-left-color: transparent;
}
.dark .ant-btn-group .ant-btn {
    border-radius: 0;
}
.dark .ant-btn-group > .ant-btn:only-child {
    border-radius: 2px;
}
.dark .ant-btn-group > span:only-child > .ant-btn {
    border-radius: 2px;
}
.dark .ant-btn-group > .ant-btn:first-child:not(:last-child),
.dark .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.dark .ant-btn-group > .ant-btn:last-child:not(:first-child),
.dark .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.dark .ant-btn-group-sm > .ant-btn:only-child {
    border-radius: 2px;
}
.dark .ant-btn-group-sm > span:only-child > .ant-btn {
    border-radius: 2px;
}
.dark .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.dark .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.dark .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.dark .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.dark .ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
    border-radius: 0;
}
.dark .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.dark .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.dark .ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.dark .ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-radius: 0 2px 2px 0;
}
.dark .ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.dark .ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-radius: 2px 0 0 2px;
}
.dark .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.dark .ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-radius: 0 2px 2px 0;
}
.dark .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.dark .ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-radius: 2px 0 0 2px;
}
.dark .ant-btn.ant-btn-background-ghost {
    color: var(--neutral-9);
    border-color: rgba(255, 255, 255, 0.25);
}
.dark .ant-btn.ant-btn-background-ghost,
.dark .ant-btn.ant-btn-background-ghost:hover,
.dark .ant-btn.ant-btn-background-ghost:active,
.dark .ant-btn.ant-btn-background-ghost:focus {
    background: transparent;
}
.dark .ant-btn.ant-btn-background-ghost:hover,
.dark .ant-btn.ant-btn-background-ghost:focus {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-btn.ant-btn-background-ghost:active {
    color: var(--ant-primary-color-active);
    border-color: var(--ant-primary-color-active);
}
.dark .ant-btn.ant-btn-background-ghost[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background: transparent;
    border-color: #434343;
}
.dark .ant-btn-background-ghost.ant-btn-primary {
    color: var(--ant-primary-color);
    border-color: var(--ant-primary-color);
}
.dark .ant-btn-background-ghost.ant-btn-primary > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-primary:hover,
.dark .ant-btn-background-ghost.ant-btn-primary:focus {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-primary:active {
    color: var(--ant-primary-color-active);
    border-color: var(--ant-primary-color-active);
}
.dark .ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-primary[disabled],
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-danger {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-btn-background-ghost.ant-btn-danger > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-danger:hover,
.dark .ant-btn-background-ghost.ant-btn-danger:focus {
    color: #ff8597;
    border-color: #ff8597;
}
.dark .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-danger:active {
    color: #cf405f;
    border-color: #cf405f;
}
.dark .ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-danger[disabled],
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous:hover,
.dark .ant-btn-background-ghost.ant-btn-dangerous:focus {
    color: #ff8597;
    border-color: #ff8597;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous:active {
    color: #cf405f;
    border-color: #cf405f;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled],
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
    color: var(--ant-primary-color-hover);
    border-color: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
    color: #ff8597;
    border-color: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
    color: #cf405f;
    border-color: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentcolor;
}
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    background: transparent;
}
.dark .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.dark .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-right-color: var(--ant-primary-color-hover);
    border-left-color: #434343;
}
.dark .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.dark .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-right-color: #434343;
    border-left-color: var(--ant-primary-color-hover);
}
.dark .ant-picker-calendar {
    color: var(--neutral-9);
    background: #262626;
}
.dark .ant-picker-calendar .ant-picker-panel {
    background: #262626;
    border: 0;
    border-top: 1px solid #303030;
    border-radius: 0;
}
.dark .ant-picker-calendar-mini {
    border-radius: 2px;
}
.dark .ant-picker-calendar-mini .ant-picker-panel {
    border-radius: 0 0 2px 2px;
}
.dark .ant-picker-calendar-full .ant-picker-panel {
    background: #262626;
    border: 0;
}
.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
    background: rgba(255, 255, 255, 0.08);
}
.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
    background: #382329;
}
.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: var(--ant-primary-color);
}
.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border: 0;
    border-top: 2px solid #303030;
    border-radius: 0;
}
.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    color: var(--neutral-9);
}
.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: var(--ant-primary-color);
}
.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: var(--neutral-9);
}
.dark .ant-card {
    color: var(--neutral-9);
    background: #262626;
    border-radius: 4px;
}
.dark .ant-card-hoverable:hover {
    border: 1px solid var(--border) !important;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.dark .ant-card-bordered {
    border: 1px solid var(--border);
}
.dark .ant-card-head {
    color: var(--neutral-9);
    background: transparent;
    border-bottom: 1px solid var(--border);
    border-radius: 2px 2px 0 0;
}
.dark .ant-card-head .ant-tabs-top {
    color: var(--neutral-9);
}
.dark .ant-card-head .ant-tabs-top-bar {
    border-bottom: 1px solid var(--border);
}
.dark .ant-card-extra {
    color: var(--neutral-9);
}
.dark .ant-card-grid {
    border: 0;
    border-radius: 0;
    box-shadow: 1px 0 0 0 #303030, 0 1px 0 0 #303030, 1px 1px 0 0 #303030, 1px 0 0 0 #303030 inset, 0 1px 0 0 #303030 inset;
}
.dark .ant-card-grid-hoverable:hover {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.dark .ant-card-actions {
    background: #262626;
    border-top: 1px solid var(--border);
}
.dark .ant-card-actions > li {
    color: var(--neutral-6);
}
.dark .ant-card-actions > li > span:hover {
    color: var(--ant-primary-color);
}
.dark .ant-card-actions > li > span a:not(.ant-btn),
.dark .ant-card-actions > li > span > .anticon {
    color: var(--neutral-6);
}
.dark .ant-card-actions > li > span a:not(.ant-btn):hover,
.dark .ant-card-actions > li > span > .anticon:hover {
    color: var(--ant-primary-color);
}
.dark .ant-card-actions > li:not(:last-child) {
    border-right: 1px solid var(--border);
}
.dark .ant-card-rtl .ant-card-actions > li:not(:last-child) {
    border-right: none;
    border-left: 1px solid var(--border);
}
.dark .ant-card-type-inner .ant-card-head {
    background: rgba(255, 255, 255, 0.04);
}
.dark .ant-card-meta-title {
    color: var(--neutral-9);
}
.dark .ant-card-meta-description {
    color: var(--neutral-6);
}
.dark .ant-card-loading-block {
    background: linear-gradient(90deg, rgba(48, 48, 48, 0.2), rgba(48, 48, 48, 0.4), rgba(48, 48, 48, 0.2));
    background-size: 600% 600%;
    border-radius: 2px;
}
.dark .ant-carousel {
    color: var(--neutral-9);
}
.dark .ant-carousel .slick-slider {
    -webkit-tap-highlight-color: transparent;
}
.dark .ant-carousel .slick-prev,
.dark .ant-carousel .slick-next {
    color: transparent;
    background: transparent;
    border: 0;
}
.dark .ant-carousel .slick-prev:hover,
.dark .ant-carousel .slick-next:hover,
.dark .ant-carousel .slick-prev:focus,
.dark .ant-carousel .slick-next:focus {
    color: transparent;
    background: transparent;
}
.dark .ant-carousel .slick-dots li button {
    color: transparent;
    background: #262626;
    border: 0;
    border-radius: 1px;
}
.dark .ant-carousel .slick-dots li.slick-active button {
    background: #262626;
}
.dark .ant-cascader-checkbox {
    color: var(--neutral-9);
}
.dark .ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-inner,
.dark .ant-cascader-checkbox:hover .ant-cascader-checkbox-inner,
.dark .ant-cascader-checkbox-input:focus + .ant-cascader-checkbox-inner {
    border-color: var(--ant-primary-color);
}
.dark .ant-cascader-checkbox-checked::after {
    border: 1px solid var(--ant-primary-color);
    border-radius: 2px;
    animation-fill-mode: backwards;
}
.dark .ant-cascader-checkbox-inner {
    background-color: transparent;
    border: 1px solid #434343;
    border-radius: 2px;
    border-collapse: separate;
}
.dark .ant-cascader-checkbox-inner::after {
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
}
.dark .ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
}
.dark .ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
    background-color: var(--ant-primary-color);
    border-color: var(--ant-primary-color);
}
.dark .ant-cascader-checkbox-disabled.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.3);
}
.dark .ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343 !important;
}
.dark .ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.08);
    border-collapse: separate;
}
.dark .ant-cascader-checkbox-disabled + span {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-cascader-checkbox-wrapper {
    color: var(--neutral-9);
}
.dark .ant-cascader-checkbox-group {
    color: var(--neutral-9);
}
.dark .ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner {
    background-color: transparent;
    border-color: #434343;
}
.dark .ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner::after {
    background-color: var(--ant-primary-color);
    border: 0;
}
.dark .ant-cascader-checkbox-indeterminate.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
    background-color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
}
.dark .ant-cascader-menu {
    border-right: 1px solid #303030;
}
.dark .ant-cascader-menu-item:hover {
    background: rgba(255, 255, 255, 0.08);
}
.dark .ant-cascader-menu-item-disabled {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-cascader-menu-item-disabled:hover {
    background: transparent;
}
.dark .ant-cascader-menu-empty .ant-cascader-menu-item {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.dark .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    background-color: #fff0f0;
}
.dark .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.dark .ant-cascader-menu-item-loading-icon {
    color: var(--neutral-6);
}
.dark .ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.dark .ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-cascader-menu-item-keyword {
    color: var(--ant-primary-color-hover);
}
.dark .ant-checkbox {
    color: var(--neutral-9);
}
.dark .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.dark .ant-checkbox:hover:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.dark .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--ant-primary-color);
}
.dark .ant-checkbox-checked::after {
    border: 1px solid var(--ant-primary-color);
    border-radius: 2px;
    animation-fill-mode: backwards;
}
.dark .ant-checkbox-inner {
    background-color: transparent;
    border: 1px solid #434343;
    border-radius: 2px;
    border-collapse: separate;
}
.dark .ant-checkbox-inner::after {
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
}
.dark .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
}
.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
}

.ant-checkbox-checked::after {
    border-color: var(--ant-primary-color) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-checkbox:hover:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--ant-primary-color) !important;
}
.dark .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.3);
}
.dark .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343 !important;
}
.dark .ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.08);
    border-collapse: separate;
}
.dark .ant-checkbox-disabled + span {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-checkbox-wrapper {
    color: var(--neutral-9);
}
.dark .ant-checkbox-group {
    color: var(--neutral-9);
}
.dark .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: transparent;
    border-color: #434343;
}
.dark .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--ant-primary-color);
    border: 0;
}
.dark .ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
    background-color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
}
.dark .ant-collapse {
    color: var(--neutral-9);
    background-color: rgba(255, 255, 255, 0.04);
    border: 1px solid #434343;
    border-bottom: 0;
    border-radius: 2px;
}
.dark .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #434343;
}
.dark .ant-collapse > .ant-collapse-item:last-child,
.dark .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0 0 2px 2px;
}
.dark .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: var(--neutral-9);
}
.dark .ant-collapse-content {
    color: var(--neutral-9);
    background-color: #262626;
    border-top: 1px solid #434343;
}
.dark .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 2px 2px;
}
.dark .ant-collapse-borderless {
    background-color: rgba(255, 255, 255, 0.04);
    border: 0;
}
.dark .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 1px solid #434343;
}
.dark .ant-collapse-borderless > .ant-collapse-item:last-child,
.dark .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
    border-radius: 0;
}
.dark .ant-collapse-borderless > .ant-collapse-item:last-child {
    border-bottom: 0;
}
.dark .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: transparent;
    border-top: 0;
}
.dark .ant-collapse-ghost {
    background-color: transparent;
    border: 0;
}
.dark .ant-collapse-ghost > .ant-collapse-item {
    border-bottom: 0;
}
.dark .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
    background-color: transparent;
    border-top: 0;
}
.dark .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.dark .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-comment {
    background-color: inherit;
}
.dark .ant-comment-avatar img {
    border-radius: 50%;
}
.dark .ant-comment-content-author-name {
    color: var(--neutral-6);
}
.dark .ant-comment-content-author-name > * {
    color: var(--neutral-6);
}
.dark .ant-comment-content-author-name > *:hover {
    color: var(--neutral-6);
}
.dark .ant-comment-content-author-time {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-comment-actions > li {
    color: var(--neutral-6);
}
.dark .ant-comment-actions > li > span {
    color: var(--neutral-6);
}
.dark .ant-comment-actions > li > span:hover {
    color: rgba(255, 255, 255, 0.65);
}
.dark .ant-picker-status-error.ant-picker,
.dark .ant-picker-status-error.ant-picker:not([disabled]):hover {
    background-color: transparent;
    border-color: var(--ant-error-color);
}

.dark .ant-picker-status-warning.ant-picker,
.dark .ant-picker-status-warning.ant-picker:not([disabled]):hover {
    background-color: transparent;
    border-color: #faad14;
}
.dark .ant-picker-status-warning.ant-picker-focused,

.dark .ant-picker {
    color: var(--neutral-9);
    background: transparent;
    border: 1px solid #434343;
}
.dark .ant-picker:hover,
.dark .ant-picker-focused {
    border-color: var(--ant-error-color);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-picker:hover,
.dark .ant-input-rtl .ant-picker-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
}

.dark .ant-input-rtl .ant-picker-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-picker.ant-picker-disabled {
    background: rgba(255, 255, 255, 0.08);
    border-color: #434343;
}
.dark .ant-picker.ant-picker-disabled .ant-picker-suffix {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-picker.ant-picker-borderless {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}
.dark .ant-picker-input > input {
    color: var(--neutral-9);
    background-color: transparent;
    background-image: none;
    border: 1px solid #434343;
    border-radius: 2px;
    background: transparent;
    border: 0;
}
.dark .ant-picker-input > input::placeholder {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-picker-input > input:hover {
    border-color: var(--ant-primary-color-hover);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-picker-input > input:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-picker-input > input:focus,

.dark .ant-input-rtl .ant-picker-input > input:focus,
.dark .ant-input-rtl .ant-picker-input > input-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-picker-input > input-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-picker-input > input-disabled:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-picker-input > input[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-picker-input > input[disabled]:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-picker-input > input-borderless,
.dark .ant-picker-input > input-borderless:hover,
.dark .ant-picker-input > input-borderless:focus,
.dark .ant-picker-input > input-borderless-focused,
.dark .ant-picker-input > input-borderless-disabled,
.dark .ant-picker-input > input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.dark .ant-picker-input > input:focus {
    box-shadow: none;
}
.dark .ant-picker-input > input[disabled] {
    background: transparent;
}
.dark .ant-picker-input-placeholder > input {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-picker-suffix {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-picker-clear {
    color: rgba(255, 255, 255, 0.3);
    background: #262626;
}
.dark .ant-picker-clear:hover {
    color: var(--neutral-6);
}
.dark .ant-picker-separator {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-picker-focused .ant-picker-separator {
    color: var(--neutral-6);
}
.dark .ant-picker-range .ant-picker-active-bar {
    background: var(--ant-primary-color);
}
.dark .ant-picker-dropdown {
    color: var(--neutral-9);
}

.dark .ant-picker-range-arrow {
    background: linear-gradient(135deg, transparent 40%, #262626 40%);
    box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 2px;
}
.dark .ant-picker-range-arrow::before {
    background: linear-gradient(to left, #262626 50%, #262626 50%) no-repeat -10px -10px;
}
.dark .ant-picker-panel-container {
    background: #262626;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.dark .ant-picker-panel-container .ant-picker-panel {
    background: transparent;
    border-width: 0 0 1px 0;
    border-radius: 0;
}
.dark .ant-picker-panel-container .ant-picker-panel-focused {
    border-color: #303030;
}
.dark .ant-picker-panel {
    background: #262626;
    border: 1px solid #303030;
    border-radius: 2px;
}
.dark .ant-picker-panel-focused {
    border-color: var(--ant-primary-color);
}
.dark .ant-picker-header {
    color: var(--neutral-9);
    border-bottom: 1px solid #303030;
}
.dark .ant-picker-header button {
    color: rgba(255, 255, 255, 0.3);
    background: transparent;
    border: 0;
}
.dark .ant-picker-header > button:hover {
    color: var(--neutral-9);
}
.dark .ant-picker-header-view button {
    color: inherit;
}
.dark .ant-picker-header-view button:hover {
    color: var(--ant-primary-color);
}
.dark .ant-picker-prev-icon::before,
.dark .ant-picker-next-icon::before,
.dark .ant-picker-super-prev-icon::before,
.dark .ant-picker-super-next-icon::before {
    border: 0 solid currentcolor;
    border-width: 1.5px 0 0 1.5px;
}
.dark .ant-picker-super-prev-icon::after,
.dark .ant-picker-super-next-icon::after {
    border: 0 solid currentcolor;
    border-width: 1.5px 0 0 1.5px;
}
.dark .ant-picker-content {
    border-collapse: collapse;
}
.dark .ant-picker-content th {
    color: var(--neutral-9);
}
.dark .ant-picker-cell {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-picker-cell-in-view {
    color: var(--neutral-9);
}
.dark .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 2px;
}
.dark .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.dark
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .dark
    .ant-picker-cell-inner {
    background: rgba(255, 255, 255, 0.08);
}
.dark .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid var(--ant-primary-color);
    border-radius: 2px;
}
.dark .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #fff0f0;
}
.dark .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: var(--ant-primary-color);
}
.dark .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #fff0f0;
}
.dark
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.dark
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.dark
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.dark
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-top: 1px dashed #9f1130;
    border-bottom: 1px dashed #9f1130;
}
.dark .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.dark .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.dark .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: #5a0a1b;
}
.dark .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
    border-radius: 2px 0 0 2px;
}
.dark .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
    border-radius: 0 2px 2px 0;
}
.dark .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.dark .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    background: #5a0a1b;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    border-left: 1px dashed #9f1130;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-right: 1px dashed #9f1130;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.dark .ant-picker-cell-disabled {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-picker-cell-disabled .ant-picker-cell-inner {
    background: transparent;
}
.dark .ant-picker-cell-disabled::before {
    background: #303030;
}
.dark .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: rgba(255, 255, 255, 0.3);
}
.dark .ant-picker-footer {
    border-bottom: 1px solid transparent;
}
.dark .ant-picker-panel .ant-picker-footer {
    border-top: 1px solid #303030;
}
.dark .ant-picker-footer-extra:not(:last-child) {
    border-bottom: 1px solid #303030;
}
.dark .ant-picker-today-btn {
    color: var(--ant-primary-color);
}
.dark .ant-picker-today-btn:hover {
    color: var(--ant-primary-color-hover);
}
.dark .ant-picker-today-btn:active {
    color: var(--ant-primary-color-active);
}
.dark .ant-picker-today-btn.ant-picker-today-btn-disabled {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.dark .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.dark .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
    border-left: 1px dashed #9f1130;
    border-radius: 2px 0 0 2px;
}
.dark .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.dark .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.dark .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
    border-right: 1px dashed #9f1130;
    border-radius: 0 2px 2px 0;
}
.dark .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.dark .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.dark .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
    border-right: 1px dashed #9f1130;
    border-radius: 0 2px 2px 0;
}
.dark .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.dark .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.dark .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
    border-left: 1px dashed #9f1130;
    border-radius: 2px 0 0 2px;
}
.dark .ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.dark .ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.dark .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
    background: transparent !important;
}
.dark .ant-picker-week-panel-row:hover td {
    background: rgba(255, 255, 255, 0.08);
}
.dark .ant-picker-week-panel-row-selected td,
.dark .ant-picker-week-panel-row-selected:hover td {
    background: var(--ant-primary-color);
}
.dark .ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.dark .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
    color: rgba(255, 255, 255, 0.5);
}
.dark .ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.dark .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #fff;
}
.dark .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.dark .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
    color: #fff;
}
.dark .ant-picker-datetime-panel .ant-picker-time-panel {
    border-left: 1px solid #303030;
}
.dark .ant-picker-time-panel-column:not(:first-child) {
    border-left: 1px solid #303030;
}
.dark .ant-picker-time-panel-column-active {
    background: rgba(56, 35, 41, 0.2);
}
.dark .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    color: var(--neutral-9);
    border-radius: 0;
}
.dark .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background: rgba(255, 255, 255, 0.08);
}
.dark .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #382329;
}
.dark .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
    color: rgba(255, 255, 255, 0.3);
    background: transparent;
}
.dark .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 2px;
}
.dark
    .ant-picker-panel-rtl
    .dark
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
    .dark
    .ant-picker-cell-inner {
    border-radius: 0 2px 2px 0;
}
.dark
    .ant-picker-panel-rtl
    .dark
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
    .dark
    .ant-picker-cell-inner {
    border-radius: 2px 0 0 2px;
}
.dark .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.dark
    .ant-picker-panel-rtl
    .dark
    .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.dark .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.dark .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    border-right: 1px dashed #9f1130;
    border-left: none;
    border-radius: 0 2px 2px 0;
}
.dark .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.dark
    .ant-picker-panel-rtl
    .dark
    .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.dark .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.dark .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-right: none;
    border-left: 1px dashed #9f1130;
    border-radius: 2px 0 0 2px;
}
.dark .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.dark .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.dark .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.dark
    .ant-picker-panel-rtl
    .dark
    .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.dark
    .ant-picker-panel-rtl
    .dark
    .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.dark
    .ant-picker-panel-rtl
    tr
    > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.dark
    .ant-picker-panel-rtl
    tr
    > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
    border-right: 1px dashed #9f1130;
    border-left: 1px dashed #9f1130;
    border-radius: 2px;
}
.dark .ant-descriptions-title {
    color: var(--neutral-9);
}
.dark .ant-descriptions-extra {
    color: var(--neutral-9);
}
.dark .ant-descriptions-view {
    border-radius: 2px;
}
.dark .ant-descriptions-row:last-child {
    border-bottom: none;
}
.dark .ant-descriptions-item-label {
    color: var(--neutral-9);
}
.dark .ant-descriptions-item-content {
    color: var(--neutral-9);
}
.dark .ant-descriptions-bordered .ant-descriptions-view {
    border: 1px solid #303030;
}
.dark .ant-descriptions-bordered .ant-descriptions-view > table {
    border-collapse: collapse;
}
.dark .ant-descriptions-bordered .ant-descriptions-item-label,
.dark .ant-descriptions-bordered .ant-descriptions-item-content {
    border-right: 1px solid #303030;
}
.dark .ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.dark .ant-descriptions-bordered .ant-descriptions-item-content:last-child {
    border-right: none;
}
.dark .ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: rgba(255, 255, 255, 0.04);
}
.dark .ant-descriptions-bordered .ant-descriptions-row {
    border-bottom: 1px solid #303030;
}
.dark .ant-descriptions-bordered .ant-descriptions-row:last-child {
    border-bottom: none;
}
.dark .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
.dark .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
    border-right: none;
    border-left: 1px solid #303030;
}
.dark .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.dark .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
    border-left: none;
}
.dark .ant-divider {
    color: var(--neutral-9);
    border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.dark .ant-divider-vertical {
    border-top: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.dark .ant-divider-horizontal.ant-divider-with-text {
    color: var(--neutral-9);
    border-top: 0;
    border-top-color: rgba(255, 255, 255, 0.12);
}
.dark .ant-divider-horizontal.ant-divider-with-text::before,
.dark .ant-divider-horizontal.ant-divider-with-text::after {
    border-top: 1px solid transparent;
    border-top-color: inherit;
    border-bottom: 0;
}
.dark .ant-divider-dashed {
    background: none;
    border-color: rgba(255, 255, 255, 0.12);
    border-style: dashed;
    border-width: 1px 0 0;
}
.dark .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.dark .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
    border-style: dashed none none;
}
.dark .ant-divider-vertical.ant-divider-dashed {
    border-width: 0 0 0 1px;
}
.dark .ant-divider-plain.ant-divider-with-text {
    color: var(--neutral-9);
}
.dark .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.dark .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.dark .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.dark .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.dark .ant-drawer-title {
    color: var(--neutral-9);
}
.dark .ant-drawer-content {
    background-color: #262626;
    background-clip: padding-box;
    border: 0;
}
.dark .ant-drawer-close {
    color: var(--neutral-6);
    background: transparent;
    border: 0;
}
.dark .ant-drawer-close:focus,
.dark .ant-drawer-close:hover {
    color: rgba(255, 255, 255, 0.75);
}
.dark .ant-drawer-header {
    color: var(--neutral-9);
    background: #262626;
    border-bottom: 1px solid #303030;
    border-radius: 2px 2px 0 0;
}

.dark .ant-drawer-header-close-only {
    border: none;
}
.dark .ant-drawer-footer {
    border-top: 1px solid #303030;
}
.dark .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.65);
}
.dark .ant-drawer .ant-picker-clear {
    background: #262626;
}
.dark .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
    color: var(--ant-primary-color-hover);
}
.dark .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
    color: #fff;
    background-color: var(--ant-primary-color-hover);
}
.dark .ant-dropdown {
    color: var(--neutral-9);
}
.dark .ant-dropdown-arrow {
    background: linear-gradient(135deg, transparent 40%, #262626 40%);
    border-radius: 0 0 2px;
}
.dark .ant-dropdown-arrow::before {
    background: linear-gradient(to left, #262626 50%, #262626 50%) no-repeat -10px -10px;
}
.dark .ant-dropdown-placement-top > .ant-dropdown-arrow,
.dark .ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.dark .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
    box-shadow: 3px 3px 7px -3px rgba(0, 0, 0, 0.1);
}
.dark .ant-dropdown-placement-bottom > .ant-dropdown-arrow,
.dark .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.dark .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.1);
}
.dark .ant-dropdown-menu {
    /* Neutral/4 */

    background: #262626;
    /* Neutral/5 */

    border: 1px solid #434343;
    /* drop-shadow/0.15 */

    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}
.dark .ant-dropdown-menu-item-group-title {
    color: var(--neutral-6);
}
.dark .ant-dropdown-menu-submenu-popup {
    background: transparent;
    box-shadow: none;
}
.dark .ant-dropdown-menu-title-content > a {
    color: inherit;
}
.dark .ant-dropdown-menu-title-content > a:hover {
    color: inherit;
}
.dark .ant-dropdown-menu-item,
.dark .ant-dropdown-menu-submenu-title {
    color: var(--neutral-9);
}
.dark .ant-dropdown-menu-item-selected,
.dark .ant-dropdown-menu-submenu-title-selected {
    color: var(--ant-primary-color);
    background-color: #382329;
}
.dark .ant-dropdown-menu-item:hover,
.dark .ant-dropdown-menu-submenu-title:hover,
.dark .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.dark .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.dark .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.dark .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
    background-color: rgba(255, 255, 255, 0.08);
}
.dark .ant-dropdown-menu-item-disabled,
.dark .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-dropdown-menu-item-disabled:hover,
.dark .ant-dropdown-menu-submenu-title-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
    background-color: transparent;
}
.dark .ant-dropdown-menu-item-divider,
.dark .ant-dropdown-menu-submenu-title-divider {
    background-color: #303030;
}
.dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: var(--neutral-6);
}
.dark .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.dark .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(255, 255, 255, 0.3);
    background-color: transparent;
}
.dark .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
    color: var(--ant-primary-color);
}
.dark .ant-dropdown-menu-dark,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu {
    background: #262626;
}
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
    color: rgba(255, 255, 255, 0.65);
}
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
    color: rgba(255, 255, 255, 0.65);
}
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
    color: #fff;
    background: transparent;
}
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
    color: #fff;
    background: var(--ant-primary-color);
}
.dark .ant-empty-normal {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-empty-small {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-empty-img-default-ellipse {
    fill: #f5f5f5;
    fill-opacity: 0.8;
}
.dark .ant-empty-img-default-path-1 {
    fill: #fff2f0;
}
.dark .ant-empty-img-default-path-3 {
    fill: #f5f5f7;
}
.dark .ant-empty-img-default-path-4 {
    fill: #dce0e6;
}
.dark .ant-empty-img-default-path-5 {
    fill: #dce0e6;
}
.dark .ant-empty-img-default-g {
    fill: #fff;
}
.dark .ant-empty-img-simple-ellipse {
    fill: #f5f5f5;
}
.dark .ant-empty-img-simple-g {
    stroke: var(--neutral-5);
}
.dark .ant-empty-img-simple-path {
    fill: #fafafa;
}
.dark .ant-form-item-explain-error {
    color: var(--ant-error-color);
}
.dark .ant-form-item-explain-warning {
    color: #faad14;
}
.dark .ant-form-item-has-warning .ant-form-item-split {
    color: #faad14;
}
.dark .ant-form-item-has-error .ant-form-item-split {
    color: var(--ant-error-color);
}
.dark .ant-form {
    color: var(--neutral-9);
}
.dark .ant-form legend {
    color: var(--neutral-6);
    border: 0;
    border-bottom: 1px solid #434343;
}
.dark .ant-form output {
    color: var(--neutral-9);
}
.dark .ant-form-item {
    color: var(--neutral-9);
}
.dark .ant-form-item-label > label {
    color: var(--neutral-9);
}

.dark .ant-form-item-label > label .ant-form-item-optional {
    color: var(--neutral-6);
}
.dark .ant-form-item-label > label .ant-form-item-tooltip {
    color: var(--neutral-6);
}
.dark .ant-form-item-explain,
.dark .ant-form-item-extra {
    color: var(--neutral-7);
}
.dark .ant-form-item-feedback-icon-success {
    color: #52c41a;
}

.dark .ant-form-item-feedback-icon-warning {
    color: #faad14;
}
.dark .ant-form-item-feedback-icon-validating {
    color: var(--ant-primary-color);
}
.dark .ant-image-img-placeholder {
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30%;
}
.dark .ant-image-mask {
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
}
.dark .ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.45);
}
.dark .ant-image-preview-operations {
    color: var(--neutral-9);
    background: rgba(0, 0, 0, 0.1);
}
.dark .ant-image-preview-operations-operation-disabled {
    color: rgba(255, 255, 255, 0.25);
}
.dark .ant-image-preview-switch-left,
.dark .ant-image-preview-switch-right {
    color: var(--neutral-9);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}
.dark .ant-image-preview-switch-left-disabled,
.dark .ant-image-preview-switch-right-disabled {
    color: rgba(255, 255, 255, 0.25);
}
.dark .ant-input-affix-wrapper {
    color: var(--neutral-9);
    background-color: transparent;
    background-image: none;
    border: 1px solid #434343;
    border-radius: 4px;
}
.dark .ant-input-affix-wrapper::placeholder {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-input-affix-wrapper:hover {
    border-color: var(--ant-primary-color-hover);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-input-affix-wrapper:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-input-affix-wrapper:focus,
.dark .ant-input-affix-wrapper-focused {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(232, 46, 86, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-input-affix-wrapper:focus,
.dark .ant-input-rtl .ant-input-affix-wrapper-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-input-affix-wrapper-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-input-affix-wrapper-disabled:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-input-affix-wrapper[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-input-affix-wrapper[disabled]:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-input-affix-wrapper-borderless,
.dark .ant-input-affix-wrapper-borderless:hover,
.dark .ant-input-affix-wrapper-borderless:focus,
.dark .ant-input-affix-wrapper-borderless-focused,
.dark .ant-input-affix-wrapper-borderless-disabled,
.dark .ant-input-affix-wrapper-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.dark :not(.ant-form-item-has-error) .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled, .ant-input-affix-wrapper-status-error):hover {
    border-color: var(--ant-primary-color-hover);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-input-affix-wrapper-disabled .ant-input[disabled] {
    background: transparent;
}
.dark .ant-input-affix-wrapper > input.ant-input {
    border: none;
}
.dark .ant-input-affix-wrapper > input.ant-input:focus {
    box-shadow: none !important;
}
.dark .ant-input-show-count-suffix {
    color: var(--neutral-6);
}
.dark .anticon.ant-input-clear-icon,
.dark .ant-input-clear-icon {
    color: rgba(255, 255, 255, 0.3);
}
.dark .anticon.ant-input-clear-icon:hover,
.dark .ant-input-clear-icon:hover {
    color: var(--neutral-6);
}
.dark .anticon.ant-input-clear-icon:active,
.dark .ant-input-clear-icon:active {
    color: var(--neutral-9);
}
.dark .ant-input-affix-wrapper-textarea-with-clear-btn {
    border: 0 !important;
}
.dark .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.dark .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: transparent;
    border-color: var(--ant-error-color);
}
.dark .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.dark .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
    border-color: #ff8597;
    box-shadow: 0 0 0 2px rgba(68, 100, 253, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-status-error .ant-input-prefix {
    color: var(--ant-error-color);
}
.dark .ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.dark .ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: transparent;
    border-color: #faad14;
}
.dark .ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.dark .ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
    border-color: #ffc53d;
    box-shadow: 0 0 0 2px rgba(248, 56, 74, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-status-warning .ant-input-prefix {
    color: #faad14;
}
.dark .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.dark .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background: transparent;
    border-color: var(--ant-error-color);
}
.dark .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.dark .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
    border-color: #ff8597;
    box-shadow: 0 0 0 2px rgba(68, 100, 253, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-affix-wrapper-status-error .ant-input-prefix {
    color: var(--ant-error-color);
}
.dark .ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.dark .ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background: transparent;
    border-color: #faad14;
}
.dark .ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.dark .ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
    border-color: #ffc53d;
    box-shadow: 0 0 0 2px rgba(248, 56, 74, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-affix-wrapper-status-warning .ant-input-prefix {
    color: #faad14;
}
.dark .ant-input-group-wrapper-status-error .ant-input-group-addon {
    color: var(--ant-error-color);
    border-color: var(--ant-error-color);
}
.dark .ant-input-group-wrapper-status-warning .ant-input-group-addon {
    color: #faad14;
    border-color: #faad14;
}
.dark .ant-input {
    color: var(--neutral-9);
    background-color: transparent;
    background-image: none;
    border: 1px solid #434343;
    border-radius: 4px;
}
.dark .ant-input::placeholder {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-input:hover {
    border-color: var(--ant-primary-color-hover);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-input:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-input:focus,
.dark .ant-input-focused {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(232, 46, 86, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-input:focus,
.dark .ant-input-rtl .ant-input-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-input-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-input-disabled:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-input[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-input[disabled]:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-input-borderless,
.dark .ant-input-borderless:hover,
.dark .ant-input-borderless:focus,
.dark .ant-input-borderless-focused,
.dark .ant-input-borderless-disabled,
.dark .ant-input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.dark .ant-input-group {
    color: var(--neutral-9);
    border-collapse: separate;
    border-spacing: 0;
}
.dark .ant-input-group-addon:not(:first-child):not(:last-child),
.dark .ant-input-group-wrap:not(:first-child):not(:last-child),
.dark .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
    border-radius: 0;
}
.dark .ant-input-group .ant-input:focus {
    border-right-width: 1px;
}
.dark .ant-input-group .ant-input:hover {
    border-right-width: 1px;
}
.dark .ant-input-group-addon {
    color: var(--neutral-9);
    background-color: rgba(255, 255, 255, 0.04);
    border: 1px solid #434343;
    border-radius: 4px;
}
.dark .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit;
    border: 1px solid transparent;
    box-shadow: none;
}
.dark .ant-input-group-addon .ant-select-open .ant-select-selector,
.dark .ant-input-group-addon .ant-select-focused .ant-select-selector {
    color: var(--ant-primary-color);
}
.dark .ant-input-group-addon .ant-cascader-picker {
    background-color: transparent;
}
.dark .ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
    border: 0;
    box-shadow: none;
}
.dark .ant-input-group > .ant-input:first-child,
.dark .ant-input-group-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.dark .ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.dark .ant-input-group-addon:first-child .ant-select .ant-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.dark .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.dark .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.dark .ant-input-group-addon:first-child {
    border-right: 0;
}
.dark .ant-input-group-addon:last-child {
    border-left: 0;
}
.dark .ant-input-group > .ant-input:last-child,
.dark .ant-input-group-addon:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.dark .ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.dark .ant-input-group-addon:last-child .ant-select .ant-select-selector {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.dark .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.dark .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.dark .ant-input-group .ant-input-affix-wrapper:not(:first-child),
.dark .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.dark .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.dark .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.dark .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
    border-right-width: 1px;
}
.dark .ant-input-group.ant-input-group-compact > * {
    border-radius: 0;
}
.dark .ant-input-group.ant-input-group-compact > *:not(:last-child) {
    border-right-width: 1px;
}
.dark .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.dark .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.dark .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.dark .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
    border-right-width: 1px;
    border-radius: 0;
}
.dark .ant-input-group.ant-input-group-compact > *:first-child,
.dark .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.dark .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.dark .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.dark .ant-input-group.ant-input-group-compact > *:last-child,
.dark .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.dark .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.dark .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
    border-right-width: 1px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.dark .ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
    border-radius: 0;
}
.dark
    .ant-input-group.ant-input-group-compact
    .dark
    .ant-input-group-wrapper:not(:last-child).ant-input-search
    > .ant-input-group
    > .ant-input-group-addon
    > .ant-input-search-button {
    border-radius: 0;
}
.dark .ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
    border-radius: 4px 0 0 4px;
}
.dark .ant-input-group > .ant-input-rtl:first-child,
.dark .ant-input-group-rtl .ant-input-group-addon:first-child {
    border-radius: 0 4px 4px 0;
}
.dark .ant-input-group-rtl .ant-input-group-addon:first-child {
    border-right: 1px solid #434343;
    border-left: 0;
}
.dark .ant-input-group-rtl .ant-input-group-addon:last-child {
    border-right: 0;
    border-left: 1px solid #434343;
}
.dark .ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.dark .ant-input-group-rtl.ant-input-group-addon:last-child {
    border-radius: 4px 0 0 4px;
}
.dark .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-radius: 4px 0 0 4px;
}
.dark .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 0 4px 4px 0;
}
.dark .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
    border-left-width: 1px;
}
.dark .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.dark .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.dark .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.dark .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
    border-radius: 0 2px 2px 0;
}
.dark .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.dark .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.dark .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.dark .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.dark .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
    border-left-width: 1px;
    border-radius: 2px 0 0 2px;
}
.dark .ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
    border-radius: 0 2px 2px 0;
}
.dark .ant-input-password-icon.anticon {
    color: var(--neutral-6);
}
.dark .ant-input-password-icon.anticon:hover {
    color: var(--neutral-9);
}
.dark .ant-input-textarea-show-count::after {
    color: var(--neutral-6);
}
.dark .ant-input-search .ant-input:hover,
.dark .ant-input-search .ant-input:focus {
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.dark .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: var(--ant-primary-color-hover);
}
.dark .ant-input-search .ant-input-affix-wrapper {
    border-radius: 0;
}
.dark .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    border: 0;
}
.dark .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 0 4px 4px 0;
}
.dark .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: var(--neutral-6);
}
.dark .ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
    border: none;
}
.dark .ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.dark .ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-right-color: var(--ant-primary-color-hover);
    border-left-color: #434343;
}
.dark .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.dark .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
    border-right-color: var(--ant-primary-color-hover);
}
.dark .ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
    border-radius: 4px 0 0 4px;
}
.dark .ant-input-number-affix-wrapper {
    color: var(--neutral-9);
    background-color: transparent;
    background-image: none;
    border: 1px solid #434343;
    border-radius: 4px;
}
.dark .ant-input-number-affix-wrapper::placeholder {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-input-number-affix-wrapper:hover {
    border-color: var(--ant-primary-color-hover);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-input-number-affix-wrapper:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-input-number-affix-wrapper:focus,
.dark .ant-input-number-affix-wrapper-focused {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(232, 46, 86, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-input-number-affix-wrapper:focus,
.dark .ant-input-rtl .ant-input-number-affix-wrapper-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-input-number-affix-wrapper-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-input-number-affix-wrapper-disabled:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-input-number-affix-wrapper[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-input-number-affix-wrapper[disabled]:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-input-number-affix-wrapper-borderless,
.dark .ant-input-number-affix-wrapper-borderless:hover,
.dark .ant-input-number-affix-wrapper-borderless:focus,
.dark .ant-input-number-affix-wrapper-borderless-focused,
.dark .ant-input-number-affix-wrapper-borderless-disabled,
.dark .ant-input-number-affix-wrapper-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.dark .ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
    border-color: var(--ant-primary-color-hover);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-input-number-affix-wrapper-disabled .ant-input-number[disabled] {
    background: transparent;
}
.dark .ant-input-number-affix-wrapper > div.ant-input-number {
    border: none;
}
.dark .ant-input-number-affix-wrapper > div.ant-input-number.ant-input-number-focused {
    box-shadow: none !important;
}
.dark .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.dark .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
    background: transparent;
    border-color: var(--ant-error-color);
}
.dark .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.dark .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
    border-color: #ff8597;
    box-shadow: 0 0 0 2px rgba(68, 100, 253, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-number-status-error .ant-input-number-prefix {
    color: var(--ant-error-color);
}
.dark .ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.dark .ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
    background: transparent;
    border-color: #faad14;
}
.dark .ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.dark .ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
    border-color: #ffc53d;
    box-shadow: 0 0 0 2px rgba(248, 56, 74, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-number-status-warning .ant-input-number-prefix {
    color: #faad14;
}
.dark
    .ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.dark
    .ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
    background: transparent;
    border-color: var(--ant-error-color);
}
.dark
    .ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.dark
    .ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
    border-color: #ff8597;
    box-shadow: 0 0 0 2px rgba(68, 100, 253, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
    color: var(--ant-error-color);
}
.dark
    .ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.dark
    .ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
    background: transparent;
    border-color: #faad14;
}
.dark
    .ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.dark
    .ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
    border-color: #ffc53d;
    box-shadow: 0 0 0 2px rgba(248, 56, 74, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
    color: #faad14;
}
.dark .ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
    color: var(--ant-error-color);
    border-color: var(--ant-error-color);
}
.dark .ant-input-number-group-wrapper-status-warning .ant-input-number-group-addon {
    color: #faad14;
    border-color: #faad14;
}
.dark .ant-input-number {
    color: var(--neutral-9);
    background-color: transparent;
    background-image: none;
    border: 1px solid #434343;
}
.dark .ant-input-number::placeholder {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-input-number:hover {
    border-color: var(--ant-primary-color-hover);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-input-number:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-input-number:focus,
.dark .ant-input-number-focused {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(232, 46, 86, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-input-number:focus,
.dark .ant-input-rtl .ant-input-number-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-input-number-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-input-number-disabled:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-input-number[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-input-number[disabled]:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-input-number-borderless,
.dark .ant-input-number-borderless:hover,
.dark .ant-input-number-borderless:focus,
.dark .ant-input-number-borderless-focused,
.dark .ant-input-number-borderless-disabled,
.dark .ant-input-number-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.dark .ant-input-number-group {
    color: var(--neutral-9);
    border-collapse: separate;
    border-spacing: 0;
}
.dark .ant-input-number-group-addon:not(:first-child):not(:last-child),
.dark .ant-input-number-group-wrap:not(:first-child):not(:last-child),
.dark .ant-input-number-group > .ant-input-number:not(:first-child):not(:last-child) {
    border-radius: 0;
}
.dark .ant-input-number-group .ant-input-number:focus {
    border-right-width: 1px;
}
.dark .ant-input-number-group .ant-input-number:hover {
    border-right-width: 1px;
}
.dark .ant-input-number-group-addon {
    color: var(--neutral-9);
    background-color: rgba(255, 255, 255, 0.04);
    border: 1px solid #434343;
    border-radius: 2px;
}
.dark .ant-input-number-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit;
    border: 1px solid transparent;
    box-shadow: none;
}
.dark .ant-input-number-group-addon .ant-select-open .ant-select-selector,
.dark .ant-input-number-group-addon .ant-select-focused .ant-select-selector {
    color: var(--ant-primary-color);
}
.dark .ant-input-number-group-addon .ant-cascader-picker {
    background-color: transparent;
}
.dark .ant-input-number-group-addon .ant-cascader-picker .ant-cascader-input {
    border: 0;
    box-shadow: none;
}
.dark .ant-input-number-group > .ant-input-number:first-child,
.dark .ant-input-number-group-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.dark .ant-input-number-group > .ant-input-number:first-child .ant-select .ant-select-selector,
.dark .ant-input-number-group-addon:first-child .ant-select .ant-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.dark .ant-input-number-group > .ant-input-number-affix-wrapper:not(:first-child) .ant-input-number {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.dark .ant-input-number-group > .ant-input-number-affix-wrapper:not(:last-child) .ant-input-number {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.dark .ant-input-number-group-addon:first-child {
    border-right: 0;
}
.dark .ant-input-number-group-addon:last-child {
    border-left: 0;
}
.dark .ant-input-number-group > .ant-input-number:last-child,
.dark .ant-input-number-group-addon:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.dark .ant-input-number-group > .ant-input-number:last-child .ant-select .ant-select-selector,
.dark .ant-input-number-group-addon:last-child .ant-select .ant-select-selector {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.dark .ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.dark .ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.dark .ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child),
.dark .ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.dark .ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child),
.dark .ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child),
.dark .ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child) {
    border-right-width: 1px;
}
.dark .ant-input-number-group.ant-input-number-group-compact > * {
    border-radius: 0;
}
.dark .ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
    border-right-width: 1px;
}
.dark .ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector,
.dark .ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input,
.dark .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input,
.dark .ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input {
    border-right-width: 1px;
    border-radius: 0;
}
.dark .ant-input-number-group.ant-input-number-group-compact > *:first-child,
.dark .ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.dark .ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.dark .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.dark .ant-input-number-group.ant-input-number-group-compact > *:last-child,
.dark .ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.dark .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.dark .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
    border-right-width: 1px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.dark .ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
    border-radius: 0;
}
.dark
    .ant-input-number-group.ant-input-number-group-compact
    .dark
    .ant-input-group-wrapper:not(:last-child).ant-input-search
    > .ant-input-group
    > .ant-input-group-addon
    > .ant-input-search-button {
    border-radius: 0;
}
.dark .ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
    border-radius: 2px 0 0 2px;
}
.dark .ant-input-number-group > .ant-input-number-rtl:first-child,
.dark .ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
    border-radius: 0 2px 2px 0;
}
.dark .ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
    border-right: 1px solid #434343;
    border-left: 0;
}
.dark .ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
    border-right: 0;
    border-left: 1px solid #434343;
}
.dark .ant-input-number-group-rtl.ant-input-number-group > .ant-input-number:last-child,
.dark .ant-input-number-group-rtl.ant-input-number-group-addon:last-child {
    border-radius: 2px 0 0 2px;
}
.dark .ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
    border-radius: 2px 0 0 2px;
}
.dark .ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
    border-radius: 0 2px 2px 0;
}
.dark .ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
    border-left-width: 1px;
}
.dark .ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.dark .ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.dark .ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.dark .ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
    border-radius: 0 2px 2px 0;
}
.dark .ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.dark .ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.dark .ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:last-child .ant-input,
.dark .ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.dark .ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
    border-left-width: 1px;
    border-radius: 2px 0 0 2px;
}
.dark .ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
    border-radius: 0 2px 2px 0;
}
.dark .ant-input-number-handler {
    color: var(--neutral-6);
    border-left: 1px solid #434343;
}
.dark .ant-input-number-handler:active {
    background: rgba(255, 255, 255, 0.08);
}
.dark .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.dark .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: var(--ant-primary-color-hover);
}
.dark .ant-input-number-handler-up-inner,
.dark .ant-input-number-handler-down-inner {
    color: inherit;
    color: var(--neutral-6);
}
.dark .ant-input-number:hover {
    border-color: var(--ant-primary-color-hover);
    border-right-width: 1px;
}
.dark .ant-input-number-focused {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(232, 46, 86, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-input-number-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-input-number-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-input-number-disabled:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-input-number-input {
    background-color: transparent;
    border: 0;
    border-radius: 2px;
}
.dark .ant-input-number-input::placeholder {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-input-number-handler-wrap {
    background: #262626;
    border-radius: 0 2px 2px 0;
}
.dark .ant-input-number-borderless .ant-input-number-handler-wrap {
    border-left-width: 0;
}
.dark .ant-input-number-handler-up {
    border-top-right-radius: 2px;
}
.dark .ant-input-number-handler-down {
    border-top: 1px solid #434343;
    border-bottom-right-radius: 2px;
}
.dark .ant-input-number-borderless .ant-input-number-handler-down {
    border-top-width: 0;
}
.dark .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.dark .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-input-number-borderless {
    box-shadow: none;
}
.dark .ant-input-number-out-of-range input {
    color: var(--ant-primary-color-hover);
}
.dark .ant-input-number-rtl .ant-input-number-handler {
    border-right: 1px solid #434343;
    border-left: 0;
}
.dark .ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
    border-right-width: 0;
}
.dark .ant-input-number-rtl .ant-input-number-handler-up {
    border-top-right-radius: 0;
}
.dark .ant-input-number-rtl .ant-input-number-handler-down {
    border-bottom-right-radius: 0;
}
.dark .ant-layout {
    background: #000;
}
.dark .ant-layout-header {
    color: var(--neutral-9);
    background: #262626;
    box-shadow: rgb(52 52 52) 0px 1px 0px;
}
.dark .ant-layout-footer {
    color: var(--neutral-9);
    background: #000;
}

.dark .ant-layout-sider:not(.community) {
    background: #262626;
    box-shadow: inset -1px 0px 0px #595959;
}
.dark .ant-layout-sider-trigger {
    color: #fff;
    background: #262626;
}
.dark .ant-layout-sider-zero-width-trigger {
    color: #fff;
    background: #262626;
    border-radius: 0 2px 2px 0;
}
.dark .ant-layout-sider-zero-width-trigger::after {
    background: transparent;
}
.dark .ant-layout-sider-zero-width-trigger:hover::after {
    background: rgba(255, 255, 255, 0.1);
}
.dark .ant-layout-sider-zero-width-trigger-right {
    border-radius: 2px 0 0 2px;
}
.dark .ant-layout-sider-light {
    background: #fff;
}
.dark .ant-layout-sider-light .ant-layout-sider-trigger {
    color: var(--neutral-9);
    background: #fff;
}
.dark .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
    color: var(--neutral-9);
    background: #fff;
}
.dark .ant-list {
    color: var(--neutral-9);
}
.dark .ant-list-empty-text {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-list-item {
    color: var(--neutral-9);
}
.dark .ant-list-item-meta-content {
    color: var(--neutral-9);
}
.dark .ant-list-item-meta-title {
    color: var(--neutral-9);
}
.dark .ant-list-item-meta-title > a {
    color: var(--neutral-9);
}
.dark .ant-list-item-meta-title > a:hover {
    color: var(--ant-primary-color);
}
.dark .ant-list-item-meta-description {
    color: var(--neutral-6);
}
.dark .ant-list-item-action > li {
    color: var(--neutral-6);
}
.dark .ant-list-item-action-split {
    background-color: #303030;
}
.dark .ant-list-header {
    background: transparent;
}
.dark .ant-list-footer {
    background: transparent;
}
.dark .ant-list-empty {
    color: var(--neutral-6);
}
.dark .ant-list-split .ant-list-item {
    border-bottom: 1px solid #303030;
}
.dark .ant-list-split .ant-list-item:last-child {
    border-bottom: none;
}
.dark .ant-list-split .ant-list-header {
    border-bottom: 1px solid #303030;
}
.dark .ant-list-split.ant-list-empty .ant-list-footer {
    border-top: 1px solid #303030;
}
.dark .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
    border-bottom: 1px solid #303030;
}
.dark .ant-list-vertical .ant-list-item-meta-title {
    color: var(--neutral-9);
}
.dark .ant-list-grid .ant-col > .ant-list-item {
    border-bottom: none;
}
.dark .ant-list-bordered {
    border: 1px solid #434343;
    border-radius: 2px;
}
.dark .ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions,
.dark .ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
    background: transparent;
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus,
.dark .ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
    border-color: #ff8597;
    box-shadow: 0 0 0 2px rgba(68, 100, 253, 0.2);
    border-right-width: 1px;
}
.dark .ant-mentions-status-error .ant-input-prefix {
    color: var(--ant-primary-color-hover);
}
.dark .ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions,
.dark .ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
    background: transparent;
    border-color: #faad14;
}
.dark .ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus,
.dark .ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
    border-color: #ffc53d;
    box-shadow: 0 0 0 2px rgba(248, 56, 74, 0.2);
    border-right-width: 1px;
}
.dark .ant-mentions-status-warning .ant-input-prefix {
    color: #faad14;
}
.dark .ant-mentions {
    color: var(--neutral-9);
    background-color: transparent;
    background-image: none;
    border: 1px solid #434343;
    border-radius: 2px;
}
.dark .ant-mentions::placeholder {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-mentions:hover {
    border-color: var(--ant-primary-color-hover);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-mentions:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-mentions:focus,
.dark .ant-mentions-focused {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(232, 46, 86, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-mentions:focus,
.dark .ant-input-rtl .ant-mentions-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-mentions-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-mentions-disabled:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-mentions[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-mentions[disabled]:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-mentions-borderless,
.dark .ant-mentions-borderless:hover,
.dark .ant-mentions-borderless:focus,
.dark .ant-mentions-borderless-focused,
.dark .ant-mentions-borderless-disabled,
.dark .ant-mentions-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.dark .ant-mentions-disabled > textarea {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-mentions-disabled > textarea:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-mentions-focused {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(232, 46, 86, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-mentions-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-mentions > textarea {
    border: none;
}
.dark .ant-mentions > textarea::placeholder {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-mentions-measure {
    color: transparent;
}
.dark .ant-mentions-dropdown {
    color: var(--neutral-9);
    background-color: #262626;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.dark .ant-mentions-dropdown-menu-item {
    color: var(--neutral-9);
}
.dark .ant-mentions-dropdown-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.08);
}
.dark .ant-mentions-dropdown-menu-item:first-child {
    border-radius: 2px 2px 0 0;
}
.dark .ant-mentions-dropdown-menu-item:last-child {
    border-radius: 0 0 2px 2px;
}
.dark .ant-mentions-dropdown-menu-item-disabled {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-mentions-dropdown-menu-item-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
    background-color: #262626;
}
.dark .ant-mentions-dropdown-menu-item-selected {
    color: var(--neutral-9);
    background-color: rgba(255, 255, 255, 0.04);
}
.dark .ant-mentions-dropdown-menu-item-active {
    background-color: rgba(255, 255, 255, 0.08);
}
.dark .ant-menu-item-danger.ant-menu-item {
    color: var(--ant-primary-color-hover);
}
.dark .ant-menu-item-danger.ant-menu-item:hover,
.dark .ant-menu-item-danger.ant-menu-item-active {
    color: var(--ant-primary-color-hover);
}
.dark .ant-menu-item-danger.ant-menu-item:active {
    background: #fff0f0;
}
.dark .ant-menu-item-danger.ant-menu-item-selected {
    color: var(--ant-primary-color-hover);
}
.dark .ant-menu-item-danger.ant-menu-item-selected > a,
.dark .ant-menu-item-danger.ant-menu-item-selected > a:hover {
    color: var(--ant-primary-color-hover);
}
.dark .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    background-color: #fff0f0;
}
.dark .ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
    border-right-color: var(--ant-primary-color-hover);
}
.dark .ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.dark .ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.dark .ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
    color: var(--ant-primary-color-hover);
}
.dark .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    color: #fff;
    background-color: var(--ant-primary-color-hover);
}
.dark .ant-menu {
    color: var(--neutral-9);
    background: #262626;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.dark .ant-menu.ant-menu-root:focus-visible {
    box-shadow: 0 0 0 2px #ffd6da;
}
.dark .ant-menu-item-group-title {
    color: var(--neutral-6);
}
.dark .ant-menu-submenu-selected {
    color: var(--ant-primary-color);
}
.dark .ant-menu-item:active,
.dark .ant-menu-submenu-title:active {
    background: var(--ant-primary-1);
}
.dark .ant-menu-item a {
    color: var(--neutral-9);
}

@media (hover: hover) {
    .dark .ant-menu-item a:hover {
        color: var(--ant-primary-color);
    }
}

.dark .ant-menu-item a::before {
    background-color: transparent;
}
.dark .ant-menu-item > .ant-badge a {
    color: var(--neutral-9);
}
.dark .ant-menu-item > .ant-badge a:hover {
    color: var(--ant-primary-color);
}
.dark .ant-menu-item-divider {
    border-color: #303030;
    border-style: solid;
    border-width: 1px 0 0;
}
.dark .ant-menu-item-divider-dashed {
    border-style: dashed;
}
.dark .ant-menu-horizontal > .ant-menu-item:hover,
.dark .ant-menu-horizontal > .ant-menu-item-active,
.dark .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
    background-color: transparent;
}
.dark .ant-menu-item-selected {
    color: var(--ant-primary-color);
}
.dark .ant-menu-item-selected a,
.dark .ant-menu-item-selected a:hover {
    color: var(--ant-primary-color);
}
.dark .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: unset;
}
.dark .ant-menu-inline,
.dark .ant-menu-vertical,
.dark .ant-menu-vertical-left {
    border-right: 1px solid #303030;
}
.dark .ant-menu-vertical-right {
    border-left: 1px solid #303030;
}
.dark .ant-menu-vertical.ant-menu-sub,
.dark .ant-menu-vertical-left.ant-menu-sub,
.dark .ant-menu-vertical-right.ant-menu-sub {
    border-right: 0;
}
.dark .ant-menu-vertical.ant-menu-sub .ant-menu-item,
.dark .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.dark .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
    border-right: 0;
}
.dark .ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.dark .ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.dark .ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
    border-right: 0;
}
.dark .ant-menu-item:focus-visible,
.dark .ant-menu-submenu-title:focus-visible {
    box-shadow: 0 0 0 2px #ffd6da;
}
.dark .ant-menu-submenu-popup {
    background: transparent;
    border-radius: 2px;
    box-shadow: none;
}
.dark .ant-menu-submenu > .ant-menu {
    background-color: #262626;
    border-radius: 2px;
}
.dark .ant-menu-submenu-popup > .ant-menu {
    background-color: #262626;
}
.dark .ant-menu-submenu-expand-icon,
.dark .ant-menu-submenu-arrow {
    color: var(--neutral-9);
}
.dark .ant-menu-submenu-arrow::before,
.dark .ant-menu-submenu-arrow::after {
    background-color: currentcolor;
    border-radius: 2px;
}
.dark .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.dark .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: var(--ant-primary-color);
}
.dark .ant-menu-vertical .ant-menu-submenu-selected,
.dark .ant-menu-vertical-left .ant-menu-submenu-selected,
.dark .ant-menu-vertical-right .ant-menu-submenu-selected {
    color: var(--ant-primary-color);
}
.dark .ant-menu-horizontal {
    border: 0;
    border-bottom: 1px solid #303030;
    box-shadow: none;
}
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: var(--ant-primary-color);
}
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.dark .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid var(--ant-primary-color);
}
.dark .ant-menu-horizontal > .ant-menu-item::after,
.dark .ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: 2px solid transparent;
}
.dark .ant-menu-horizontal > .ant-menu-item a {
    color: var(--neutral-9);
}
.dark .ant-menu-horizontal > .ant-menu-item a:hover {
    color: var(--ant-primary-color);
}
.dark .ant-menu-horizontal > .ant-menu-item-selected a {
    color: var(--ant-primary-color);
}
.dark .ant-menu-vertical .ant-menu-item::after,
.dark .ant-menu-vertical-left .ant-menu-item::after,
.dark .ant-menu-vertical-right .ant-menu-item::after,
.dark .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid var(--ant-primary-color);
}
.dark .ant-menu.ant-menu-inline-collapsed-tooltip a {
    color: var(--neutral-9);
}
.dark .ant-menu-root.ant-menu-vertical,
.dark .ant-menu-root.ant-menu-vertical-left,
.dark .ant-menu-root.ant-menu-vertical-right,
.dark .ant-menu-root.ant-menu-inline {
    box-shadow: none;
}
.dark .ant-menu-sub.ant-menu-inline {
    background: rgba(255, 255, 255, 0.04);
    border: 0;
    border-radius: 0;
    box-shadow: none;
}
.dark .ant-menu-item-disabled,
.dark .ant-menu-submenu-disabled {
    color: rgba(255, 255, 255, 0.3) !important;
    background: none;
}
.dark .ant-menu-item-disabled::after,
.dark .ant-menu-submenu-disabled::after {
    border-color: transparent !important;
}
.dark .ant-menu-item-disabled a,
.dark .ant-menu-submenu-disabled a {
    color: rgba(255, 255, 255, 0.3) !important;
}
.dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(255, 255, 255, 0.3) !important;
}
.dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    background: rgba(255, 255, 255, 0.3) !important;
}
.dark .ant-menu-inline-collapsed-tooltip a,
.dark .ant-menu-inline-collapsed-tooltip a:hover {
    color: #fff;
}
.dark .ant-menu-light .ant-menu-item:hover,
.dark .ant-menu-light .ant-menu-item-active,
.dark .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.dark .ant-menu-light .ant-menu-submenu-active {
    color: var(--ant-primary-color);
}

@media (hover: hover) {
    .dark.red .ant-menu-light .ant-menu-submenu-title:hover {
        color: #ff3862;
    }
}
.dark .ant-menu.ant-menu-root:focus-visible {
    box-shadow: 0 0 0 2px var(--ant-primary-color-active);
}
.dark .ant-menu-dark .ant-menu-item:focus-visible,
.dark .ant-menu-dark .ant-menu-submenu-title:focus-visible {
    box-shadow: 0 0 0 2px var(--ant-primary-color-active);
}
.dark .ant-menu.ant-menu-dark,
.dark .ant-menu-dark .ant-menu-sub,
.dark .ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #262626;
}
.dark .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.dark .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.dark .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.dark .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.dark .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.dark .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    background: #fff;
}
.dark .ant-menu-dark.ant-menu-submenu-popup {
    background: transparent;
}
.dark .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #000c17;
}
.dark .ant-menu-dark.ant-menu-horizontal {
    border-bottom: 0;
}
.dark .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.dark .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    border-color: #262626;
    border-bottom: 0;
}
.dark .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: var(--ant-primary-color);
}
.dark .ant-menu-dark .ant-menu-item,
.dark .ant-menu-dark .ant-menu-item-group-title,
.dark .ant-menu-dark .ant-menu-item > a,
.dark .ant-menu-dark .ant-menu-item > span > a {
    color: rgba(255, 255, 255, 0.65);
}
.dark .ant-menu-dark.ant-menu-inline,
.dark .ant-menu-dark.ant-menu-vertical,
.dark .ant-menu-dark.ant-menu-vertical-left,
.dark .ant-menu-dark.ant-menu-vertical-right {
    border-right: 0;
}
.dark .ant-menu-dark.ant-menu-inline .ant-menu-item,
.dark .ant-menu-dark.ant-menu-vertical .ant-menu-item,
.dark .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.dark .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
    border-right: 0;
}
.dark .ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.dark .ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.dark .ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.dark .ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
    border-right: 0;
}
.dark .ant-menu-dark .ant-menu-item:hover,
.dark .ant-menu-dark .ant-menu-item-active,
.dark .ant-menu-dark .ant-menu-submenu-active,
.dark .ant-menu-dark .ant-menu-submenu-open,
.dark .ant-menu-dark .ant-menu-submenu-selected,
.dark .ant-menu-dark .ant-menu-submenu-title:hover {
    color: #fff;
    background-color: transparent;
}
.dark .ant-menu-dark .ant-menu-item:hover > a,
.dark .ant-menu-dark .ant-menu-item-active > a,
.dark .ant-menu-dark .ant-menu-submenu-active > a,
.dark .ant-menu-dark .ant-menu-submenu-open > a,
.dark .ant-menu-dark .ant-menu-submenu-selected > a,
.dark .ant-menu-dark .ant-menu-submenu-title:hover > a,
.dark .ant-menu-dark .ant-menu-item:hover > span > a,
.dark .ant-menu-dark .ant-menu-item-active > span > a,
.dark .ant-menu-dark .ant-menu-submenu-active > span > a,
.dark .ant-menu-dark .ant-menu-submenu-open > span > a,
.dark .ant-menu-dark .ant-menu-submenu-selected > span > a,
.dark .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
    color: #fff;
}
.dark .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.dark .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.dark .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.dark .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.dark .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.dark .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.dark .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.dark .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.dark .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.dark .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.dark .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.dark .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
    background: #fff;
}
.dark .ant-menu-dark .ant-menu-item:hover {
    background-color: transparent;
}
.dark .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--ant-primary-color);
}
.dark .ant-menu-dark .ant-menu-item-selected {
    color: #fff;
    border-right: 0;
}
.dark .ant-menu-dark .ant-menu-item-selected::after {
    border-right: 0;
}
.dark .ant-menu-dark .ant-menu-item-selected > a,
.dark .ant-menu-dark .ant-menu-item-selected > span > a,
.dark .ant-menu-dark .ant-menu-item-selected > a:hover,
.dark .ant-menu-dark .ant-menu-item-selected > span > a:hover {
    color: #fff;
}
.dark .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.dark .ant-menu-dark .ant-menu-item-selected .anticon {
    color: #fff;
}
.dark .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.dark .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: #fff;
}
.dark .ant-menu.ant-menu-dark .ant-menu-item-selected,
.dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: var(--ant-primary-color);
}
.dark .ant-menu-dark .ant-menu-item-disabled,
.dark .ant-menu-dark .ant-menu-submenu-disabled,
.dark .ant-menu-dark .ant-menu-item-disabled > a,
.dark .ant-menu-dark .ant-menu-submenu-disabled > a,
.dark .ant-menu-dark .ant-menu-item-disabled > span > a,
.dark .ant-menu-dark .ant-menu-submenu-disabled > span > a {
    color: rgba(255, 255, 255, 0.3) !important;
}
.dark .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.dark .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(255, 255, 255, 0.3) !important;
}
.dark .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.dark .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.dark .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.dark .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    background: rgba(255, 255, 255, 0.3) !important;
}
.dark .ant-menu-rtl.ant-menu-inline,
.dark .ant-menu-rtl.ant-menu-vertical {
    border-right: none;
    border-left: 1px solid #303030;
}
.dark .ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.dark .ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
    border-left: none;
}
.dark .ant-menu-sub.ant-menu-inline {
    border: 0;
}
.dark .ant-message {
    color: var(--neutral-9);
}
.dark .ant-message-notice-content {
    background: #262626;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.dark .ant-message-success .anticon {
    color: #52c41a;
}

.dark .ant-message-warning .anticon {
    color: #faad14;
}
.dark .ant-message-info .anticon,
.dark .ant-message-loading .anticon {
    color: #1890ff;
}
.dark .ant-modal {
    color: var(--neutral-9);
}
.dark .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.45);
}
.dark .ant-modal-title {
    color: var(--neutral-9);
}
.dark .ant-modal-content {
    background-color: #262626;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.dark .ant-modal-close {
    color: var(--neutral-6);
    background: transparent;
    border: 0;
}
.dark .ant-modal-close:focus,
.dark .ant-modal-close:hover {
    color: rgba(255, 255, 255, 0.75);
}
.dark .ant-modal-header {
    color: var(--neutral-9);
    background: #262626;
}
.dark .ant-modal-confirm-body .ant-modal-confirm-title {
    color: var(--neutral-9);
}
.dark .ant-modal-confirm-body .ant-modal-confirm-content {
    color: var(--neutral-9);
}
.dark .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
    color: var(--ant-primary-color-hover);
}
.dark .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.dark .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
    color: #faad14;
}
.dark .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
    color: #1890ff;
}
.dark .ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
    color: #52c41a;
}
.dark .ant-notification {
    color: var(--neutral-9);
}
.dark .ant-notification-notice {
    background: #262626;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.dark .ant-notification-notice-message {
    color: var(--neutral-9);
}
.dark .ant-notification-notice-message-single-line-auto-margin {
    background-color: transparent;
}
.dark .anticon.ant-notification-notice-icon-success {
    color: #52c41a;
}
.dark .anticon.ant-notification-notice-icon-info {
    color: #1890ff;
}
.dark .anticon.ant-notification-notice-icon-warning {
    color: #faad14;
}
.dark .anticon.ant-notification-notice-icon-error {
    color: var(--ant-primary-color-hover);
}
.dark .ant-notification-notice-close {
    color: var(--neutral-6);
}
.dark .ant-notification-notice-close:hover {
    color: rgba(77, 77, 77, 0.67);
}
.dark .ant-notification .notification-fade-effect {
    animation-fill-mode: both;
}
.dark .ant-notification-fade-enter,
.dark .ant-notification-fade-appear {
    animation-fill-mode: both;
}
.dark .ant-notification-fade-leave {
    animation-fill-mode: both;
}
.dark .ant-page-header {
    color: var(--neutral-9);
    background-color: #262626;
}
.dark .ant-page-header-ghost {
    background-color: inherit;
}
.dark .ant-page-header-back-button {
    color: var(--ant-primary-color);
    color: inherit;
}
.dark .ant-page-header-back-button:focus,
.dark .ant-page-header-back-button:hover {
    color: var(--ant-primary-color-hover);
}
.dark .ant-page-header-back-button:active {
    color: var(--ant-primary-color-active);
}
.dark .ant-page-header-heading-title {
    color: var(--neutral-9);
}
.dark .ant-page-header-heading-sub-title {
    color: var(--neutral-6);
}
.dark .ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
    border: none;
}
.dark .ant-pagination {
    color: var(--neutral-9);
}
.dark .ant-pagination-item {
    background-color: transparent;
    border: 1px solid #434343;
    border-radius: 2px;
}
.dark .ant-pagination-item a {
    color: var(--neutral-9);
}
.dark .ant-pagination-item:hover {
    border-color: var(--ant-primary-color);
}
.dark .ant-pagination-item:hover a {
    color: var(--ant-primary-color);
}
.dark .ant-pagination-item:focus-visible {
    border-color: var(--ant-primary-color);
}
.dark .ant-pagination-item:focus-visible a {
    color: var(--ant-primary-color);
}
.dark .ant-pagination-item-active {
    background: transparent;
    border-color: var(--ant-primary-color);
}
.dark .ant-pagination-item-active a {
    color: var(--ant-primary-color);
}
.dark .ant-pagination-item-active:hover {
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-pagination-item-active:focus-visible {
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-pagination-item-active:hover a {
    color: var(--ant-primary-color-hover);
}
.dark .ant-pagination-item-active:focus-visible a {
    color: var(--ant-primary-color-hover);
}
.dark .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.dark .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--ant-primary-color);
}
.dark .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.dark .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-pagination-prev,
.dark .ant-pagination-next,
.dark .ant-pagination-jump-prev,
.dark .ant-pagination-jump-next {
    color: var(--neutral-9);
    border-radius: 2px;
}
.dark .ant-pagination-prev button,
.dark .ant-pagination-next button {
    color: var(--neutral-9);
}
.dark .ant-pagination-prev:hover button,
.dark .ant-pagination-next:hover button {
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-pagination-prev .ant-pagination-item-link,
.dark .ant-pagination-next .ant-pagination-item-link {
    background-color: transparent;
    border: 1px solid #434343;
    border-radius: 2px;
}
.dark .ant-pagination-prev:focus-visible .ant-pagination-item-link,
.dark .ant-pagination-next:focus-visible .ant-pagination-item-link {
    color: var(--ant-primary-color);
    border-color: var(--ant-primary-color);
}
.dark .ant-pagination-prev:hover .ant-pagination-item-link,
.dark .ant-pagination-next:hover .ant-pagination-item-link {
    color: var(--ant-primary-color);
    border-color: var(--ant-primary-color);
}
.dark .ant-pagination-disabled .ant-pagination-item-link,
.dark .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
}
.dark .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    color: rgba(255, 255, 255, 0.3);
    border-color: #434343;
}
.dark .ant-pagination-options-quick-jumper input {
    color: var(--neutral-9);
    background-color: transparent;
    background-image: none;
    border: 1px solid #434343;
    border-radius: 2px;
}
.dark .ant-pagination-options-quick-jumper input::placeholder {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-pagination-options-quick-jumper input:hover {
    border-color: var(--ant-primary-color-hover);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-pagination-options-quick-jumper input:focus,
.dark .ant-pagination-options-quick-jumper input-focused {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(232, 46, 86, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-pagination-options-quick-jumper input:focus,
.dark .ant-input-rtl .ant-pagination-options-quick-jumper input-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-pagination-options-quick-jumper input-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-pagination-options-quick-jumper input-disabled:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-pagination-options-quick-jumper input[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-pagination-options-quick-jumper input[disabled]:hover {
    border-color: #434343;
    border-right-width: 1px;
}
.dark .ant-pagination-options-quick-jumper input-borderless,
.dark .ant-pagination-options-quick-jumper input-borderless:hover,
.dark .ant-pagination-options-quick-jumper input-borderless:focus,
.dark .ant-pagination-options-quick-jumper input-borderless-focused,
.dark .ant-pagination-options-quick-jumper input-borderless-disabled,
.dark .ant-pagination-options-quick-jumper input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.dark .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.dark .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
    background-color: transparent;
    border: 0;
}
.dark .ant-pagination-simple .ant-pagination-simple-pager input {
    background-color: transparent;
    border: 1px solid #434343;
    border-radius: 2px;
}
.dark .ant-pagination-simple .ant-pagination-simple-pager input:hover {
    border-color: var(--ant-primary-color);
}
.dark .ant-pagination-simple .ant-pagination-simple-pager input:focus {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(232, 46, 86, 0.2);
}
.dark .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #434343;
}
.dark .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
    background: transparent;
    border-color: transparent;
}
.dark .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.dark .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
    background: transparent;
    border-color: transparent;
}
.dark .ant-pagination.ant-pagination-disabled .ant-pagination-item {
    background: rgba(255, 255, 255, 0.08);
    border-color: #434343;
}
.dark .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
    color: rgba(255, 255, 255, 0.3);
    background: transparent;
    border: none;
}
.dark .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
    background: rgba(255, 255, 255, 0.25);
}
.dark .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
    color: #000;
}
.dark .ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #434343;
}
.dark .ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    background: transparent;
}
.dark .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-popover {
    color: var(--neutral-9);
}
.dark .ant-popover::after {
    background: rgba(255, 255, 255, 0.01);
}
.dark .ant-popover-inner {
    background-color: #262626;
    background-clip: padding-box;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.45);
}
.dark .ant-popover-title {
    color: var(--neutral-9);
    border-bottom: 1px solid #303030;
}
.dark .ant-popover-inner-content {
    color: var(--neutral-9);
}
.dark .ant-popover-message {
    color: var(--neutral-9);
}
.dark .ant-popover-message > .anticon {
    color: #faad14;
}
.dark .ant-popover-arrow {
    background: transparent;
}
.dark .ant-popover-arrow-content {
    background-color: #262626;
    border-radius: 0 0 2px;
}
.dark .ant-popover-arrow-content::before {
    background: linear-gradient(to left, #262626 50%, #262626 50%) no-repeat -10px -10px;
}
.dark .ant-popover-placement-top .ant-popover-arrow-content,
.dark .ant-popover-placement-topLeft .ant-popover-arrow-content,
.dark .ant-popover-placement-topRight .ant-popover-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.dark .ant-popover-placement-right .ant-popover-arrow-content,
.dark .ant-popover-placement-rightTop .ant-popover-arrow-content,
.dark .ant-popover-placement-rightBottom .ant-popover-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.dark .ant-popover-placement-bottom .ant-popover-arrow-content,
.dark .ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.dark .ant-popover-placement-bottomRight .ant-popover-arrow-content {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
}
.dark .ant-popover-placement-left .ant-popover-arrow-content,
.dark .ant-popover-placement-leftTop .ant-popover-arrow-content,
.dark .ant-popover-placement-leftBottom .ant-popover-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.dark .ant-popover-pink .ant-popover-inner {
    background-color: #eb2f96;
}
.dark .ant-popover-pink .ant-popover-arrow-content {
    background-color: #eb2f96;
}
.dark .ant-popover-magenta .ant-popover-inner {
    background-color: #eb2f96;
}
.dark .ant-popover-magenta .ant-popover-arrow-content {
    background-color: #eb2f96;
}
.dark .ant-popover-red .ant-popover-inner {
    background-color: var(--ant-primary-color);
}
.dark .ant-popover-red .ant-popover-arrow-content {
    background-color: var(--ant-primary-color);
}
.dark .ant-popover-volcano .ant-popover-inner {
    background-color: #fa541c;
}
.dark .ant-popover-volcano .ant-popover-arrow-content {
    background-color: #fa541c;
}
.dark .ant-popover-orange .ant-popover-inner {
    background-color: #fa8c16;
}
.dark .ant-popover-orange .ant-popover-arrow-content {
    background-color: #fa8c16;
}
.dark .ant-popover-yellow .ant-popover-inner {
    background-color: #fadb14;
}
.dark .ant-popover-yellow .ant-popover-arrow-content {
    background-color: #fadb14;
}
.dark .ant-popover-gold .ant-popover-inner {
    background-color: #faad14;
}
.dark .ant-popover-gold .ant-popover-arrow-content {
    background-color: #faad14;
}
.dark .ant-popover-cyan .ant-popover-inner {
    background-color: #13c2c2;
}
.dark .ant-popover-cyan .ant-popover-arrow-content {
    background-color: #13c2c2;
}
.dark .ant-popover-lime .ant-popover-inner {
    background-color: #a0d911;
}
.dark .ant-popover-lime .ant-popover-arrow-content {
    background-color: #a0d911;
}
.dark .ant-popover-green .ant-popover-inner {
    background-color: #52c41a;
}
.dark .ant-popover-green .ant-popover-arrow-content {
    background-color: #52c41a;
}
.dark .ant-popover-blue .ant-popover-inner {
    background-color: #1890ff;
}
.dark .ant-popover-blue .ant-popover-arrow-content {
    background-color: #1890ff;
}
.dark .ant-popover-geekblue .ant-popover-inner {
    background-color: #2f54eb;
}
.dark .ant-popover-geekblue .ant-popover-arrow-content {
    background-color: #2f54eb;
}
.dark .ant-popover-purple .ant-popover-inner {
    background-color: #722ed1;
}
.dark .ant-popover-purple .ant-popover-arrow-content {
    background-color: #722ed1;
}
.dark .ant-progress {
    color: var(--neutral-9);
}
.dark .ant-progress-steps-item {
    background: rgba(255, 255, 255, 0.08);
}
.dark .ant-progress-steps-item-active {
    background: #1890ff;
}
.dark .ant-progress-inner {
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 100px;
}
.dark .ant-progress-circle-trail {
    stroke: rgba(255, 255, 255, 0.08);
}
.dark .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #1890ff;
}
.dark .ant-progress-status-normal .ant-progress-bg {
    background-color: #d87a16 !important;
    border-radius: 100px;
}
.dark .ant-progress-success-bg {
    background-color: #52c41a;
}
.dark .ant-progress-text {
    color: var(--neutral-9);
}
.dark .ant-progress-status-active .ant-progress-bg::before {
    background: #262626;
    border-radius: 10px;
}
.dark .ant-progress-status-exception .ant-progress-bg {
    background-color: var(--ant-primary-color-hover);
}
.dark .ant-progress-status-exception .ant-progress-text {
    color: var(--ant-primary-color-hover);
}
.dark .ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: var(--ant-primary-color-hover);
}
.dark .ant-progress-status-success .ant-progress-bg {
    background-color: #52c41a;
}
.dark .ant-progress-status-success .ant-progress-text {
    color: #52c41a;
}
.dark .ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #52c41a;
}
.dark .ant-progress-circle .ant-progress-inner {
    background-color: transparent;
}
.dark .ant-progress-circle .ant-progress-text {
    color: var(--neutral-9);
}
.dark .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
    color: var(--ant-primary-color-hover);
}
.dark .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #52c41a;
}
.dark .ant-radio-group {
    color: var(--neutral-9);
}
.dark .ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
    border-left: none;
}
.dark .ant-radio-wrapper {
    color: var(--neutral-9);
}
.dark .ant-radio {
    color: var(--neutral-9);
}
.dark .ant-radio-wrapper:hover .ant-radio,
.dark .ant-radio:hover .ant-radio-inner,
.dark .ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--ant-primary-color);
}
.dark .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
}
.dark .ant-radio-checked::after {
    border: 1px solid var(--ant-primary-color);
    border-radius: 50%;
    animation-fill-mode: both;
}
.dark .ant-radio-inner {
    background-color: transparent;
    border-color: #434343;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
}
.dark .ant-radio-inner::after {
    background-color: var(--ant-primary-color);
    border-top: 0;
    border-left: 0;
    border-radius: 16px;
}
.dark .ant-radio-checked .ant-radio-inner {
    border-color: var(--ant-primary-color);
}
.dark .ant-radio-disabled .ant-radio-inner {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343 !important;
}
.dark .ant-radio-disabled .ant-radio-inner::after {
    background-color: rgba(255, 255, 255, 0.2);
}
.dark .ant-radio-disabled + span {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-radio-button-wrapper {
    color: var(--neutral-9);
    background: transparent;
    border: 1px solid #434343;
    border-top-width: 1.02px;
    border-left-width: 0;
}
.dark .ant-radio-button-wrapper a {
    color: var(--neutral-9);
}
.dark .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #434343;
}
.dark .ant-radio-button-wrapper:first-child {
    border-left: 1px solid #434343;
    border-radius: 4px 0 0 4px;
}
.dark .ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0;
}
.dark .ant-radio-button-wrapper:first-child:last-child {
    border-radius: 2px;
}
.dark .ant-radio-button-wrapper:hover {
    color: var(--ant-primary-color);
    border-color: var(--ant-primary-color);
}

.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: var(--ant-primary-color);
    background: transparent;
    border-color: var(--ant-primary-color);
}
.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: var(--ant-primary-color);
}
.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: var(--ant-primary-color);
}
.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: var(--ant-primary-color-hover);
}
.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: var(--ant-primary-color-active);
    border-color: var(--ant-primary-color-active);
}
.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
    background-color: var(--ant-primary-color-active);
}

.dark .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: var(--ant-primary-color);
    border-color: var(--ant-primary-color);
}
.dark .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #fff;
    background: var(--ant-primary-color-active);
    border-color: var(--ant-primary-color-active);
}

.dark .ant-radio-button-wrapper-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
}
.dark .ant-radio-button-wrapper-disabled:first-child,
.dark .ant-radio-button-wrapper-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343;
}
.dark .ant-radio-button-wrapper-disabled:first-child {
    border-left-color: #434343;
}
.dark .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.2);
    border-color: #434343;
    box-shadow: none;
}
.dark .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
    border-right-width: 0;
    border-left-width: 1px;
}
.dark .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
    border-right: 1px solid #434343;
    border-radius: 0 2px 2px 0;
}
.dark .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: var(--ant-primary-color-hover);
}
.dark .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
    border-radius: 2px 0 0 2px;
}
.dark .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
    border-right-color: #434343;
}
.dark .ant-rate {
    color: var(--neutral-9);
    color: #fadb14;
}
.dark .ant-rate-star {
    color: inherit;
}
.dark .ant-rate-star-first,
.dark .ant-rate-star-second {
    color: rgba(255, 255, 255, 0.12);
}
.dark .ant-rate-star-half .ant-rate-star-first,
.dark .ant-rate-star-full .ant-rate-star-second {
    color: inherit;
}
.dark .ant-result-success .ant-result-icon > .anticon {
    color: #52c41a;
}
.dark .ant-result-error .ant-result-icon > .anticon {
    color: var(--ant-primary-color-hover);
}
.dark .ant-result-info .ant-result-icon > .anticon {
    color: #1890ff;
}
.dark .ant-result-warning .ant-result-icon > .anticon {
    color: #faad14;
}
.dark .ant-result-title {
    color: var(--neutral-9);
}
.dark .ant-result-subtitle {
    color: var(--neutral-6);
}
.dark .ant-result-content {
    background-color: rgba(255, 255, 255, 0.04);
}
.segmented-disabled-item,
.segmented-disabled-item:hover,
.segmented-disabled-item:focus {
    color: rgba(255, 255, 255, 0.3);
}
.segmented-item-selected {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}
.dark .ant-segmented {
    color: var(--neutral-9);
    color: rgba(0, 0, 0, 0.65);
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 2px;
}
.dark .ant-segmented:not(.ant-segmented-disabled):hover,
.dark .ant-segmented:not(.ant-segmented-disabled):focus {
    background-color: rgba(0, 0, 0, 0.06);
}
.dark .ant-segmented-item-selected {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
    color: #262626;
}
.dark .ant-segmented-item:hover,
.dark .ant-segmented-item:focus {
    color: #262626;
}
.dark .ant-segmented-item-disabled,
.dark .ant-segmented-item-disabled:hover,
.dark .ant-segmented-item-disabled:focus {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-segmented-thumb {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}
.dark .ant-select-single.ant-select-open .ant-select-selection-item {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-select-disabled.ant-select-multiple .ant-select-selector {
    background: #262626;
}
.dark .ant-select-multiple .ant-select-selection-item {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid #303030;
    border-radius: 4px;
}

.ant-select-multiple .ant-select-selection-item {
    border-radius: 4px;
}
.dark .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    color: #595959;
    border-color: #262626;
}
.dark .ant-select-multiple .ant-select-selection-item-remove {
    color: inherit;
    color: var(--neutral-6);
}
.dark .ant-select-multiple .ant-select-selection-item-remove:hover {
    color: rgba(255, 255, 255, 0.75);
}
.dark .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
}
.dark .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.dark .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
    border-color: #ff8597;
    box-shadow: 0 0 0 2px rgba(68, 100, 253, 0.2);
    border-right-width: 1px;
}
.dark .ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border-color: #faad14 !important;
}
.dark .ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.dark .ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
    border-color: #ffc53d;
    box-shadow: 0 0 0 2px rgba(248, 56, 74, 0.2);
    border-right-width: 1px;
}
.dark .ant-select {
    color: var(--neutral-9);
}
.dark .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: 1px solid #434343;
    border-radius: 4px;
}
.dark .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(232, 46, 86, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
}
.dark .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #262626;
}
.dark .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    background: transparent;
    border: none;
}
.dark .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--ant-primary-color-hover);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-select-selection-placeholder {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-select-arrow {
    color: inherit;
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-select-clear {
    color: rgba(255, 255, 255, 0.3);
    background: #262626;
}
.dark .ant-select-clear:hover {
    color: var(--neutral-6);
}
.dark .ant-select-dropdown {
    color: var(--neutral-9);
    background-color: #262626;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.dark .ant-select-dropdown-empty {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-select-item-empty {
    color: var(--neutral-9);
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-select-item {
    color: var(--neutral-9);
}
.dark .ant-select-item-group {
    color: var(--neutral-6);
}
.dark .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(255, 255, 255, 0.08);
}
.dark .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: var(--neutral-9);
    background-color: var(--ant-primary-color);
}
.dark .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: var(--ant-primary-color);
}
.dark .ant-select-item-option-disabled {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-select-item-option-disabled.ant-select-item-option-selected {
    background-color: #262626;
}
.dark .ant-select-borderless .ant-select-selector {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}
.dark .ant-skeleton-header .ant-skeleton-avatar {
    background: #303030;
}
.dark .ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
    border-radius: 50%;
}
.dark .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
    border-radius: 50%;
}
.dark .ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
    border-radius: 50%;
}
.dark .ant-skeleton-content .ant-skeleton-title {
    background: #303030;
    border-radius: 4px;
}
.dark .ant-skeleton-content .ant-skeleton-paragraph > li {
    background: #303030;
    border-radius: 4px;
}
.dark .ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.dark .ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
    border-radius: 100px;
}
.dark .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.dark .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
    background: linear-gradient(90deg, #303030 25%, rgba(255, 255, 255, 0.16) 37%, #303030 63%);
    background-size: 400% 100%;
}
.dark .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
    background: linear-gradient(90deg, #303030 25%, rgba(255, 255, 255, 0.16) 37%, #303030 63%);
    background-size: 400% 100%;
}
.dark .ant-skeleton.ant-skeleton-active .ant-skeleton-button {
    background: linear-gradient(90deg, #303030 25%, rgba(255, 255, 255, 0.16) 37%, #303030 63%);
    background-size: 400% 100%;
}
.dark .ant-skeleton.ant-skeleton-active .ant-skeleton-input {
    background: linear-gradient(90deg, #303030 25%, rgba(255, 255, 255, 0.16) 37%, #303030 63%);
    background-size: 400% 100%;
}
.dark .ant-skeleton.ant-skeleton-active .ant-skeleton-image {
    background: linear-gradient(90deg, #303030 25%, rgba(255, 255, 255, 0.16) 37%, #303030 63%);
    background-size: 400% 100%;
}
.dark .ant-skeleton-element .ant-skeleton-button {
    background: #303030;
    border-radius: 2px;
}
.dark .ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
    border-radius: 50%;
}
.dark .ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
    border-radius: 32px;
}
.dark .ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
    border-radius: 50%;
}
.dark .ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
    border-radius: 40px;
}
.dark .ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
    border-radius: 50%;
}
.dark .ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
    border-radius: 24px;
}
.dark .ant-skeleton-element .ant-skeleton-avatar {
    background: #303030;
}
.dark .ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
    border-radius: 50%;
}
.dark .ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
    border-radius: 50%;
}
.dark .ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
    border-radius: 50%;
}
.dark .ant-skeleton-element .ant-skeleton-input {
    background: #303030;
}
.dark .ant-skeleton-element .ant-skeleton-image {
    background: #303030;
}
.dark .ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
    border-radius: 50%;
}
.dark .ant-skeleton-element .ant-skeleton-image-path {
    fill: #bfbfbf;
}
.dark .ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
    border-radius: 50%;
}
.dark .ant-slider {
    color: var(--neutral-9);
}
.dark .ant-slider-rail {
    background-color: #262626;
    border-radius: 2px;
}
.dark .ant-slider-track {
    background-color: #ffadb7;
    border-radius: 2px;
}
.dark .ant-slider-handle {
    background-color: #262626;
    border: solid 2px #ffadb7;
    border-radius: 50%;
    box-shadow: 0;
}
.dark .ant-slider-handle:focus {
    border-color: #ed5878;
    box-shadow: 0 0 0 5px rgba(232, 46, 86, 0.12);
}
.dark .ant-slider-handle.ant-tooltip-open {
    border-color: var(--ant-primary-color);
}
.dark .ant-slider:hover .ant-slider-rail {
    background-color: #434343;
}
.dark .ant-slider:hover .ant-slider-track {
    background-color: #ff8597;
}
.dark .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #ff8597;
}
.dark .ant-slider-mark-text {
    color: var(--neutral-6);
}
.dark .ant-slider-mark-text-active {
    color: var(--neutral-9);
}
.dark .ant-slider-step {
    background: transparent;
}
.dark .ant-slider-dot {
    background-color: #262626;
    border: 2px solid #303030;
    border-radius: 50%;
}
.dark .ant-slider-dot-active {
    border-color: #7d2a3c;
}
.dark .ant-slider-disabled .ant-slider-rail {
    background-color: #262626 !important;
}
.dark .ant-slider-disabled .ant-slider-track {
    background-color: rgba(255, 255, 255, 0.3) !important;
}
.dark .ant-slider-disabled .ant-slider-handle,
.dark .ant-slider-disabled .ant-slider-dot {
    background-color: #262626;
    border-color: rgba(255, 255, 255, 0.3) !important;
    box-shadow: none;
}
.dark .ant-spin {
    color: var(--neutral-9);
    color: var(--ant-primary-color);
}
.dark .ant-spin-container::after {
    background: #262626;
}
.dark .ant-spin-tip {
    color: var(--neutral-6);
}
.dark .ant-spin-dot-item {
    background-color: var(--ant-primary-color);
    border-radius: 100%;
}
.dark .ant-statistic {
    color: var(--neutral-9);
}
.dark .ant-statistic-title {
    color: var(--neutral-6);
}
.dark .ant-statistic-content {
    color: var(--neutral-9);
}
.dark .ant-steps {
    color: var(--neutral-9);
}
.dark .ant-steps-item-icon {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 32px;
}
.dark .ant-steps-item-icon .ant-steps-icon {
    color: var(--ant-primary-color);
}
.dark .ant-steps-item-tail::after {
    background: #303030;
    border-radius: 1px;
}
.dark .ant-steps-item-title {
    color: var(--neutral-9);
}
.dark .ant-steps-item-title::after {
    background: #303030;
}
.dark .ant-steps-item-subtitle {
    color: var(--neutral-6);
}
.dark .ant-steps-item-description {
    color: var(--neutral-6);
}
.dark .ant-steps-item-wait .ant-steps-item-icon {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.3);
}
.dark .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: rgba(255, 255, 255, 0.3);
}
.dark .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--neutral-6);
}
.dark .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #303030;
}
.dark .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: var(--neutral-6);
}
.dark .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #303030;
}
.dark .ant-steps-item-process .ant-steps-item-icon {
    background-color: transparent;
    border-color: var(--ant-primary-color);
}
.dark .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: var(--ant-primary-color);
}
.dark .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: var(--ant-primary-color);
}
.dark .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--neutral-9);
}
.dark .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #303030;
}
.dark .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: var(--neutral-9);
}
.dark .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #303030;
}
.dark .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: var(--ant-primary-color);
}
.dark .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    color: #fff;
}
.dark .ant-steps-item-finish .ant-steps-item-icon {
    background-color: transparent;
    border-color: var(--ant-primary-color);
}
.dark .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: var(--ant-primary-color);
}
.dark .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: var(--ant-primary-color);
}
.dark .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--neutral-9);
}
.dark .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: var(--ant-primary-color);
}
.dark .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: var(--neutral-6);
}
.dark .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: var(--ant-primary-color);
}
.dark .ant-steps-item-error .ant-steps-item-icon {
    background-color: transparent;
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
    color: var(--ant-primary-color-hover);
}
.dark .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: var(--ant-primary-color-hover);
}
.dark .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--ant-primary-color-hover);
}
.dark .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #303030;
}
.dark .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: var(--ant-primary-color-hover);
}
.dark .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #303030;
}
.dark .ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
    background: var(--ant-primary-color-hover);
}
.dark .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.dark .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.dark .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
    color: var(--ant-primary-color);
}
.dark
    .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon {
    border-color: var(--ant-primary-color);
}
.dark
    .ant-steps
    .dark
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .dark
    .ant-steps-item-icon
    .dark
    .ant-steps-icon {
    color: var(--ant-primary-color);
}
.dark .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
    background: none;
    border: 0;
}
.dark .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: var(--ant-primary-color);
}
.dark .ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
    background: none;
}
.dark .ant-steps-small .ant-steps-item-icon {
    border-radius: 24px;
}
.dark .ant-steps-small .ant-steps-item-description {
    color: var(--neutral-6);
}
.dark .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
    background: none;
    border: 0;
    border-radius: 0;
}
.dark .ant-steps-dot .ant-steps-item-icon,
.dark .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    background: transparent;
    border: 0;
}
.dark .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.dark .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
    border-radius: 100px;
}
.dark .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.dark .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
    background: rgba(0, 0, 0, 0.001);
}
.dark .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.dark .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
    background: none;
}
.dark .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
    background: none;
}
.dark .ant-steps-navigation .ant-steps-item::after {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: none;
    border-left: none;
}
.dark .ant-steps-navigation .ant-steps-item::before {
    background-color: var(--ant-primary-color);
}
.dark .ant-switch {
    color: var(--neutral-9);
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), linear-gradient(to right, #262626, #262626);
    border: 0;
    border-radius: 100px;
}
.dark .ant-switch:focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
}
.dark .ant-switch-checked:focus {
    box-shadow: 0 0 0 2px #fff0f0;
}
.dark .ant-switch:focus:hover {
    box-shadow: none;
}
.dark .ant-switch-checked {
    background: var(--ant-primary-color);
}
.dark .ant-switch-loading *,
.dark .ant-switch-disabled * {
    box-shadow: none;
}
.dark .ant-switch-inner {
    color: #fff;
}
.dark .ant-switch-handle::before {
    background-color: #fff;
    border-radius: 9px;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
}
.dark .ant-switch-loading-icon.anticon {
    color: rgba(0, 0, 0, 0.65);
}
.dark .ant-switch-checked .ant-switch-loading-icon {
    color: var(--ant-primary-color);
}
.dark .ant-table.ant-table-bordered > .ant-table-title {
    border: 1px solid #303030;
    border-bottom: 0;
}
.dark .ant-table.ant-table-bordered > .ant-table-container {
    border-left: 1px solid #303030;
}
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
    border-right: 1px solid #303030;
}
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
    border-bottom: 1px solid #303030;
}
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th::before,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th::before,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th::before,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th::before {
    background-color: transparent !important;
}
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
    border-right: 1px solid #303030;
}
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
    border-right: 1px solid #303030;
}
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
    border-top: 1px solid #303030;
}
.dark .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.dark .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
    border-right: 0;
}
.dark .ant-table.ant-table-bordered > .ant-table-footer {
    border: 1px solid #303030;
    border-top: 0;
}
.dark .ant-table-cell .ant-table-container:first-child {
    border-top: 0;
}
.dark .ant-table-cell-scrollbar {
    box-shadow: 0 1px 0 1px #1d1d1d;
}
.dark .ant-table {
    color: var(--neutral-9);
    background: #262626;
    border-radius: 2px;
}
.dark .ant-table table {
    border-radius: 2px 2px 0 0;
    border-collapse: separate;
    border-spacing: 0;
}
.dark .ant-table-footer {
    color: var(--neutral-9);
    background: rgba(255, 255, 255, 0.04);
}
.dark .ant-table-thead > tr > th {
    color: var(--neutral-9);
    background: #1d1d1d;
    border-bottom: 1px solid #303030;
}
.dark .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: rgba(0, 0, 0, 0.06);
}
.dark .ant-table-thead > tr:not(:last-child) > th[colspan] {
    border-bottom: 0;
}
.dark .ant-table-tbody > tr > td {
    border-bottom: 1px solid #303030;
}
.dark .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td,
.dark .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
    border-bottom: 0;
}
.dark .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.dark .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.dark .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child,
.dark .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
    border-radius: 0;
}
.dark .ant-table-tbody > tr.ant-table-row:hover > td,
.dark .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #262626;
}
.dark .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #fff0f0;
    border-color: rgba(0, 0, 0, 0.03);
}
.dark .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #311f24;
}
.dark .ant-table-summary {
    background: #262626;
}
div.ant-table-summary {
    box-shadow: 0 -1px 0 #303030;
}
.dark .ant-table-summary > tr > th,
.dark .ant-table-summary > tr > td {
    border-bottom: 1px solid #303030;
}
.dark .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #303030;
}
.dark .ant-table-thead th.ant-table-column-has-sorters:hover::before {
    background-color: transparent !important;
}
.dark .ant-table-thead th.ant-table-column-has-sorters:focus-visible {
    color: var(--ant-primary-color);
}
.dark .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.dark .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
    background: #f5f5f5;
}
.dark .ant-table-thead th.ant-table-column-sort {
    background: #262626;
}
.dark .ant-table-thead th.ant-table-column-sort::before {
    background-color: transparent !important;
}
td.ant-table-column-sort {
    background: rgba(255, 255, 255, 0.01) !important;
}
.dark .ant-table-column-sorter {
    color: #bfbfbf;
}
.dark .ant-table-column-sorter-up.active,
.dark .ant-table-column-sorter-down.active {
    color: var(--ant-primary-color);
}
.dark .ant-table-column-sorters:hover .ant-table-column-sorter {
    color: #a6a6a6;
}
.dark .ant-table-filter-trigger {
    color: #bfbfbf;
    border-radius: 2px;
}
.dark .ant-table-filter-trigger:hover {
    color: var(--neutral-6);
    background: #434343;
}
.dark .ant-table-filter-trigger.active {
    color: var(--ant-primary-color);
}
.dark .ant-table-filter-dropdown {
    color: var(--neutral-9);
    background-color: #262626;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.dark .ant-table-filter-dropdown .ant-dropdown-menu {
    border: 0;
    box-shadow: none;
}
.dark .ant-table-filter-dropdown .ant-dropdown-menu:empty::after {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-table-filter-dropdown-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
    background-color: rgba(255, 255, 255, 0.08);
}
.dark .ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper,
.dark .ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
    background-color: #ffd6da;
}
.dark .ant-table-filter-dropdown-search {
    border-bottom: 1px #303030 solid;
}
.dark .ant-table-filter-dropdown-search-input .anticon {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-table-filter-dropdown-btns {
    background-color: inherit;
    border-top: 1px solid #303030;
}
table tr th.ant-table-selection-column::after {
    background-color: transparent !important;
}
.dark .ant-table-selection-extra .anticon {
    color: #bfbfbf;
}
.dark .ant-table-selection-extra .anticon:hover {
    color: #a6a6a6;
}
.dark .ant-table-row-expand-icon {
    color: var(--ant-primary-color);
    color: inherit;
    background: transparent;
    border: 1px solid #303030;
    border-radius: 2px;
}
.dark .ant-table-row-expand-icon:focus,
.dark .ant-table-row-expand-icon:hover {
    color: var(--ant-primary-color-hover);
}
.dark .ant-table-row-expand-icon:active {
    color: var(--ant-primary-color-active);
}
.dark .ant-table-row-expand-icon:focus,
.dark .ant-table-row-expand-icon:hover,
.dark .ant-table-row-expand-icon:active {
    border-color: currentcolor;
}
.dark .ant-table-row-expand-icon::before,
.dark .ant-table-row-expand-icon::after {
    background: currentcolor;
}
.dark .ant-table-row-expand-icon-spaced {
    background: transparent;
    border: 0;
}
tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
    background: #1d1d1d;
}
.dark .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: #262626;
}
.dark .ant-table-cell-fix-left,
.dark .ant-table-cell-fix-right {
    background: #262626;
}
.dark .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.45);
}
.dark .ant-table-ping-left .ant-table-cell-fix-left-first::after,
.dark .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.45);
}
.dark .ant-table-ping-left .ant-table-cell-fix-left-last::before {
    background-color: transparent !important;
}
.dark .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.45);
}
.dark .ant-table-ping-right .ant-table-cell-fix-right-first::after,
.dark .ant-table-ping-right .ant-table-cell-fix-right-last::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.45);
}
.dark .ant-table-sticky-holder {
    background: #262626;
}
.dark .ant-table-sticky-scroll {
    background: #fcfcfc;
    border-top: 1px solid #303030;
}
.dark .ant-table-sticky-scroll-bar {
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 4px;
}
.dark .ant-table-sticky-scroll-bar:hover {
    background-color: rgba(0, 0, 0, 0.8);
}
.dark .ant-table-sticky-scroll-bar-active {
    background-color: rgba(0, 0, 0, 0.8);
}
.dark .ant-table-title {
    border-radius: 2px 2px 0 0;
}
.dark .ant-table-title + .ant-table-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.dark .ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
    border-radius: 0;
}
.dark .ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
    border-radius: 0;
}
.dark .ant-table-container {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}
.dark .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 2px;
}
.dark .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 2px;
}
.dark .ant-table-footer {
    border-radius: 0 0 2px 2px;
}
.dark .ant-tabs-top > .ant-tabs-nav::before,
.dark .ant-tabs-bottom > .ant-tabs-nav::before,
.dark .ant-tabs-top > div > .ant-tabs-nav::before,
.dark .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #303030;
}
.dark .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.dark .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.dark .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.dark .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.dark .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.dark .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.dark .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.dark .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.dark .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.dark .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.dark .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.dark .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.dark .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.dark .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.dark .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.dark .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.dark .ant-tabs-left > .ant-tabs-content-holder,
.dark .ant-tabs-left > div > .ant-tabs-content-holder {
    border-left: 1px solid #303030;
}
.dark .ant-tabs-right > .ant-tabs-content-holder,
.dark .ant-tabs-right > div > .ant-tabs-content-holder {
    border-right: 1px solid #303030;
}
.dark .ant-tabs-dropdown {
    color: var(--neutral-9);
}
.dark .ant-tabs-dropdown-menu {
    background-color: #262626;
    background-clip: padding-box;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.dark .ant-tabs-dropdown-menu-item {
    color: var(--neutral-9);
}
.dark .ant-tabs-dropdown-menu-item-remove {
    color: var(--neutral-6);
    background: transparent;
    border: 0;
}
.dark .ant-tabs-dropdown-menu-item-remove:hover {
    color: var(--ant-primary-color-hover);
}
.dark .ant-tabs-dropdown-menu-item:hover {
    background: rgba(255, 255, 255, 0.08);
}
.dark .ant-tabs-dropdown-menu-item-disabled,
.dark .ant-tabs-dropdown-menu-item-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
    background: transparent;
}
.dark .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.dark .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid #303030;
}
.dark .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.dark .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: var(--ant-primary-color);
    background: #262626;
}
.dark .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.dark .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 2px 2px 0 0;
}
.dark .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.dark .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #262626;
}
.dark .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.dark .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0 0 2px 2px;
}
.dark .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.dark .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-top-color: #262626;
}
.dark .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.dark .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 2px 0 0 2px;
}
.dark .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.dark .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-right-color: #262626;
}
.dark .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.dark .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0 2px 2px 0;
}
.dark .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.dark .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-left-color: #262626;
}
.dark .ant-tabs {
    color: var(--neutral-9);
}
.dark .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.dark .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
    background: transparent;
    border: 0;
}
.dark .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.dark .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid #303030;
    border-radius: 2px 2px 0 0;
}
.dark .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.dark .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
    color: var(--ant-primary-color-hover);
}
.dark .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.dark .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.dark .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.dark .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
    color: var(--ant-primary-color-active);
}
.dark .ant-tabs-ink-bar {
    background: var(--ant-primary-color);
}
.dark .ant-tabs-tab {
    background: transparent;
    border: 0;
}
.dark .ant-tabs-tab-btn:focus,
.dark .ant-tabs-tab-remove:focus,
.dark .ant-tabs-tab-btn:active,
.dark .ant-tabs-tab-remove:active {
    color: var(--ant-primary-color-active);
}
.dark .ant-tabs-tab-remove {
    color: var(--neutral-6);
    background: transparent;
    border: none;
}
.dark .ant-tabs-tab-remove:hover {
    color: var(--neutral-9);
}
.dark .ant-tabs-tab:hover {
    color: var(--ant-primary-color-hover);
}
.dark .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--ant-primary-color);
}
.dark .ant-tabs-tab.ant-tabs-tab-disabled {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.dark .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.dark .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.dark .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-tag {
    color: var(--neutral-9);
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid #434343;
}
.dark .ant-tag,
.dark .ant-tag a,
.dark .ant-tag a:hover {
    color: var(--neutral-9);
}
.dark .ant-tag-close-icon {
    color: var(--neutral-6);
}
.dark .ant-tag-close-icon:hover {
    color: var(--neutral-9);
}
.dark .ant-tag-has-color {
    border-color: transparent;
}
.dark .ant-tag-has-color,
.dark .ant-tag-has-color a,
.dark .ant-tag-has-color a:hover,
.dark .ant-tag-has-color .anticon-close,
.dark .ant-tag-has-color .anticon-close:hover {
    color: #fff;
}
.dark .ant-tag-checkable {
    background-color: transparent;
    border-color: transparent;
}
.dark .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: var(--ant-primary-color);
}
.dark .ant-tag-checkable:active,
.dark .ant-tag-checkable-checked {
    color: #fff;
}
.dark .ant-tag-checkable-checked {
    background-color: var(--ant-primary-color);
}
.dark .ant-tag-checkable:active {
    background-color: var(--ant-primary-color-active);
}
.dark .ant-tag-pink {
    color: #c41d7f;
    background: #fff0f6;
    border-color: #ffadd2;
}
.dark .ant-tag-pink-inverse {
    color: #fff;
    background: #eb2f96;
    border-color: #eb2f96;
}
.dark .ant-tag-magenta {
    color: #c41d7f;
    background: #fff0f6;
    border-color: #ffadd2;
}
.dark .ant-tag-magenta-inverse {
    color: #fff;
    background: #eb2f96;
    border-color: #eb2f96;
}
.dark .ant-tag-red {
    color: var(--ant-primary-color-active);
    background: #fff0f0;
    border-color: #ffadb7;
}
.dark .ant-tag-red-inverse {
    color: #fff;
    background: var(--ant-primary-color);
    border-color: var(--ant-primary-color);
}
.dark .ant-tag-volcano {
    color: #d4380d;
    background: #fff2e8;
    border-color: #ffbb96;
}
.dark .ant-tag-volcano-inverse {
    color: #fff;
    background: #fa541c;
    border-color: #fa541c;
}
.dark .ant-tag-orange {
    color: #d46b08;
    background: #fff7e6;
    border-color: #ffd591;
}
.dark .ant-tag-orange-inverse {
    color: #fff;
    background: #fa8c16;
    border-color: #fa8c16;
}
.dark .ant-tag-yellow {
    color: #d4b106;
    background: #feffe6;
    border-color: #fffb8f;
}
.dark .ant-tag-yellow-inverse {
    color: #fff;
    background: #fadb14;
    border-color: #fadb14;
}
.dark .ant-tag-gold {
    color: #d48806;
    background: #999190;
    border-color: #ffe58f;
}
.dark .ant-tag-gold-inverse {
    color: #fff;
    background: #faad14;
    border-color: #faad14;
}
.dark .ant-tag-cyan {
    color: #08979c;
    background: #e6fffb;
    border-color: #87e8de;
}
.dark .ant-tag-cyan-inverse {
    color: #fff;
    background: #13c2c2;
    border-color: #13c2c2;
}
.dark .ant-tag-lime {
    color: #7cb305;
    background: #fcffe6;
    border-color: #eaff8f;
}
.dark .ant-tag-lime-inverse {
    color: #fff;
    background: #a0d911;
    border-color: #a0d911;
}
.dark .ant-tag-green {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
}
.dark .ant-tag-green-inverse {
    color: #fff;
    background: #52c41a;
    border-color: #52c41a;
}
.dark .ant-tag-blue {
    color: #096dd9;
    background: #e6f7ff;
    border-color: #91d5ff;
}
.dark .ant-tag-blue-inverse {
    color: #fff;
    background: #1890ff;
    border-color: #1890ff;
}
.dark .ant-tag-geekblue {
    color: #1d39c4;
    background: #fff2f0;
    border-color: #adc6ff;
}
.dark .ant-tag-geekblue-inverse {
    color: #fff;
    background: #2f54eb;
    border-color: #2f54eb;
}
.dark .ant-tag-purple {
    color: #531dab;
    background: #f9f0ff;
    border-color: #d3adf7;
}
.dark .ant-tag-purple-inverse {
    color: #fff;
    background: #722ed1;
    border-color: #722ed1;
}
.dark .ant-tag-success {
    color: #52c41a;
    background: #f6ffed;
    border-color: #b7eb8f;
}
.dark .ant-tag-processing {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
}
.dark .ant-tag-error {
    color: var(--ant-primary-color-hover);
    background: #fff2f0;
    border-color: #ffd6da;
}
.dark .ant-tag-warning {
    color: #faad14;
    background: #fffbe6;
    border-color: #ffe58f;
}
.dark .ant-timeline {
    color: var(--neutral-9);
}
.dark .ant-timeline-item-tail {
    border-left: 2px solid #303030;
}
.dark .ant-timeline-item-pending .ant-timeline-item-head {
    background-color: transparent;
}
.dark .ant-timeline-item-head {
    background-color: #262626;
    border: 2px solid transparent;
    border-radius: 100px;
}
.dark .ant-timeline-item-head-blue {
    color: var(--ant-primary-color);
    border-color: var(--ant-primary-color);
}
.dark .ant-timeline-item-head-red {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-timeline-item-head-green {
    color: #52c41a;
    border-color: #52c41a;
}
.dark .ant-timeline-item-head-gray {
    color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
}
.dark .ant-timeline-item-head-custom {
    border: 0;
    border-radius: 0;
}
.dark .ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
    border-left: 2px dotted #303030;
}
.dark .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
    border-left: 2px dotted #303030;
}
.dark .ant-timeline-rtl .ant-timeline-item-tail {
    border-right: 2px solid #303030;
    border-left: none;
}
.dark .ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
    border-right: 2px dotted #303030;
    border-left: none;
}
.dark .ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
    border-right: 2px dotted #303030;
    border-left: none;
}
.dark .ant-tooltip {
    color: var(--neutral-9);
}

.dark  .ant-tooltip-arrow-content{
    --antd-arrow-background-color: #F0F0F0 !important;
}


.dark .ant-tooltip-inner {
    color: var(--neutral-4);
    background-color: #F0F0F0 !important;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.dark .ant-tooltip-arrow {
    background: transparent;
}
.dark .ant-tooltip-arrow-content {
    --antd-arrow-background-color: var(--text);
    background-color: transparent;
    border-radius: 0 0 2px;
}
.dark .ant-tooltip-arrow-content::before {
    background: linear-gradient(to left, var(--antd-arrow-background-color) 50%, var(--antd-arrow-background-color) 50%) no-repeat -10px -10px;
}
.dark .ant-tooltip-placement-top .ant-tooltip-arrow-content,
.dark .ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.dark .ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.dark .ant-tooltip-placement-right .ant-tooltip-arrow-content,
.dark .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.dark .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
.dark .ant-tooltip-placement-left .ant-tooltip-arrow-content,
.dark .ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.dark .ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
.dark .ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.dark .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.dark .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
    box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
}
.dark .ant-tooltip-pink .ant-tooltip-inner {
    background-color: #eb2f96;
}
.dark .ant-tooltip-pink .ant-tooltip-arrow-content::before {
    background: #eb2f96;
}
.dark .ant-tooltip-magenta .ant-tooltip-inner {
    background-color: #eb2f96;
}
.dark .ant-tooltip-magenta .ant-tooltip-arrow-content::before {
    background: #eb2f96;
}
.dark .ant-tooltip-red .ant-tooltip-inner {
    background-color: var(--ant-primary-color);
}
.dark .ant-tooltip-red .ant-tooltip-arrow-content::before {
    background: var(--ant-primary-color);
}
.dark .ant-tooltip-volcano .ant-tooltip-inner {
    background-color: #fa541c;
}
.dark .ant-tooltip-volcano .ant-tooltip-arrow-content::before {
    background: #fa541c;
}
.dark .ant-tooltip-orange .ant-tooltip-inner {
    background-color: #fa8c16;
}
.dark .ant-tooltip-orange .ant-tooltip-arrow-content::before {
    background: #fa8c16;
}
.dark .ant-tooltip-yellow .ant-tooltip-inner {
    background-color: #fadb14;
}
.dark .ant-tooltip-yellow .ant-tooltip-arrow-content::before {
    background: #fadb14;
}
.dark .ant-tooltip-gold .ant-tooltip-inner {
    background-color: #faad14;
}
.dark .ant-tooltip-gold .ant-tooltip-arrow-content::before {
    background: #faad14;
}
.dark .ant-tooltip-cyan .ant-tooltip-inner {
    background-color: #13c2c2;
}
.dark .ant-tooltip-cyan .ant-tooltip-arrow-content::before {
    background: #13c2c2;
}
.dark .ant-tooltip-lime .ant-tooltip-inner {
    background-color: #a0d911;
}
.dark .ant-tooltip-lime .ant-tooltip-arrow-content::before {
    background: #a0d911;
}
.dark .ant-tooltip-green .ant-tooltip-inner {
    background-color: #52c41a;
}
.dark .ant-tooltip-green .ant-tooltip-arrow-content::before {
    background: #52c41a;
}
.dark .ant-tooltip-blue .ant-tooltip-inner {
    background-color: #1890ff;
}
.dark .ant-tooltip-blue .ant-tooltip-arrow-content::before {
    background: #1890ff;
}
.dark .ant-tooltip-geekblue .ant-tooltip-inner {
    background-color: #2f54eb;
}
.dark .ant-tooltip-geekblue .ant-tooltip-arrow-content::before {
    background: #2f54eb;
}
.dark .ant-tooltip-purple .ant-tooltip-inner {
    background-color: #722ed1;
}
.dark .ant-tooltip-purple .ant-tooltip-arrow-content::before {
    background: #722ed1;
}
.dark .ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
    border: 0;
    border-radius: 0;
}
.dark .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
    background: #1d1d1d;
}
.dark .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
    border-bottom: 1px solid #303030;
}
.dark .ant-transfer-customize-list .ant-input[disabled] {
    background-color: transparent;
}
.dark .ant-transfer-status-error .ant-transfer-list {
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-transfer-status-error .ant-transfer-list-search:not([disabled]) {
    border-color: #434343;
}
.dark .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
    border-color: var(--ant-primary-color-hover);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(232, 46, 86, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-transfer-status-warning .ant-transfer-list {
    border-color: #faad14;
}
.dark .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]) {
    border-color: #434343;
}
.dark .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
    border-color: var(--ant-primary-color-hover);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(232, 46, 86, 0.2);
    border-right-width: 1px;
}
.dark .ant-input-rtl .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
    border-right-width: 0;
    border-left-width: 1px !important;
}
.dark .ant-transfer {
    color: var(--neutral-9);
}
.dark .ant-transfer-disabled .ant-transfer-list {
    background: rgba(255, 255, 255, 0.08);
}
.dark .ant-transfer-list {
    border: 1px solid #434343;
    border-radius: 2px;
}
.dark .ant-transfer-list-search .anticon-search {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-transfer-list-header {
    color: var(--neutral-9);
    background: #262626;
    border-bottom: 1px solid #303030;
    border-radius: 2px 2px 0 0;
}
.dark .ant-transfer-list-content-item-remove {
    color: var(--ant-primary-color);
    color: #434343;
}
.dark .ant-transfer-list-content-item-remove:focus,
.dark .ant-transfer-list-content-item-remove:hover {
    color: var(--ant-primary-color-hover);
}
.dark .ant-transfer-list-content-item-remove:active {
    color: var(--ant-primary-color-active);
}
.dark .ant-transfer-list-content-item-remove:hover {
    color: var(--ant-primary-color-hover);
}
.dark .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    background-color: #262626;
}
.dark .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
    background-color: #311f24;
}
.dark .ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    background: transparent;
}
.dark .ant-transfer-list-content-item-checked {
    background-color: #382329;
}
.dark .ant-transfer-list-content-item-disabled {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-transfer-list-pagination {
    border-top: 1px solid #303030;
}
.dark .ant-transfer-list-body-not-found {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-transfer-list-footer {
    border-top: 1px solid #303030;
}
.dark .ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
    background: rgba(255, 255, 255, 0.08);
}
.dark .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
    border-radius: 0;
}
.dark .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
    background: transparent;
}
.dark .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
    color: #fff;
    background: transparent;
}
.dark .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.dark .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
    background: var(--ant-primary-color);
}
.dark .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
    color: #fff;
}
.dark .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
    color: #fff;
    background: transparent;
}
.dark .ant-tree-checkbox {
    color: var(--neutral-9);
}
.dark .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.dark .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.dark .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: var(--ant-primary-color);
}
.dark .ant-tree-checkbox-checked::after {
    border: 1px solid var(--ant-primary-color);
    border-radius: 2px;
    animation-fill-mode: backwards;
}
.dark .ant-tree-checkbox-inner {
    background-color: transparent;
    border: 1px solid #434343;
    border-radius: 2px;
    border-collapse: separate;
}
.dark .ant-tree-checkbox-inner::after {
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
}
.dark .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
}
.dark .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: var(--ant-primary-color);
    border-color: var(--ant-primary-color);
}
.dark .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.3);
}
.dark .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343 !important;
}
.dark .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.08);
    border-collapse: separate;
}
.dark .ant-tree-checkbox-disabled + span {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-tree-checkbox-wrapper {
    color: var(--neutral-9);
}
.dark .ant-tree-checkbox-group {
    color: var(--neutral-9);
}
.dark .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
    background-color: transparent;
    border-color: #434343;
}
.dark .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: var(--ant-primary-color);
    border: 0;
}
.dark .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
    background-color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
}
.dark .ant-tree {
    color: var(--neutral-9);
    background: transparent;
    border-radius: 2px;
}
.dark .ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
    background: #fff0f0;
}
.dark .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging::after {
    border: 1px solid var(--ant-primary-color);
    animation-fill-mode: forwards;
}
.dark .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
    background: transparent;
}
.dark .ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
    background: rgba(255, 255, 255, 0.08);
}
.dark .ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
    color: inherit;
}
.dark .ant-tree-switcher-loading-icon {
    color: var(--ant-primary-color);
}
.dark .ant-tree-switcher-leaf-line::before {
    border-right: 1px solid var(--neutral-5);
}
.dark .ant-tree-switcher-leaf-line::after {
    border-bottom: 1px solid var(--neutral-5);
}
.dark .ant-tree .ant-tree-node-content-wrapper {
    color: inherit;
    background: transparent;
    border-radius: 2px;
}
.dark .ant-tree .ant-tree-node-content-wrapper:hover {
    background-color: rgba(255, 255, 255, 0.08);
}
.dark .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #ffd6da;
}
.dark .ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
    background-color: transparent;
}
.dark .ant-tree-node-content-wrapper .ant-tree-drop-indicator {
    background-color: var(--ant-primary-color);
    border-radius: 1px;
}
.dark .ant-tree-node-content-wrapper .ant-tree-drop-indicator::after {
    background-color: transparent;
    border: 2px solid var(--ant-primary-color);
    border-radius: 50%;
}
.dark .ant-tree .ant-tree-treenode.drop-container > [draggable] {
    box-shadow: 0 0 0 2px var(--ant-primary-color);
}
.dark .ant-tree-show-line .ant-tree-indent-unit::before {
    border-right: 1px solid #434343;
}
.dark .ant-tree-show-line .ant-tree-switcher {
    background: #262626;
}
.dark .ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
    border-right: none;
    border-left: 1px solid #434343;
}
.dark .ant-select-tree-checkbox {
    color: var(--neutral-9);
}
.dark .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.dark .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.dark .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
    border-color: var(--ant-primary-color);
}
.dark .ant-select-tree-checkbox-checked::after {
    border: 1px solid var(--ant-primary-color);
    border-radius: 2px;
    animation-fill-mode: backwards;
}
.dark .ant-select-tree-checkbox-inner {
    background-color: transparent;
    border: 1px solid #434343;
    border-radius: 2px;
    border-collapse: separate;
}
.dark .ant-select-tree-checkbox-inner::after {
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
}
.dark .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
}
.dark .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: var(--ant-primary-color);
    border-color: var(--ant-primary-color);
}
.dark .ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.3);
}
.dark .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343 !important;
}
.dark .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.08);
    border-collapse: separate;
}
.dark .ant-select-tree-checkbox-disabled + span {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-select-tree-checkbox-wrapper {
    color: var(--neutral-9);
}
.dark .ant-select-tree-checkbox-group {
    color: var(--neutral-9);
}
.dark .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
    background-color: transparent;
    border-color: #434343;
}
.dark .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
    background-color: var(--ant-primary-color);
    border: 0;
}
.dark .ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
    background-color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
}
.dark .ant-tree-select-dropdown .ant-select-tree {
    border-radius: 0;
}
.dark .ant-select-tree {
    color: var(--neutral-9);
    background: transparent;
    border-radius: 2px;
}
.dark .ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
    background: #fff0f0;
}
.dark .ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging::after {
    border: 1px solid var(--ant-primary-color);
    animation-fill-mode: forwards;
}
.dark .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
    background: transparent;
}
.dark .ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
    background: rgba(255, 255, 255, 0.08);
}
.dark .ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
    color: inherit;
}
.dark .ant-select-tree-switcher-loading-icon {
    color: var(--ant-primary-color);
}
.dark .ant-select-tree-switcher-leaf-line::before {
    border-right: 1px solid var(--neutral-5);
}
.dark .ant-select-tree-switcher-leaf-line::after {
    border-bottom: 1px solid var(--neutral-5);
}
.dark .ant-select-tree .ant-select-tree-node-content-wrapper {
    color: inherit;
    background: transparent;
    border-radius: 2px;
}
.dark .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
    background-color: rgba(255, 255, 255, 0.08);
}
.dark .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: #ffd6da;
}
.dark .ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
    background-color: transparent;
}
.dark .ant-select-tree-node-content-wrapper .ant-tree-drop-indicator {
    background-color: var(--ant-primary-color);
    border-radius: 1px;
}
.dark .ant-select-tree-node-content-wrapper .ant-tree-drop-indicator::after {
    background-color: transparent;
    border: 2px solid var(--ant-primary-color);
    border-radius: 50%;
}
.dark .ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
    box-shadow: 0 0 0 2px var(--ant-primary-color);
}
.dark .ant-select-tree-show-line .ant-select-tree-indent-unit::before {
    border-right: 1px solid #434343;
}
.dark .ant-select-tree-show-line .ant-select-tree-switcher {
    background: #262626;
}

.dark .ant-typography.ant-typography-secondary {
    color: var(--neutral-6);
}
.dark .ant-typography.ant-typography-success {
    color: #52c41a;
}
.dark .ant-typography.ant-typography-warning {
    color: #faad14;
}
.dark .ant-typography.ant-typography-danger {
    color: var(--ant-primary-6);
}
.dark a.ant-typography.ant-typography-danger:active,
.dark a.ant-typography.ant-typography-danger:focus {
    color: #cf405f;
}
.dark a.ant-typography.ant-typography-danger:hover {
    color: #ff8597;
}
.dark .ant-typography.ant-typography-disabled {
    color: rgba(255, 255, 255, 0.3);
}
.dark h1.ant-typography,
.dark div.ant-typography-h1,
.dark div.ant-typography-h1 > textarea,
.dark .ant-typography h1 {
    color: var(--neutral-9);
}
.dark h2.ant-typography,
.dark div.ant-typography-h2,
.dark div.ant-typography-h2 > textarea,
.dark .ant-typography h2 {
    color: var(--neutral-9);
}
.dark h3.ant-typography,
.dark div.ant-typography-h3,
.dark div.ant-typography-h3 > textarea,
.dark .ant-typography h3 {
    color: var(--neutral-9);
}
.dark h4.ant-typography,
.dark div.ant-typography-h4,
.dark div.ant-typography-h4 > textarea,
.dark .ant-typography h4 {
    color: var(--neutral-9);
}
.dark h5.ant-typography,
.dark div.ant-typography-h5,
.dark div.ant-typography-h5 > textarea,
.dark .ant-typography h5 {
    color: var(--neutral-9);
}
.dark a.ant-typography,
.dark .ant-typography a {
    color: var(--ant-primary-color);
}
.dark a.ant-typography:focus,
.dark .ant-typography a:focus,
.dark a.ant-typography:hover,
.dark .ant-typography a:hover {
    color: var(--ant-primary-color-hover);
}
.dark a.ant-typography:active,
.dark .ant-typography a:active {
    color: var(--ant-primary-color-active);
}
a.ant-typography[disabled],
.dark .ant-typography a[disabled],
.dark .dark a.ant-typography.ant-typography-disabled,
.dark .ant-typography a.ant-typography-disabled {
    color: rgba(255, 255, 255, 0.3);
}
.dark a.ant-typography[disabled]:active,
.dark .ant-typography a[disabled]:active,
.dark a.ant-typography.ant-typography-disabled:active,
.dark .ant-typography a.ant-typography-disabled:active,
.dark a.ant-typography[disabled]:hover,
.dark .ant-typography a[disabled]:hover,
.dark a.ant-typography.ant-typography-disabled:hover,
.dark .ant-typography a.ant-typography-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-typography code {
    background: rgba(150, 150, 150, 0.1);
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 3px;
}
.dark .ant-typography kbd {
    background: rgba(150, 150, 150, 0.06);
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-bottom-width: 2px;
    border-radius: 3px;
}
.dark .ant-typography mark {
    background-color: #ffe58f;
}
.dark .ant-typography-expand,
.dark .ant-typography-edit,
.dark .ant-typography-copy {
    color: var(--ant-primary-color);
}
.dark .ant-typography-expand:focus,
.dark .ant-typography-edit:focus,
.dark .ant-typography-copy:focus,
.dark .ant-typography-expand:hover,
.dark .ant-typography-edit:hover,
.dark .ant-typography-copy:hover {
    color: var(--ant-primary-color-hover);
}
.dark .ant-typography-expand:active,
.dark .ant-typography-edit:active,
.dark .ant-typography-copy:active {
    color: var(--ant-primary-color-active);
}
.dark .ant-typography-copy-success,
.dark .ant-typography-copy-success:hover,
.dark .ant-typography-copy-success:focus {
    color: #52c41a;
}
.dark .ant-typography-edit-content-confirm {
    color: var(--neutral-6);
}
.dark .ant-typography pre {
    background: rgba(150, 150, 150, 0.1);
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 3px;
}
.dark .ant-typography pre code {
    background: transparent;
    border: 0;
}
.dark .ant-typography blockquote {
    border-left: 4px solid rgba(100, 100, 100, 0.2);
}
.dark .ant-upload {
    color: var(--neutral-9);
}
.dark .ant-upload.ant-upload-select-picture-card {
    background-color: rgba(255, 255, 255, 0.04);
    border: 1px dashed #434343;
    border-radius: 2px;
}
.dark .ant-upload.ant-upload-select-picture-card:hover {
    border-color: var(--ant-primary-color);
}
.dark .ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
    border-color: #434343;
}
.dark .ant-upload.ant-upload-drag {
    background: rgba(255, 255, 255, 0.04);
    border: 1px dashed #434343;
    border-radius: 2px;
}
.dark .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
    border-color: var(--ant-primary-color-active);
}
.dark .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: var(--ant-primary-color-hover);
}
.dark .ant-upload.ant-upload-drag p.ant-upload-text {
    color: var(--neutral-9);
}
.dark .ant-upload.ant-upload-drag p.ant-upload-hint {
    color: var(--neutral-6);
}
.dark .ant-upload.ant-upload-drag .anticon-plus {
    color: rgba(255, 255, 255, 0.3);
}
.dark .ant-upload.ant-upload-drag .anticon-plus:hover {
    color: var(--neutral-6);
}
.dark .ant-upload.ant-upload-drag:hover .anticon-plus {
    color: var(--neutral-6);
}
.dark .ant-upload-list {
    color: var(--neutral-9);
}
.dark .ant-upload-list-item-card-actions .anticon {
    color: var(--neutral-6);
}
.dark .ant-upload-list-item-card-actions:hover .anticon {
    color: var(--neutral-9);
}
.dark .ant-upload-list-item-info .anticon-loading .anticon,
.dark .ant-upload-list-item-info .ant-upload-text-icon .anticon {
    color: var(--neutral-6);
}
.dark .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: rgba(255, 255, 255, 0.08);
}
.dark .ant-upload-list-item-error,
.dark .ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.dark .ant-upload-list-item-error .ant-upload-list-item-name {
    color: var(--ant-primary-color-hover);
}
.dark .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
    color: var(--ant-primary-color-hover);
}
.dark .ant-upload-list-picture .ant-upload-list-item,
.dark .ant-upload-list-picture-card .ant-upload-list-item {
    border: 1px solid #434343;
    border-radius: 2px;
}
.dark .ant-upload-list-picture .ant-upload-list-item:hover,
.dark .ant-upload-list-picture-card .ant-upload-list-item:hover {
    background: transparent;
}
.dark .ant-upload-list-picture .ant-upload-list-item-error,
.dark .ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: var(--ant-primary-color-hover);
}
.dark .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.dark .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
    background: transparent;
}
.dark .ant-upload-list-picture .ant-upload-list-item-uploading,
.dark .ant-upload-list-picture-card .ant-upload-list-item-uploading {
    border-style: dashed;
}
.dark .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.dark .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
    fill: #fff2f0;
}
.dark .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.dark .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
    fill: var(--ant-primary-color-hover);
}
.dark .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    background-color: rgba(0, 0, 0, 0.5);
}
.dark .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.dark .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.dark .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    color: var(--neutral-9);
}
.dark .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.dark .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.dark .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
    color: #fff;
}
.dark .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
    background-color: rgba(255, 255, 255, 0.04);
}
.dark .ant-upload-list .ant-upload-animate-inline-appear,
.dark .ant-upload-list .ant-upload-animate-inline-enter,
.dark .ant-upload-list .ant-upload-animate-inline-leave {
    animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
