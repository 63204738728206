.button_select {
    display: flex;
    align-items: center;
    border: 1px solid var(--border-button);
    transition: 0.3s all;
}
.button_select:hover {
    cursor: pointer;
    border: 1px solid var(--ant-primary-color-hover);
}

.button_select:active {
    cursor: pointer;
    border: 1px solid var(--ant-primary-color-active);
}
