.item{
   transition: all 0.3s; 
}


.item:hover{
    cursor: pointer;
    background-color: var(--neutral-2);
}
.item_active{
    border-right: 4px var(--ant-primary-color) solid;
    background-color: var(--neutral-2);
}