.color_image{
    color: var(--post-type-image-icon);
}

.color_video{
    color: var(--post-type-video-icon);
}

.color_poll{
    color: var(--post-type-poll-icon);
}

.color_attachment{
    color: var(--post-type-attachment-icon);
}

.background_image{
    background: var(--post-type-image-background);
}

.background_video{
    background: var(--post-type-video-background);
}

.background_poll{
    background: var(--post-type-poll-background);
}

.background_attachment{
    background: var(--post-type-attachment-background);
}



.color_image:hover{
    color: var(--post-type-image-icon-hover);
}

.color_video:hover{
    color: var(--post-type-video-icon-hover);
}

.color_poll:hover{
    color: var(--post-type-poll-icon-hover);
}

.color_attachment:hover{
    color: var(--post-type-attachment-icon-hover);
}

.color_image:hover .background_image{
    background: var(--post-type-image-background-hover);
}

.color_video:hover .background_video{
    background: var(--post-type-video-background-hover);
}

.color_poll:hover .background_poll{
    background: var(--post-type-poll-background-hover);
}

.color_attachment:hover .background_attachment{
    background: var(--post-type-attachment-background-hover);
}


.color_image:active{
    color: var(--post-type-image-icon-active);
}

.color_video:active{
    color: var(--post-type-video-icon-active);
}

.color_poll:active{
    color: var(--post-type-poll-icon-active);
}

.color_attachment:active{
    color: var(--post-type-attachment-icon-active);
}

.color_image:active .background_image{
    background: var(--post-type-image-background-active);
}

.color_video:active .background_video{
    background: var(--post-type-video-background-active);
}

.color_poll:active .background_poll{
    background: var(--post-type-poll-background-active);
}

.color_attachment:active .background_attachment{
    background: var(--post-type-attachment-background-active);
}



.button{
    cursor: pointer;
    transition: all 0.3s;
}


.circle{
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.043);
    border-radius: 100px;
    transition: all 0.3s;

}


.small{
    padding: 8px;
}
.medium{
    padding: 12px;
}


.disabled_color{
    cursor: not-allowed;
    color: var(--neutral-6) !important;
} 
.disabled_background{
    cursor: not-allowed;
    background: var(--neutral-2) !important;
}