.buttonBack {
    border: 0 !important;
    background-color: transparent !important;
    margin: 0 auto !important;
    padding: 0;
  }
  .buttonBack:hover{
    cursor: pointer;
  }
  .buttonBack:focus,
  .buttonBack[focus]{
    outline:0;
  }

  .buttonBack:active,
  .buttonBack[active]{
    outline:0;
  }


  .buttonBack:disabled,
  .buttonBack[disabled]{
    visibility: hidden;

}
  .buttonNext {
    border: 0 !important;
    background-color: transparent !important;
    margin: 0 auto !important;
    padding: 0;
  }
  .buttonNext:hover{
    cursor: pointer;
  }
  .buttonNext:active,
  .buttonNext[active]{
    outline:0 !important;
  }

  .buttonNext:focus,
  .buttonNext[focus]{
    outline:0;
  }

  .buttonNext:disabled,
  .buttonNext[disabled]{
    visibility: hidden;
}


.slide{
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  left: 10px;
  top: 10px;
}


.maxWidth{
  width: 100vw !important;
}