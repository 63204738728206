.google {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 4px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    height: 40px;
    place-items: center;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    margin-bottom: 8px;
}


.facebook {
    background: #1890FF;
    border: 1px solid #1890FF;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 4px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    height: 40px;
    place-items: center;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;    
    cursor: pointer;
    margin-bottom: 8px;
}

.linkedin {
    background: #0050B3;
    border: 1px solid #0050B3;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 4px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    height: 40px;
    place-items: center;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    margin-bottom: 8px;
}

.zoom {
    background: #1890FF;
    border: 1px solid #1890FF;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 4px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    height: 40px;
    place-items: center;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    margin-bottom: 8px;
}