.item {
    border: 1px solid var(--tag-border);
    border-radius: 100px;
    transition: all 0.3s ease;
    cursor: pointer;
    display: inline-flex;
    padding: 4px 8px;
}

.itemSelected {
    border: 1px solid var(--tag-active-border);
    color:  var(--tag-active-border);
    transition: all 0.3s ease;
    border-radius: 100px;
    cursor: pointer;
    display: inline-flex;
    padding: 4px 8px;
}


.itemDisabled {
    border: 1px solid var(--tag-border);
    color:  var(--neutral-6);
    transition: all 0.3s ease;
    border-radius: 100px;
    cursor: default;
    display: inline-flex;
    padding: 4px 8px;

}

.itemSelectedDisabled {
    border: 1px solid var(--tag-border);
    color:  var(--neutral-6);
    transition: all 0.3s ease;
    border-radius: 100px;
    cursor: pointer;
}

@media (hover: hover) {

    .item:hover {
        border: 1px solid var(--tag-active-border);
        color:  var(--tag-active-border);
    }
}
