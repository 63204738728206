.previewCard {
    cursor: pointer;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.image {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32vw;
    height: 18vw;
    border-radius: 4px;
    color: #fff;
    min-height: 153px;
    min-width: 153px;
    max-width: 200px;
    max-height: 200px;
}

.previewCard:hover .title {
    color: var(--ant-primary-color-hover);
}
.previewCard:hover .description {
    color: var(--ant-primary-color-hover);
}
.title {
    overflow: hidden;
    color: #222222;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s;
}

.description {
    margin-bottom: 0px !important;
    color: #222222;
    font-size: 12px;
    transition: all 0.3s;
}
