.menu {
    width: 100% !important;
    padding-bottom: 10px !important;
}

.menuBottom {
    width: 100% !important;
    line-height: 17px !important;
    height: 100% !important;
}

.menuItemExpanded {
    font-size: 16px !important;
    margin: 0 !important;
    padding-left: 16px !important;
}




.menuItemCollapse {
    font-size: 16px !important;
    margin: 0 !important;
    display: flex !important;
    align-items: center !important;
}

.menuItemBottom {
    font-size: 11px !important;
    width: 25% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}


.menuItemExpanded:hover .menuIcon {
    color: var(--ant-primary-color) !important;

}


.menuItemCollapse:hover .menuIcon {
    color: var(--ant-primary-color) !important;

}

.menuItem {
    padding: 0px !important;
    margin: 0px !important;
}

.menuIcon {
    transition: 0.3s all;
    display: flex !important;
    width: 24px !important;
}

.menuText:hover {
    color: white !important;
}

.link-dark {
    color: white !important;
}

.link-light {
    color: black !important;
}

.link-light:hover {
    color: var(--ant-primary-color) !important;
}

.link-dark:hover {
    color: var(--ant-primary-color) !important;
}

