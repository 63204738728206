.item {
    border: 1px solid var(--tag-border);
    box-sizing: border-box;
    border-radius: 100px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.itemSelected {
    background: var(--tag-active-background);
    border: 1px solid var(--tag-active-border);
    color:  var(--tag-active-border);
    box-sizing: border-box;
    transition: all 0.3s ease;
    border-radius: 100px;
    cursor: pointer;
}


.itemDisabled {
    border: 1px solid var(--tag-border);
    box-sizing: border-box;
    border-radius: 100px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.itemSelectedDisabled {
    background: var(--tag-active-background);
    border: 1px solid var(--tag-active-border);
    color:  var(--tag-active-border);
    box-sizing: border-box;
    transition: all 0.3s ease;
    border-radius: 100px;
    cursor: pointer;
}

@media (hover: hover) {
    .itemSelected:hover {
        background: var(--tag-active-background);
    }

    .item:hover {
        border: 1px solid var(--tag-active-border);
        color:  var(--tag-active-border);
    }
}
