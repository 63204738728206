.previewCard {
    cursor: pointer;
}

.previewCard:hover .title {
    color: var(--ant-primary-color);
}
.previewCard:hover .description {
    color: var(--ant-primary-color);
}
.title {
    color: var(--text);
    font-weight: 500;
    font-size: 14px;
    white-space: wrap;
    text-overflow: ellipsis;
    transition: all 0.3s;
}

.description {
    margin-bottom: 0px !important;
    color: var(--text);
    font-size: 12px;
    transition: all 0.3s;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-word;
}
