

.create-button{
    border: 1px solid var(--neutral-5) !important;
}

.create-button:hover{
    border: 1px solid #8C8C8C !important;

}
.create-button:focus{
    border: 1px solid #8C8C8C !important;

}