.container{
  height: 434px;
  background: #fff;
}
.img-container{
  width: 100%;
  height: 40%;
  position: relative;
}

.img-container img.banner-img{
  width: 100%;
  height: 100%;

}

.editContainer {
  margin-right: 24px !important; 
  margin-top: 24px !important; 
  margin-bottom: 40px !important; 
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
  justify-content: end;
}


.img-container .profile-img{
  display: flex;
  align-items: center;
  font-size: 40px;
  position: absolute;
  bottom: -80px;
  object-fit: cover;
  left: 24px;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 4px solid var(--background);
}

@media screen and (max-width: 575px) {
  .img-container .profile-img{
    width: 80px;
    height: 80px;
    bottom: -60px;
    font-size: 16px;
  }

  .editContainer {
    margin: 0px !important; 
  }

  .infoContainer {
    padding: 16px;
  }
}