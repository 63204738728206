
.libox {
    margin-top: 4pt;
    margin-bottom: 6pt;
    margin-left: 1em;
    display: list-item;
    list-style: disc;
    margin-right: 0pt;
    text-indent: 0pt;
}

.text {
    top: -2px;
    position: relative;
}


.libox::marker {
    color: var(--cyan-6);
    font-size: 18px;
}