.container-double {
    display: grid;
    grid-column-gap: 26px;
    grid-template-columns: 1fr 1fr;
}

.container-simple {
    display: grid;
    grid-row-gap: 8px;
    grid-template-columns: 1fr;
}

.container-double-private {
    display: flex;
    align-items: center;
    justify-content: space-between;
}




.container-text {
    flex: 1 1 200px;
}

.container-action-small {
    display: flex;
    justify-content: space-between;
}

.container-action {
    display: flex;
}
