.logo:hover{
    cursor: pointer;
}


.menuWhite{
    color: black;
    transition: 0.3s all;
}

.menuWhite:hover{
    cursor: pointer;
    color: #FF3862;
}


.menuDark{
    color: white;
    transition: 0.3s all;
}

.menuDark:hover{
    cursor: pointer;
    color: #FF3862;
}


.icon:hover{
    color: var(--ant-primary-color) !important;
    border-bottom: 2.5px solid var(--ant-primary-color);
}

.icon{
    transition: color 0.3s;
    padding: 12px;
    display: flex;
    border-bottom: 2.5px solid transparent;
    cursor: pointer;
}
.icon_active{
    transition: all 0.3s;
    padding: 12px;
    display: flex;
    color: var(--ant-primary-color) !important;
    border-bottom: 2.5px solid var(--ant-primary-color);
    cursor: pointer;}

