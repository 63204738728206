.mask_button {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    border: 1px solid var(--border);
    border-radius: 100px;
    padding: 4px;
    transition: 0.3s all;
    background-color: var(--background);
}
.mask_button:hover {
    cursor: pointer;
    border: 1px solid var(--border);
    background-color: var(--neutral-2);
}
