.container{
    height: 434px;
    background: #fff;
  }
  .img-container{
    width: 100%;
    height: 40%;
    position: relative;
  }
  
  .img-container img.banner-img{
    width: 100%;
    height: 100%;

  }

  .editContainer {
    margin-bottom: 40px;
  }

  .infoContainer {
    padding: 24px;
  }
  
  .img-container img.profile-img{
    position: absolute;
    bottom: -80px;
    object-fit: cover;
    left: 24px;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 4px solid #fff;
  }
  
  @media screen and (max-width: 575px) {
    .img-container img.profile-img{
      width: 80px;
      height: 80px;
      bottom: -60px;
    }

    .editContainer {
      margin-bottom: 33px;
    }
  
    .infoContainer {
      padding: 16px;
    }
  }