.notification:hover{
    background-color: var(--neutral-3);
    cursor: pointer;
}

.notification{
    display: flex;
    padding: 12px 16px;
    transition: all 0.3s;
}


.icon:hover{
    color: var(--ant-primary-color) !important;
    border-bottom: 2.5px solid var(--ant-primary-color);
}

.icon{
    transition: color 0.3s;
    padding: 12px;
    display: flex;
    border-bottom: 2.5px solid transparent;
    cursor: pointer;
}
.icon_active{
    transition: all 0.3s;
    padding: 12px;
    display: flex;
    color: var(--ant-primary-color) !important;
    border-bottom: 2.5px solid var(--ant-primary-color);
    cursor: pointer;}