.footer {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 24px;
}

.first {
    max-width: 1260px;
    height: 100%;
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 991px) {
        flex-direction: column;
        margin-top: 24px;
    }
}

.second {
    max-width: 1260px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
/*     margin-bottom: 24px;
 */
    @media (max-width: 991px) {
        flex-direction: column-reverse;
        align-items: center;
    }
}

.copyright {
    width: 100%;
    height: 44px;
    align-self: center;
    text-align: center;
    font-size: 12px;
}

.secondP {
    align-items: center;
    max-width: 1260px;
    height: 100%;
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 991px) {
        flex-direction: column-reverse;
        align-items: center;
    }
}

.links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
    margin-right: 24px;

    @media (max-width: 1230px) {
        max-width: 480px;
    }

    @media (max-width: 991px) {
        flex-direction: column;

        margin-left: 0;
        justify-content: center;
        margin-bottom: 8px;
        margin-right: 0;
    }
}

.turingo {
    @media (max-width: 991px) {
        margin-bottom: 24px;
    }
}

.secure {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    row-gap: 24px;
    column-gap: 24px;

    @media (max-width: 991px) {
        grid-template-columns: 1fr;
        margin-top: 8px;
        margin-bottom: 24px;
        justify-items: center;
    }
    align-items: center;
}

.languages {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    row-gap: 24px;
    column-gap: 8px;
    @media (max-width: 991px) {
        margin-bottom: 24px;
    }
}

.language-dark {
    color: white !important;
}

.language-light {
    color: black !important;
}

.language-light:hover {
    color: var(--ant-primary-color) !important;
    text-decoration: underline;
}

.language-dark:hover {
    color: var(--ant-primary-color) !important;
    text-decoration: underline;
}

.language-light-selected {
    color: var(--ant-primary-color) !important;
    text-decoration: underline;
}

.language-dark-selected {
    color: var(--ant-primary-color) !important;
    text-decoration: underline;
}
