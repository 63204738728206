.menu:hover {
    cursor: pointer;
    border-bottom: 5px solid red;
}

.icon {
    transition: all 0.5;
}

.popoverClosed {
    border-bottom: 2.5px solid transparent;
    transition: color 0.3s;
}

.popoverClosed:hover {
    color: var(--ant-primary-color);
    border-bottom: 2.5px solid var(--ant-primary-color);
    cursor: pointer;
}

.popoverClosed:after {
    content: '';
    display: block;
    background: var(--neutral-13);

    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.5506 13.6949C12.1624 13.9512 11.6349 13.9085 11.2932 13.5668L8.46477 10.7384C8.07424 10.3479 8.07424 9.71469 8.46477 9.32417C8.85529 8.93365 9.48846 8.93365 9.87898 9.32417L12.0003 11.4455L14.1217 9.32414C14.5122 8.93362 15.1453 8.93362 15.5359 9.32414C15.9264 9.71467 15.9264 10.3478 15.5359 10.7384L12.7074 13.5668C12.7074 13.5668 12.7074 13.5668 12.7074 13.5668C12.6586 13.6156 12.606 13.6583 12.5506 13.6949Z' fill='red' fill-opacity='0.85'/%3E%3C/svg%3E%0A");
    mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.5506 13.6949C12.1624 13.9512 11.6349 13.9085 11.2932 13.5668L8.46477 10.7384C8.07424 10.3479 8.07424 9.71469 8.46477 9.32417C8.85529 8.93365 9.48846 8.93365 9.87898 9.32417L12.0003 11.4455L14.1217 9.32414C14.5122 8.93362 15.1453 8.93362 15.5359 9.32414C15.9264 9.71467 15.9264 10.3478 15.5359 10.7384L12.7074 13.5668C12.7074 13.5668 12.7074 13.5668 12.7074 13.5668C12.6586 13.6156 12.606 13.6583 12.5506 13.6949Z' fill='red' fill-opacity='0.85'/%3E%3C/svg%3E%0A");
    width: 24px;
    height: 24px;
    float: right;
}

.popoverClosed:hover:after {
    content: '';
    background: var(--ant-primary-color);
    display: block;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.5506 13.6949C12.1624 13.9512 11.6349 13.9085 11.2932 13.5668L8.46477 10.7384C8.07424 10.3479 8.07424 9.71469 8.46477 9.32417C8.85529 8.93365 9.48846 8.93365 9.87898 9.32417L12.0003 11.4455L14.1217 9.32414C14.5122 8.93362 15.1453 8.93362 15.5359 9.32414C15.9264 9.71467 15.9264 10.3478 15.5359 10.7384L12.7074 13.5668C12.7074 13.5668 12.7074 13.5668 12.7074 13.5668C12.6586 13.6156 12.606 13.6583 12.5506 13.6949Z' fill='red' fill-opacity='0.85'/%3E%3C/svg%3E%0A");
    mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.5506 13.6949C12.1624 13.9512 11.6349 13.9085 11.2932 13.5668L8.46477 10.7384C8.07424 10.3479 8.07424 9.71469 8.46477 9.32417C8.85529 8.93365 9.48846 8.93365 9.87898 9.32417L12.0003 11.4455L14.1217 9.32414C14.5122 8.93362 15.1453 8.93362 15.5359 9.32414C15.9264 9.71467 15.9264 10.3478 15.5359 10.7384L12.7074 13.5668C12.7074 13.5668 12.7074 13.5668 12.7074 13.5668C12.6586 13.6156 12.606 13.6583 12.5506 13.6949Z' fill='red' fill-opacity='0.85'/%3E%3C/svg%3E%0A");

    width: 24px;
    height: 24px;
    float: right;
}
