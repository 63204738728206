.icon {
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    color: var(--text);
    border: 1px solid var(--neutral-5);
    box-shadow: 0px 2px 0px 0px #0000000b;
}

.iconSelected {
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    color: var(--ant-primary-color-active);
    border: 1px solid var(--ant-primary-color-active);
    box-shadow: 0px 2px 0px 0px #0000000b;
}

@media (hover: hover) {
    .icon:hover {
        border: 1px solid var(--ant-primary-color-hover);
        color:  var(--ant-primary-color-hover);
    }
}
