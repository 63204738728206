/******************************************************************************************************************************
ANT GLOBAL HACKS
-------------------------------------------------------------------------------------------------------------------------------
Use this file to add modifications to the global implementation of Ant.

The rules applied here are applied literally without conversion.

Try to keep the CSS rules grouped by their component.
******************************************************************************************************************************/

/******************************************************************************************************************************
Ant Collapse
*******************************************************************************************************************************/
/* .ant-spin-nested-loading{
    height: 100%;
} */

.filter-collapse .ant-collapse-header {
    display: flex !important;
    align-items: center !important;
}

.filter-collapse .ant-collapse-content-box {
    padding: 0px !important;
}
.filter-collapse .ant-collapse-header {
    flex-direction: row-reverse;
}

.filter-collapse .ant-collapse.ant-collapse-horizontal {
    margin-bottom: 16px;
    border-color: rgb(0 0 0 / 5%);
}

.ant-form-item:has(.no-margin-error) {
    padding: 0px 0px 16px;
    margin: 0px 0px 0px;
}

.ant-form-item {
    margin: 0px 0px 16px;
}

.ant-form-item-margin-offset {
    margin: 0px !important;
}
.table .ant-collapse-header-text {
    display: contents;
}
.table .ant-collapse-header {
    display: flex !important;
    align-items: center !important;
}
.ant-collapse-header {
    display: flex;
    flex: row nowrap;
    align-items: baseline;
}

.ant-collapse-header span:nth-child(2) {
    flex-grow: 1;
    margin-right: 8px;
}
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    padding: 0px 0px 0px 0px;
}
.ant-collapse > .ant-collapse-item:not(:last-child) > .ant-collapse-header {
    padding: 0px 0px 16px 0px;
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header[aria-expanded='true'] {
    padding: 0px 0px 16px 0px;
}

.ant-collapse-content-box {
    padding-top: 0px !important;
    padding-left: 24px !important;
}

.nopadding .ant-collapse-header {
    padding: 16px 0px !important;
}

.nopadding .ant-collapse-content-box {
    padding: 0px 0px 8px 0px !important;
}
/******************************************************************************************************************************
Ant List
*******************************************************************************************************************************/
.ant-list-item-meta {
    place-items: center;
}
.ant-list-item-meta-title {
    margin-bottom: 0 !important;
}

.ant-list-vertical .ant-list-item-meta {
    margin-bottom: 0px !important;
}

.ant-list-item-meta-avatar {
    margin-right: 8px !important;
}
.ant-list-item {
    padding-top: 8px;
    padding-bottom: 8px;
}
@media (max-width: 991px) {
    .ant-list-item {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
/******************************************************************************************************************************
Ant Menu 
*******************************************************************************************************************************/

.ant-menu-vertical {
    border-right: 0 !important;
    width: 98.4% !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: 0px solid transparent !important;
    width: calc(100% - 1px) !important;
}



.ant-menu-horizontal {
    border-bottom: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin-top: -2px !important;
}

.ant-drawer .ant-menu-vertical .ant-menu-item {
    display: flex;
    align-items: center;
}
.ant-layout-sider-collapsed {
    overflow: visible !important;
}
.ant-menu {
    font-size: 16px;
}
.ant-menu-submenu-title {
    padding-left: 16px !important;
}
.ant-menu-item-only-child {
    padding-left: 48px !important;
    font-size: 14px !important;
}

.sidebar-admin .ant-menu-item-only-child {
    padding-left: 32px !important;
    font-size: 14px !important;
}

.sidebar-admin .ant-menu-submenu-title {
    padding-left: 0px !important;
}

.ant-menu-submenu-popup .ant-menu-item-only-child {
    padding-left: 16px !important;
    font-size: 14px !important;
}
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub,
.ant-menu-vertical.ant-menu-sub {
    min-width: 130px !important;
}

.ant-menu-sub.ant-menu-inline {
    background: var(--background);
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
    font-size: 24px !important;
}

.ant-layout-sider .ant-menu-item .ant-menu-item-icon + span,
.ant-layout-sider .ant-menu-item .anticon + span,
.ant-layout-sider .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-layout-sider .ant-menu-submenu-title .anticon + span {
    margin-left: 0px !important;
}

.ant-drawer-body .ant-menu-item .ant-menu-item-icon + span,
.ant-drawer-body .ant-menu-item .anticon + span,
.ant-drawer-body .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-drawer-body .ant-menu-submenu-title .anticon + span {
    margin-left: 8px !important;
}
:not(.ant-menu-inline-collapsed) .ant-menu-submenu-selected {
    color: unset !important;
}

.ant-menu-inline-collapsed .ant-menu-submenu-selected {
    color: var(--ant-primary-color) !important;
}

.ant-menu-inline-collapsed .ant-menu-submenu-selected {
    border-right: 3px solid var(--ant-primary-color);
}
.ant-layout-sider .ant-menu-inline > .ant-menu-item,
.ant-layout-sider .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left > .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right > .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical > .ant-menu-item,
.ant-layout-sider .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 38px !important;
    line-height: 38px !important;
    margin: 0px !important;
}
.ant-layout-sider .ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-layout-sider .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 38px !important;
    line-height: 38px !important;
    margin: 0px !important;
}
@media (max-width: 991px) {
    .ant-layout-sider .ant-menu-inline > .ant-menu-item,
    .ant-layout-sider .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-layout-sider .ant-menu-vertical-left > .ant-menu-item,
    .ant-layout-sider .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-layout-sider .ant-menu-vertical-right > .ant-menu-item,
    .ant-layout-sider .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-layout-sider .ant-menu-vertical > .ant-menu-item,
    .ant-layout-sider .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
        height: 44px !important;
        line-height: 44px !important;
        margin: 0px !important;
    }
    .ant-layout-sider .ant-menu-sub.ant-menu-inline > .ant-menu-item,
    .ant-layout-sider .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
        height: 44px !important;
        line-height: 44px !important;
        margin: 0px !important;
    }
    .ant-layout-sider .ant-menu-sub.ant-menu-inline > .ant-menu-item,
    .ant-layout-sider .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
        height: 44px !important;
        line-height: 44px !important;
        margin: 0px !important;
    }
    .ant-menu-item-only-child {
        padding-left: 56px !important;
    }
    .ant-drawer-body .ant-menu-submenu {
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }
    .ant-menu > .ant-menu-item-divider .ant-menu-item {
        margin-bottom: 0px !important;
        margin-top: 0px;
    }
}

.ant-drawer-body .ant-menu > .ant-menu-item-divider {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
}

.ant-drawer-body li.ant-menu-item.ant-menu-item-only-child {
    margin: 0px !important;
}

.dark .ant-menu-sub.ant-menu-inline {
    background: unset !important;
}

.ant-menu-submenu-title {
    margin: 0px !important;
}

/* .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title{
    width: calc(92% + 3px) !important; 
} */

/******************************************************************************************************************************
Ant Mentions
*******************************************************************************************************************************/

.ant-mentions:hover {
    border: 0 !important;
}

.ant-mentions-focused:hover {
    border: 0 !important;
}

/******************************************************************************************************************************
Ant Tabs
*******************************************************************************************************************************/

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 0px solid #f0f0f0 !important;
}

.ant-tabs-nav-operations {
    padding-left: 0px !important;
}
.ant-tabs-nav {
    padding-left: 0px !important;
}

/* .ant-tabs-nav.with-padding {
    padding-left: 0px !important;
} */

.ant-tabs-nav-list {
    padding-right: 24px !important;
}
.no-padding .ant-tabs-nav-list {
    padding-right: 0px !important;
}
.with-margin .ant-tabs-nav-list .ant-tabs-tab:first-child {
    margin-left: 24px !important;
}
.small-margin .ant-tabs-nav-list .ant-tabs-tab:first-child {
    margin-left: 8px !important;
}
.no-margin .ant-tabs-nav-list .ant-tabs-tab:first-child {
    margin-left: 0px !important;
}

.ant-tabs-centered .ant-tabs-tabpane {
    position: relative;
    text-align: center;
}

.ant-tabs-tab {
    font-size: 16px !important;
}

.turingo-card-tabs .ant-tabs-tab {
    font-size: 14px !important;
}
.small .ant-tabs-tab {
    font-size: 14px !important;
}
.turingo-landing-tabs .ant-tabs-tab {
    font-size: 16px !important;
}

.dark .with-border .ant-tabs-nav {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}
.light .with-border .ant-tabs-nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
}

.dark .no-border .ant-tabs-nav {
    border-bottom: none !important;
}
.light .no-border .ant-tabs-nav {
    border-bottom: none !important;
}

.ant-tabs-nav-operations {
    display: none !important;
}

@media (max-width: 991px) {
    .with-margin .ant-tabs-nav-list .ant-tabs-tab:first-child {
        margin-left: 16px !important;
    }
    .small-margin .ant-tabs-nav-list .ant-tabs-tab:first-child {
        margin-left: 8px !important;
    }
    .no-margin .ant-tabs-nav-list .ant-tabs-tab:first-child {
        margin-left: 0px !important;
    }

    .ant-tabs-nav-list {
        padding-right: 16px !important;
    }
    .no-padding .ant-tabs-nav-list {
        padding-right: 0px !important;
    }
    .ant-tabs-tab {
        font-size: 18px !important;
    }

    .turingo-landing-tabs .ant-tabs-tab {
        font-size: 14px !important;
    }
    .small .ant-tabs-tab {
        font-size: 16px !important;
    }
    .turingo-card-tabs .ant-tabs-tab {
        font-size: 16px !important;
    }
    .ant-anchor-link-title {
        font-size: 18px !important;
    }
}

@media (max-width: 575px) {
    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 16px !important;
    }
}
/******************************************************************************************************************************
Ant Table
*******************************************************************************************************************************/

.ant-table {
    overflow-x: auto;
    border-radius: 4px !important;
}
.ant-table-cell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 300px;
}
/******************************************************************************************************************************
Ant Sider
*******************************************************************************************************************************/

.ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 70px !important;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    margin-left: 31px !important;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    margin: 0 0 0 34px !important;
}

.sideBarBtn span {
    width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.turingo-sidebar .ant-menu-item-divider {
    margin: 16px;
}

/******************************************************************************************************************************
Ant Stepper
*******************************************************************************************************************************/

.ant-steps-item-process.ant-steps-item-active .ant-steps-item-content {
    font-weight: 500;
}

/******************************************************************************************************************************
Ant Upload
*******************************************************************************************************************************/

.ant-upload.ant-upload-select.ant-upload-select-text {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 100;
}

/******************************************************************************************************************************
Ant Modal
*******************************************************************************************************************************/

.ant-modal .ant-picker-clear,
.ant-modal .ant-slider-handle,
.ant-modal .ant-anchor-wrapper,
.ant-modal .ant-collapse-content,
.ant-modal .ant-timeline-item-head,
.ant-modal .ant-card {
    background-color: unset !important;
}

@media (max-width: 576px) {
    .ant-modal-body {
        padding: 16px !important;
    }
    .ant-modal-footer {
        padding: 0px 16px 24px 16px !important;
    }

    .ant-modal-header {
        padding: 24px 16px !important;
    }
}
/******************************************************************************************************************************
Ant Input
*******************************************************************************************************************************/


.ant-input-textarea-show-count.ant-input-textarea-in-form-item:after{
    margin-bottom: -25px;
}
.alignTop .ant-radio {
    margin-top: 3px;
}

.alignTop .ant-radio-wrapper {
    display: flex;
    align-items: flex-start;
}

.alignTop .ant-form-item-required {
    display: flex;
    align-items: flex-start;
}

.alignTop .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    margin-top: 4px;
}
.ant-input-affix-wrapper .ant-input:focus:not(.ant-input-borderless) {
    border: none !important;
}
.ant-input-affix-wrapper .ant-input:hover:not(.ant-input-borderless, .ant-input-status-error) {
    border: none !important;
}

.turingo-imagelist-item-control {
    width: 100% !important;
}
.ant-input-textarea {
    width: 100% !important;
}

*:not(.ant-input-group) > .ant-input {
    border-radius: 4px !important;
}

.ant-input-lg {
    font-size: 14px !important;
}

.ant-typography {
    color: var(--text);
}
.ant-picker {
    border-radius: 4px;
}
.ant-picker-focused {
    border-color: var(--neutral-7);
    box-shadow: 0px 0px 0px 2px rgba(140, 140, 140, 0.2) !important;
}
.ant-picker:hover {
    border-color: var(--neutral-7);
}

.dark .ant-picker-focused {
    border-color: var(--neutral-7);
    box-shadow: 0px 0px 0px 2px rgba(140, 140, 140, 0.2) !important;

}
.dark .ant-picker:hover {
    border-color: var(--neutral-7);
}



.ant-input-number-focused {
    border-color: var(--neutral-7);
    box-shadow: 0px 0px 0px 2px rgba(140, 140, 140, 0.2) !important;
}
.ant-input-number:hover {
    border-color: var(--neutral-7);
}

.dark .ant-input-number-focused {
    border-color: var(--neutral-7);
    box-shadow: 0px 0px 0px 2px rgba(140, 140, 140, 0.2) !important;
}
.dark .ant-input-number:hover {
    border-color: var(--neutral-7);
}




@media (max-width: 991px) {
    .ant-typography h5,
    div.ant-typography-h5,
    div.ant-typography-h5 > textarea,
    h5.ant-typography {
        font-size: 18px !important;
    }

    .ant-input-lg {
        font-size: 16px !important;
    }
    .ant-picker-input > input {
        font-size: 16px !important;
    }
}
@media (max-width: 575px) {
    .ant-form .ant-form-item .ant-form-item-label,
    .ant-form .ant-form-item .ant-form-item-control {
        flex: inherit;
    }
}

.ant-form-item-required {
    width: 100%;
}

.ant-form-item-label > label {
    width: 100% !important;
}

.ant-input {
    border-radius: 4px;
}

.turingo-upload .ant-form-item-control>div:not(.ant-form-item-control-input) {
    display: none !important;
}





/* .ant-select:not(.ant-select-lg) .ant-select-selector{
    height: 32px !important;
}
.ant-select.ant-select-lg .ant-select-selector{
    height: 38px !important;
} */


.ant-input-affix-wrapper{
    height: 32px !important;
}

.ant-input-number-input{
    height: 32px !important;
}

.ant-input-number{
    border-radius: 4px !important;
}

.ant-picker{
    height: 32px !important
}

.ant-select-selector{
    font-size: 14px !important;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.ant-select-multiple .ant-select-selection-search-input, .ant-select-multiple .ant-select-selection-search-mirror{
    
    height: 22px !important;
    line-height: 22px !important;
}
.ant-select-multiple .ant-select-selection-search{
    margin: 0px !important;
    height: 22px !important;
    line-height: 22px !important;
}

.ant-select-multiple .ant-select-selector:after {
    line-height: 18px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search-input, .ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror{
    
    height: 28px !important;
    line-height: 28px !important;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search{
    margin: 0px !important;
    height: 28px !important;
    line-height: 28px !important;
}

.ant-select-multiple.ant-select-lg .ant-select-selector:after {
    line-height: 18px;
}

/* Input search */
.ant-input-search:not(.ant-input-search-large) .ant-input-search-button {
    height: 32px !important;
}

.ant-input-search:not(.ant-input-search-large) input {
    height: 32px !important;
}


.ant-input-search-large .ant-input-search-button {
    height: 38px !important;
}

.ant-input-search-large .ant-input-affix-wrapper {
    height: 38px !important;
}

.ant-select-multiple .ant-select-selection-item{
    border-radius: 100px !important;
    padding: 2px 8px !important; 
}



@media (max-width: 991px) {

    .ant-picker{
        height: 34px !important
    }
    .ant-input-affix-wrapper{
        height: 34px !important;
    }

    .ant-input-number-input{
        height: 34px !important;
    }
    .ant-select-selection-item-content{
        font-size: 14px !important;

    }
    .ant-select-multiple .ant-select-selection-item {
        border-radius: 100px !important;
        padding: 2px 8px !important;
    }
    .ant-select-selector{
        font-size: 16px !important;
    }


    .ant-select-multiple .ant-select-selection-search-input, .ant-select-multiple .ant-select-selection-search-mirror{
    
        height: 24px !important;
        line-height: 24px !important;
    }
    .ant-select-multiple .ant-select-selection-search{
        margin: 0px !important;
        height: 24px !important;
        line-height: 24px !important;
    }
    
    .ant-select-multiple .ant-select-selector:after {
        line-height: 18px;
    }

    .ant-select-multiple.ant-select-lg .ant-select-selection-search{
        height: 30px !important;
        line-height: 30px !important;
    }
    
    .ant-select-multiple.ant-select-lg .ant-select-selector:after {
        line-height: 30px;
    }



    .ant-input-search:not(.ant-input-search-large) .ant-input-search-button {
        height: 34px !important;
    }
    .ant-input-search:not(.ant-input-search-large) input {
        height: 34px !important;
    }


    .ant-input-search-large .ant-input-search-button {
        height: 44px !important;
    }
    .ant-input-search-large .ant-input-affix-wrapper {
        height: 44px !important;
    }
}
/******************************************************************************************************************************
Ant Card
*******************************************************************************************************************************/
.ant-card-actions {
    border-top: none !important;
}

.ant-card {
    border-radius: 4px;
}

.ant-card-body {
    padding: 16px 24px;
}
.ant-card-cover img {
    border-radius: 0px;
}
@media (max-width: 991px) {
    .ant-card-head {
        padding: 0 16px;
        font-size: 18px;
    }

    .ant-card-body {
        padding: 16px;
    }
    .ant-card {
        font-size: 16px;
    }
}

@media (max-width: 400px) {
    .ant-card {
        border-radius: 0px !important;
    }
}

.ant-card-actions > li:not(:last-child) {
    border-right: none !important;
}

/******************************************************************************************************************************
Ant Tooltip
*******************************************************************************************************************************/

.ant-tooltip-arrow-content {
    --antd-arrow-background-color: #262626 !important;
}
.ant-tooltip-inner {
    max-width: 700px;
    background-color: #262626 !important;
}
.ant-tooltip-inner a {
    color: white !important;
    background-color: var(--text);
}
.dark .ant-tooltip-inner a {
    color: var(--neutral-4) !important;
    background-color: var(--text);
}

@media (min-width: 991px) {
    .ant-tooltip {
        max-width: unset !important;
    }
}
/******************************************************************************************************************************
Ant Button
*******************************************************************************************************************************/


.btnActive {
    color: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
}
button.ant-btn:not(.ant-btn-circle, .ant-input-search-button, .ant-btn-lg, .sideBarBtn, .custom) {
    height: 38px;
}
a.ant-btn {
    height: 38px;
    line-height: 38px;
}

.ant-btn-icon-only {
    height: 38px;
    width: 38px;
}

.turingo-small-circle-btn {
    min-width: 30px !important;
    height: 30px !important;
    width: 30px !important;
}

@media (max-width: 991px) {
    .turingo-small-circle-btn {
        min-width: 34px !important;
        height: 34px !important;
        width: 34px !important;
    }
}

#closeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 100px;
    transition: 0.3s all;
    border: 1px solid var(--border-button) !important;
}
#closeBtn.ant-btn-icon-only {
    line-height: 32px;
    font-size: 16px;
    color: var(--text);
}
#closeBtn.ant-btn-icon-only:hover {
    color: var(--ant-primary-color);
}
#closeBtn:hover {
    border-radius: 100px;
    cursor: pointer;
    color: var(--ant-primary-color);
    border: 1px solid var(--ant-primary-color-hover) !important;
}

#downloadBtn {
    display: flex;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 100px;
    transition: 0.3s all;
    border: 1px solid var(--border-button) !important;
}
/* #downloadBtn.ant-btn-icon-only {
    line-height: 32px;
    font-size: 16px;
    padding-left: 7px;
    color: var(--text);
}

#downloadBtn:hover {
    border-radius: 100px;
    cursor: pointer;
    color: var(--ant-primary-color);
    border: 1px solid var(--ant-primary-color-hover) !important;
}
 */

.ant-btn:not(.ant-btn-circle, .ant-input-search-button) {
    border-radius: 4px !important;
}

/* .ant-btn span {
    line-height: 29px;
} */

/* 
@media (max-width: 576px) {
    .ant-btn > .anticon + span, .ant-btn > span + .anticon{
        margin-left: 0px;
    }
}
 */

/******************************************************************************************************************************
Ant Image Preview
*******************************************************************************************************************************/
.ant-image-preview-operations {
    background-color: black !important;
}

/******************************************************************************************************************************
Ant Select
*******************************************************************************************************************************/
.turingo-select .ant-select-selector {
    overflow: auto;
    max-height: 116px;
}

@media (max-width: 991px) {
    .turingo-select .ant-select-selector {
        overflow: auto;
        max-height: 124px;
    }
}

.ant-select-multiple .ant-select-selector {
    padding: 4px 12px !important;
}


.ant-select-selection-item {
    display: flex;
    align-items: center;
}
.ant-select-selection-item-content {
    font-size: 12px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
}
.ant-input-affix-wrapper {
    border-radius: 4px;
}

.ant-select-item-option-content {
    display: flex;
    align-items: center;
}

.light .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: var(--ant-primary-color);
}

.ant-select-multiple .ant-select-selection-item-content {
    display: flex;
}

/******************************************************************************************************************************
Ant Affix
*******************************************************************************************************************************/

.ant-affix {
    z-index: 6 !important;
}

/******************************************************************************************************************************
Ant Popover
*******************************************************************************************************************************/

.ant-popover-arrow {
    display: none;
}

.ant-popover.ant-popover-placement-bottomRight {
    top: 50px !important;
}

.ant-popover-inner {
    box-shadow: rgb(0 0 0 / 15%) 0px 6px 8px !important;
    border-radius: 4px !important;
}

.ant-popover-title {
    padding: 16px;
}

/******************************************************************************************************************************
Ant Dropdown
*******************************************************************************************************************************/

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    font-size: 14px;
    padding: 9px 16px;
    line-height: 22px;
}

.ant-dropdown-menu-item-icon {
    min-width: 14px;
    display: flex;
    margin-right: 8px;
    font-size: 14px;
}

@media (max-width: 991px) {
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        font-size: 16px;
        padding: 9px 16px;
        line-height: 24px;
    }

    .ant-dropdown-menu-item-icon {
        min-width: 16px;
        margin-right: 8px;
        display: flex;
        font-size: 16px;
    }
}

.ant-dropdown-menu {
    padding: 0px;
}

.light .ant-dropdown-menu {
    /* Neutral/1 */

    background: #ffffff;
    /* drop-shadow/0.15 */
    border: none !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}
.pollForm .ant-form-item-control-input-content {
    display: flex;
}

.light .ant-message-success .anticon {
    color: var(--polar-green-5);
}
.dark .ant-message-success .anticon {
    color: var(--polar-green-7);
}

.light .ant-message-notice-content:has(.ant-message-success) {
    padding: 8px 16px;
    border-radius: 4px;
    background-color: var(--polar-green-1);
    border: 1px solid var(--polar-green-3);
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}
.dark .ant-message-notice-content:has(.ant-message-success) {
    padding: 8px 16px;
    border-radius: 4px;
    background-color: var(--polar-green-1);
    border: 1px solid var(--polar-green-5);
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.light .ant-message-notice-content:has(.ant-message-error) {
    padding: 8px 16px;
    border-radius: 4px;
    background-color: var(--dust-red-1);
    border: 1px solid var(--dust-red-3);
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}
.dark .ant-message-notice-content:has(.ant-message-error) {
    padding: 8px 16px;
    border-radius: 4px;
    background-color: var(--dust-red-1);
    border: 1px solid var(--dust-red-5);
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: transparent !important;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 0 4px 4px 0 !important;
}
/******************************************************************************************************************************
Ant Alert
*******************************************************************************************************************************/

.ant-alert {
    padding: 8px 16px !important;
}

.ant-alert-info {
    border-radius: 4px !important;
}

.ant-alert-warning {
    background-color: var(--yellow) !important;
}
.ant-alert-icon {
    align-self: flex-start;
    margin-top: 4px;
}

.ant-alert-close-icon {
    align-self: flex-start;
    margin-left: 0;
    font-size: 14px;
    margin-top: 4px;
    line-height: 14px;
}

.footerBanner .ant-alert-icon {
    font-size: 21px;
}

/******************************************************************************************************************************
Ant Badge
*******************************************************************************************************************************/

.turingo-bottom-badge-dot.ant-scroll-number {
    transition: background 1.5s;
}

.turingo-bottom-badge-dot {
    background: #ff4d6e;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff;
}

.turingo-bottom-badge-status {
    line-height: inherit;
    vertical-align: baseline;
}

.turingo-bottom-badge {
    width: min-content;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    line-height: 1;
}

.turingo-bottom-badge-count,
.turingo-bottom-badge-dot,
.turingo-bottom-badge .ant-scroll-number-custom-component {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0%;
}

.turingo-bottom-badge-dot {
    z-index: auto;
    width: 6px;
    min-width: 6px;
    height: 6px;
    background: #a61d24;
    border-radius: 100%;
}

/******************************************************************************************************************************
Ant Skeleton
*******************************************************************************************************************************/
.ant-skeleton-input {
    border-radius: 4px;
}
.ant-skeleton-header .ant-skeleton-avatar {
    border-radius: 50%;
}

.ant-skeleton-content {
    vertical-align: middle !important;
}
.ant-skeleton-title {
    margin-bottom: 0 !important;
}
.small.ant-skeleton .ant-skeleton-content .ant-skeleton-title {
    height: 12px;
}

.large.ant-skeleton .ant-skeleton-content .ant-skeleton-title {
    height: 48px;
}

.big-message.ant-skeleton .ant-skeleton-content .ant-skeleton-title {
    height: 72px;
}

.input-skeleton.ant-skeleton .ant-skeleton-content .ant-skeleton-title {
    height: 32px;
}

.tag-skeleton.ant-skeleton .ant-skeleton-content .ant-skeleton-title {
    border-radius: 100px;
    height: 22px;
}

.turingo-skeleton::after {
    position: absolute;
    top: 0;
    right: -150%;
    bottom: 0;
    left: -150%;
    background: linear-gradient(90deg, hsla(0, 0%, 74.5%, 0.2) 25%, hsla(0, 0%, 50.6%, 0.24) 37%, hsla(0, 0%, 74.5%, 0.2) 63%);
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    animation: ant-skeleton-loading 1.4s ease infinite;
    content: '';
}

/******************************************************************************************************************************
Ant Divider
*******************************************************************************************************************************/

.dark .ant-divider-horizontal.ant-divider-with-text::before,
.dark .ant-divider-horizontal.ant-divider-with-text::after {
    position: unset !important;
    border-top-color: #13a8a8 !important;
}
.light .ant-divider-horizontal.ant-divider-with-text::before,
.light .ant-divider-horizontal.ant-divider-with-text::after {
    position: unset !important;
    border-top-color: #4dc1be !important;
}
.ant-divider-inner-text {
    padding: 0 4px !important;
}

.ant-divider-horizontal.ant-divider-with-text {
    font-weight: 400 !important;
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 16px !important;
}
.ant-divider-horizontal {
    margin: 16px 0px;
}

.small-divider .ant-divider-inner-text {
    font-size: 12px;
    color: var(--neutral-7);
    padding: 0 16px !important;
}

.dark .small-divider.ant-divider-horizontal.ant-divider-with-text::before,
.dark .small-divider.ant-divider-horizontal.ant-divider-with-text::after {
    position: unset !important;
    border-top-color: var(--neutral-6) !important;
}

.light .small-divider.ant-divider-horizontal.ant-divider-with-text::before,
.light .small-divider.ant-divider-horizontal.ant-divider-with-text::after {
    position: unset !important;
    border-top-color: var(--neutral-4) !important;
}

/******************************************************************************************************************************
Ant Anchor
*******************************************************************************************************************************/

.ant-anchor {
    display: flex;
    overflow: auto;
}
.ant-anchor-ink {
    display: none;
}
.ant-anchor-link {
    padding: 21px 12px;
    line-height: 24px;
    border-bottom: 2px solid var(--background);
}
.ant-anchor-wrapper {
    margin-left: 0px;
    padding-left: 0px;
}
.ant-anchor::-webkit-scrollbar {
    display: none;
}

.ant-anchor-link-active {
    border-bottom: 2px solid var(--ant-primary-color);
}

/******************************************************************************************************************************
Ant Breadcrumb
*******************************************************************************************************************************/

.ant-breadcrumb-link {
    display: inline-flex;
}

.ant-breadcrumb li:nth-last-child(2) a {
    max-width: 100px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ant-breadcrumb ol {
    padding: 0px !important;
    margin: 0px !important;
}
.ant-breadcrumb li,
.ant-breadcrumb li a {
    color: var(--neutral-7);
}
.ant-breadcrumb li:last-child,
.ant-breadcrumb li:last-child a {
    color: var(--neutral-13);
}
.ant-breadcrumb a:hover {
    color: var(--neutral-13);
}
/******************************************************************************************************************************
Ant Radio
*******************************************************************************************************************************/

.ant-radio {
    top: 0px;
}
.ant-radio-wrapper {
    align-items: center;
}

.quiz-form-item .ant-radio-wrapper {
    margin: 0px !important;
}

@media (max-width: 991px) {
    .ant-radio-inner {
        width: 18px;
        height: 18px;
    }

    .ant-radio-inner:after {
        width: 18px;
        height: 18px;
        margin-top: -9px;
        margin-left: -9px;
        border-radius: 18px;
    }

    .ant-radio-group:not(.turingo-radio-card) .ant-space-item {
        display: flex !important;
        height: 44px !important;
    }

    .ant-checkbox-group:not(.turingo-radio-card) .ant-space-item {
        display: flex !important;
        height: 44px !important;
    }
}

.radio-turingo-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    display: flex;
    justify-content: space-between;
    font-size: 0;
}

.ant-badge {
    color: inherit !important;
}
.ant-badge-count {
    z-index: 3;
    min-width: 19px;
    height: 19px;
}

.radio-turingo-group .ant-badge-count {
    z-index: 1;
}
.radio-turingo-group > .ant-badge:not(:first-child) > .radio-turingo-button-wrapper {
    border-left: none;
}
.radio-turingo-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    align-items: baseline;
    cursor: pointer;
}
.radio-turingo-wrapper-disabled {
    cursor: not-allowed;
}
.radio-turingo-wrapper::after {
    display: inline-block;
    width: 0;
    overflow: hidden;
    content: '\a0';
}
.radio-turingo-wrapper.radio-turingo-wrapper-in-form-item input[type='radio'] {
    width: 14px;
    height: 14px;
}
.radio-turingo {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    top: 0.2em;
    margin-bottom: 8px;
    display: flex;
    outline: none;
    cursor: pointer;
    justify-content: center;
}
.radio-turingo-wrapper:hover .radio-turingo,
.radio-turingo:hover .radio-turingo-inner,
.radio-turingo-input:focus + .radio-turingo-inner {
    border-color: var(--ant-primary-color);
}

.radio-turingo:hover::after,
.radio-turingo-wrapper:hover .radio-turingo::after {
    visibility: visible;
}
.radio-turingo-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border-color: #434343;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    transition: all 0.3s;
}
.radio-turingo-inner::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -8px;
    background-color: var(--ant-primary-color);
    border-top: 0;
    border-left: 0;
    border-radius: 16px;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: ' ';
}

.radio-turingo-form.ant-form-item {
    margin-bottom: 40px;
}
.radio-turingo-form.ant-form-item-has-error {
    margin-bottom: 0px;
}

.radio-turingo-form .ant-form-item-row {
    width: 100% !important;
    margin-top: 5px;
}
.radio-turingo-form .ant-form-item-control-input-content {
    display: flex !important;
    width: 100% !important;
}


.radio-turingo-form .ant-form-item-explain {
    margin-left: 28px;
}

.radio-turingo-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
}
.radio-turingo-checked .radio-turingo-inner {
    border-color: var(--ant-primary-color);
}
.radio-turingo-checked .radio-turingo-inner::after {
    transform: scale(0.5);
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.radio-turingo-disabled {
    cursor: not-allowed;
}
.radio-turingo-disabled .radio-turingo-inner {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: #434343 !important;
    cursor: not-allowed;
}
.radio-turingo-disabled .radio-turingo-inner::after {
    background-color: rgba(255, 255, 255, 0.2);
}
.radio-turingo-disabled .radio-turingo-input {
    cursor: not-allowed;
}
.radio-turingo-disabled + span {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
}
span.radio-turingo + * {
    padding-right: 8px;
    padding-left: 8px;
}

.radio-turingo-group-large .radio-turingo-button-wrapper {
    height: 40px;
    font-size: 16px;
    line-height: 38px;
}
.radio-turingo-group-small .radio-turingo-button-wrapper {
    height: 24px;
    padding: 0 7px;
    line-height: 22px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 500 !important;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
    opacity: 0.3 !important;
}
.ant-radio-button-wrapper:first-child {
    border-radius: 4px 0 0 4px;
}
.ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0;
}

/******************************************************************************************************************************
Ant Drawer
*******************************************************************************************************************************/

.ant-drawer-header-title {
    flex-direction: row-reverse !important;
}

.ant-drawer-header {
    padding: 16px !important;
    border-bottom: none !important;
}
.ant-drawer-close {
    margin-right: 0px !important;
    padding: 0px !important;
}

.ant-notification-notice-close {
    top: 16px !important;
    right: 16px !important;
}
/* .ant-drawer-content {
    height: 100%;
    overflow-y: scroll;
  }
   */
.ant-drawer-content-wrapper {
    max-width: 400px !important;
    width: 100% !important;
}

/******************************************************************************************************************************
Ant Image
*******************************************************************************************************************************/

.ant-image-img {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    object-fit: cover !important;
    object-position: center center !important;
    cursor: pointer !important;
}
.ant-col.turingo-image-item-control {
    width: 100%;
}

/******************************************************************************************************************************
Ant Progress
*******************************************************************************************************************************/

.ant-progress-status-normal .ant-progress-bg {
    background-color: #fa8c16 !important;
}

.ant-progress-status-exception .ant-progress-bg {
    background-color: var(--dust-red-4) !important;
}

/******************************************************************************************************************************
Ant Typography
*******************************************************************************************************************************/

.ant-typography p,
div.ant-typography {
    margin-bottom: 0px;
}

.turingo .ant-select-arrow {
    font-size: 12px !important;
    color: black;
    transition: 0.3s all;
}
.turingo .ant-select-selector {
    text-align: center;
    transition: 0.3s all;
}
.turingo .ant-select-selection-placeholder {
    align-self: center;
    color: black;
    transition: 0.3s all;
}

.turingo .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px !important;
    transition: 0.3s all;
}
.turingo .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: 1px solid var(--ant-primary-5) !important;
    color: var(--ant-primary-5);
    transition: 0.3s all;
}
.turingo .ant-select:not(.ant-select-disabled):hover .ant-select-arrow {
    color: var(--ant-primary-5);
    transition: 0.3s all;
}

.ant-tag {
    padding: 4px 8px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 100px;
}

@media (min-width: 992px) {
    .ant-tag {
        font-size: 12px;
    }
}

@media (max-width: 991px) {
    .ant-tag {
        font-size: 14px !important;
    }
}

@media (max-width: 991px) {
    .custom .ant-collapse-header {
        display: flex !important;
        flex-direction: column !important;
        padding: 0px !important;
    }
    .custom .ant-collapse-header-text {
        margin-bottom: 16px !important;
    }
    .custom .ant-collapse-extra {
        width: 100% !important;
        margin-bottom: 16px !important;
    }
    .ant-select-clear {
        z-index: 100;
        width: 16px;
        height: 16px;
        margin-top: -8px;
    }
}

.ant-select-clear {
    z-index: 100;
}

.custom .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 37px 0 11px;
}

.custom .ant-collapse-content-box {
    padding: 0 !important;
}

/******************************************************************************************************************************
Ant Picker
*******************************************************************************************************************************/

.left.ant-picker {
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-radius: 4px 0px 0px 4px;
}

.right.ant-picker {
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-left: 0px;
    border-radius: 0px 4px 4px 0px;
}

.dark .left.ant-picker {
    border-bottom: 1px solid #434343;
    border-top: 1px solid #434343;
    border-left: 1px solid #434343;
    border-right: 1px solid #434343;
    border-radius: 4px 0px 0px 4px;
}

.dark .right.ant-picker {
    border-bottom: 1px solid #434343;
    border-top: 1px solid #434343;
    border-right: 1px solid #434343;
    border-left: 0px;
    border-radius: 0px 4px 4px 0px;
}

.ant-picker-time-panel-column:after {
    height: unset !important;
}
.ant-picker-ranges .ant-picker-ok {
    float: none;
    margin-left: 0px;
    width: 100%;
}
.ant-picker-ok button {
    width: 100%;
}

.tag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    height: 20px;

    border-radius: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-left: 4px;
}

:not(.ant-tabs-tab-active) .tag {
    background: var(--neutral-4);
    color: var(--neutral-7);
}

.ant-tabs-tab-active .tag {
    background: var(--ant-primary-1);
    color: var(--ant-primary-color);
}

.ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.65);
}

.ant-progress-circle.turingo .ant-progress-text {
    color: white !important;
}

.ant-select-selector {
    overflow: auto;
    max-height: 120px;
}

.ant-select-selector {
    padding-left: 2px;
    padding-right: 2px;
}

.ant-select-selector::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

.light .ant-select-selector::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    border-left: 4px #b80d0d00 solid;
    background-clip: padding-box;
}

.dark .ant-select-selector::-webkit-scrollbar-track {
}

.light .ant-select-selector::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.dark .ant-select-selector::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;

    border-left: 4px #b80d0d00 solid;
    background-clip: padding-box;
}


.i-icon{
    display: flex;
    align-items: center;
}

.ant-checkbox-group-item {
    display: flex;
    margin-right: 0;
    margin-bottom: 8px;
}

.quiz-form-item .ant-form-item-label{
    display: contents;
}

.quiz-form-item .ant-space-item, .quiz-form-item .correct, .quiz-form-item  .ant-radio-group, .quiz-form-item .incorrect  {
    width: 100%;
}

.quiz-form-item .correct{
    border-radius: 4px;
    padding: 8px;
    border: 1px solid var(--alert-success-outline);

}

.quiz-form-item .ant-radio-wrapper-in-form-item{

    display: flex;
    align-items: flex-start;
    
}

.quiz-form-item .ant-checkbox-wrapper-in-form-item{

    display: flex;
    align-items: flex-start;
    
}


.quiz-form-item .ant-checkbox-group{
    width: 100%;
}
.quiz-form-item .ant-radio{
   margin-top: 4px;
}

.quiz-form-item .incorrect{
    border-radius: 4px;
    padding: 8px;
    border: 1px solid var(--alert-error-outline);

}
.quiz-form-item .none{
    border-radius: 4px;
    padding: 8px;
    border: 1px solid transparent;

}

.none .turingo-text{
    color: var(--neutral-6) !important;

}

.turingo-required:before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}

.quiz-form-item .ant-space-item {
    display: flex;
    align-items: center;
}
.quiz-form-item .ant-space-item:has(label.incorrect):before {
    display: flex;
    content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.582031 6.9987C0.582031 3.45487 3.45487 0.582031 6.9987 0.582031C10.5425 0.582031 13.4154 3.45487 13.4154 6.9987C13.4154 10.5425 10.5425 13.4154 6.9987 13.4154C3.45487 13.4154 0.582031 10.5425 0.582031 6.9987ZM9.06112 4.93648C9.28893 5.16429 9.28893 5.53363 9.06112 5.76144L7.82369 6.99887L9.06112 8.23631C9.28893 8.46411 9.28893 8.83346 9.06112 9.06127C8.83332 9.28907 8.46397 9.28907 8.23617 9.06127L6.99873 7.82383L5.7613 9.06127C5.53349 9.28907 5.16414 9.28907 4.93634 9.06127C4.70853 8.83346 4.70853 8.46411 4.93634 8.23631L6.17377 6.99887L4.93634 5.76144C4.70853 5.53363 4.70853 5.16429 4.93634 4.93648C5.16414 4.70867 5.53349 4.70867 5.7613 4.93648L6.99873 6.17392L8.23617 4.93648C8.46397 4.70867 8.83332 4.70867 9.06112 4.93648Z' fill='%23FF4D4F'/%3E%3C/svg%3E%0A");
    width: 14px;
    height: 14px;
    margin-right: 8px;
}

.quiz-form-item .ant-space-item:has(label.correct):before {
    display: flex;
    content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.46143 2.46533C3.62199 1.30479 5.22696 0.585938 6.9987 0.585938C8.77044 0.585938 10.3754 1.30479 11.536 2.46533C12.6965 3.62589 13.4154 5.23086 13.4154 7.0026C13.4154 8.77435 12.6965 10.3793 11.536 11.5399C10.3754 12.7004 8.77044 13.4193 6.9987 13.4193C5.22696 13.4193 3.62199 12.7004 2.46143 11.5399C1.30089 10.3793 0.582031 8.77434 0.582031 7.0026C0.582031 5.23086 1.30089 3.62589 2.46143 2.46533ZM10.3278 5.66508C10.5557 5.43728 10.5557 5.06793 10.3278 4.84013C10.1 4.61232 9.73069 4.61232 9.50289 4.84013L6.41537 7.92765L5.07784 6.59013C4.85004 6.36232 4.48069 6.36232 4.25289 6.59013C4.02508 6.81793 4.02508 7.18728 4.25289 7.41508L6.00289 9.16508C6.23069 9.39289 6.60004 9.39289 6.82784 9.16508L10.3278 5.66508Z' fill='%2373D13D'/%3E%3C/svg%3E%0A");
    width: 14px;
    height: 14px;
    margin-right: 8px;
}

.quiz-form-item .ant-space-item:has(label.none):before {
    display: flex;
    content: "";
    width: 14px;
    height: 14px;
    margin-right: 8px;
}

.ant-form-item-explain-error{
    display: flex;
    align-items: center;
    
}


.ant-form-item-explain-error:not(:empty):before {
    display: flex;
    content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.582031 6.9987C0.582031 3.45487 3.45487 0.582031 6.9987 0.582031C10.5425 0.582031 13.4154 3.45487 13.4154 6.9987C13.4154 10.5425 10.5425 13.4154 6.9987 13.4154C3.45487 13.4154 0.582031 10.5425 0.582031 6.9987ZM9.06112 4.93648C9.28893 5.16429 9.28893 5.53363 9.06112 5.76144L7.82369 6.99887L9.06112 8.23631C9.28893 8.46411 9.28893 8.83346 9.06112 9.06127C8.83332 9.28907 8.46397 9.28907 8.23617 9.06127L6.99873 7.82383L5.7613 9.06127C5.53349 9.28907 5.16414 9.28907 4.93634 9.06127C4.70853 8.83346 4.70853 8.46411 4.93634 8.23631L6.17377 6.99887L4.93634 5.76144C4.70853 5.53363 4.70853 5.16429 4.93634 4.93648C5.16414 4.70867 5.53349 4.70867 5.7613 4.93648L6.99873 6.17392L8.23617 4.93648C8.46397 4.70867 8.83332 4.70867 9.06112 4.93648Z' fill='%23FF4D4F'/%3E%3C/svg%3E%0A");
    width: 14px;
    height: 18px;
    margin-right: 4px;
}

@media (max-width: 991px) {
    .ant-form-item-explain-error{
        font-size: 16px;
    }
    .ant-form-item-explain-error:not(:empty):before {
        display: flex;
        content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.582031 6.9987C0.582031 3.45487 3.45487 0.582031 6.9987 0.582031C10.5425 0.582031 13.4154 3.45487 13.4154 6.9987C13.4154 10.5425 10.5425 13.4154 6.9987 13.4154C3.45487 13.4154 0.582031 10.5425 0.582031 6.9987ZM9.06112 4.93648C9.28893 5.16429 9.28893 5.53363 9.06112 5.76144L7.82369 6.99887L9.06112 8.23631C9.28893 8.46411 9.28893 8.83346 9.06112 9.06127C8.83332 9.28907 8.46397 9.28907 8.23617 9.06127L6.99873 7.82383L5.7613 9.06127C5.53349 9.28907 5.16414 9.28907 4.93634 9.06127C4.70853 8.83346 4.70853 8.46411 4.93634 8.23631L6.17377 6.99887L4.93634 5.76144C4.70853 5.53363 4.70853 5.16429 4.93634 4.93648C5.16414 4.70867 5.53349 4.70867 5.7613 4.93648L6.99873 6.17392L8.23617 4.93648C8.46397 4.70867 8.83332 4.70867 9.06112 4.93648Z' fill='%23FF4D4F'/%3E%3C/svg%3E%0A");
        width: 16px;
        height: 22px;
        margin-right: 4px;
    }
}


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: unset;
}

.ant-menu-submenu-title:focus-visible {
    outline: 2px solid var(--ant-primary-color) !important;
}   

.ant-menu-item a:focus-visible {
    color: var(--ant-primary-color)
}
.ant-menu-item:focus-visible{
    outline: 2px solid var(--ant-primary-color);
    outline-offset: -2px;
    border-radius: 4px;
}

.ant-menu-item:has(:focus-visible) {
    background: var(--ant-primary-1) !important;
}

a:focus-visible {
    outline: 2px solid var(--ant-primary-color);
    outline-offset: -2px;
    border-radius: 4px;
}

.ant-form-item-explain, .ant-form-item-extra {
    color: var(--neutral-7) !important;
}