.text:hover {
    color: var(--ant-primary-5);
    cursor: pointer;
}

.text {
    color: var(--ant-primary-color);
    cursor: pointer;
}


.text:active {
    color: var(--ant-primary-7);
    cursor: pointer;
}

.text:hover {
    transition: all 0.3s;
}
