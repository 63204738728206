
// MARK: - Constants

.container {
    // These colors bypass the normal $master-color rules, since the diploma must always be white.
    color: black;
    position: relative;
    text-align: center;
    height: 100%;
    z-index: 1;
    display: flex;
    border: 1px solid var(--border);
}

.container > canvas {
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
}

.icon{
    height: 64px;
    width: 64px;
    margin-bottom: 21px;
}