.icon {
    width: 56px;
    height: 56px;
}

.title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}

.subtitle {
    font-size: 14px;
}
