.animationDiv {
    animation: fadein 0.125s;
}

.animationModal {
    animation: modalIn 300ms;
}


.closeBtn{
    transition: 0.3s all;
    color: var(--neutral-7);


    :hover{
        color: black;
        transition: 0.3s all;
    }
}
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@keyframes modalIn {
    from { opacity: 0; transform: translate(0, 10%); }
    to { opacity: 1; transform: translate(0, 0); }
  }