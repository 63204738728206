.item{
    background-color: transparent;
    border: 1px solid var(--tag-border);
    border-radius: 100px;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    position: relative;
    text-wrap: nowrap;
   /*  &:hover{
        background-color: rgba(255, 255, 255, 0.12);;
        cursor: pointer;
    } */

  
}

.closeBtn{
    &:hover{
        color: var(--ant-primary-color);
        cursor: pointer;
    }
}
.itemMobile{
    border: 1px solid var(--tag-border);
    box-sizing: border-box;
    border-radius: 100px;
    transition: all 0.3s ease;
    padding: 4px 8px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    position: relative;
    text-wrap: nowrap;

    /* &:hover{
        background-color: rgba(255, 255, 255, 0.12);;
        cursor: pointer;
    } */
}


.itemAdd{
    background-color: transparent;
    border: 1px dashed var(--tag-border);
    border-radius: 100px;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 22px;
    position: relative;
    transition: 0.3s all;
    text-wrap: nowrap;

    &:hover{
        color: var(--ant-primary-color);
        border-color: var(--ant-primary-color);
        cursor: pointer;
    }

  
}


.itemAddMobile{
    border: 1px dashed var(--tag-border);
    box-sizing: border-box;
    border-radius: 100px;
    transition: all 0.3s ease;
    padding: 4px 8px;
    font-size: 16px;
    line-height: 24px;
    text-wrap: nowrap;

    position: relative;
    &:hover{
        color: var(--ant-primary-color);
        border-color: var(--ant-primary-color);
        cursor: pointer;
    }
}


.itemAddDisabled{
    cursor:default;
    background-color: var(--disabled-button-background);
    border: 1px dashed var(--neutral-6);
    color: var(--neutral-6);
    border-radius: 100px;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 22px;
    position: relative;
    text-wrap: nowrap;

    transition: 0.3s all;
  
}


.itemAddMobileDisabled{
    cursor:default;
    background-color: var(--disabled-button-background);
    color: var(--neutral-6);
    border: 1px dashed var(--neutral-6);
    box-sizing: border-box;
    border-radius: 100px;
    font-size: 16px;
    line-height: 24px;
    transition: all 0.3s ease;
    padding: 4px 8px;
    position: relative;
    text-wrap: nowrap;


}

